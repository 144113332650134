import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginaComponent } from './pagina/pagina.component';
import { PestanaContenidoComponent } from './pestana-contenido/pestana-contenido.component';
import { CabeceraModule } from './cabecera/cabecera.module';
import { MenuComponent } from './menu/menu.component';
import { AgendaComponent } from './agenda/agenda.component';
import { CampoComponent } from './campo/campo.component';
import { TarjetaComponent } from './tarjeta/tarjeta.component';
import { BotonComponent } from './boton/boton.component';
import { CasillaVerificacionComponent } from './casilla-verificacion/casilla-verificacion.component';
import { EtiquetaComponent } from './etiqueta/etiqueta.component';
import { BloqueComponent } from './bloque/bloque.component';
import { BotoneraComponent } from './botonera/botonera.component';
import { PestanaComponent } from './pestana/pestana.component';
import { DesplegableComponent } from './desplegable/desplegable.component';
import { TablaComponent } from './tabla/tabla.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { JqwidgetsModule } from '../../shared/jqwidgets/jqwidgets.module';
import { ContenedorModalComponent } from './contenedorModal/contenedorModal.component';
import { ModalComponent } from './modal/modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { TituloTarjetaComponent } from './tarjeta/tituloTarjeta.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GanttComponent } from "./gantt/gantt.component";
import { ChartComponent } from "./chart/chart.component";

export const CONTAINERS = [
    //*********************************** */
    //**** COMPONENTES RE-IMPLEMENTACIÓN */
    BotonComponent,
    EtiquetaComponent,
    CampoComponent,
    CasillaVerificacionComponent,
    BloqueComponent,
    TarjetaComponent,
    TituloTarjetaComponent,
    BotoneraComponent,
    PestanaComponent,
    DesplegableComponent,
    TablaComponent,
    ContenedorModalComponent,
    ModalComponent,
    //*********************************** */
    //*********************************** */
    PaginaComponent,
    PestanaContenidoComponent,
    AgendaComponent,
    MenuComponent,
    DashboardComponent,
    GanttComponent,
    ChartComponent
];

export const EXPORT_CONTAINERS = [
    //*********************************** */
    //**** COMPONENTES RE-IMPLEMENTACIÓN */
    BotonComponent,
    EtiquetaComponent,
    CampoComponent,
    CasillaVerificacionComponent,
    BloqueComponent,
    TarjetaComponent,
    TituloTarjetaComponent,
    BotoneraComponent,
    PestanaComponent,
    DesplegableComponent,
    TablaComponent,
    ContenedorModalComponent,
    ModalComponent,
    //*********************************** */
    //*********************************** */
    PaginaComponent,
    PestanaContenidoComponent,
    AgendaComponent,
    MenuComponent,
    DashboardComponent,
    GanttComponent,
    ChartComponent
];

export const EXPORT_PROVIDERS = [
];

@NgModule({
    imports: [
        CommonModule,
        JqwidgetsModule,
        CabeceraModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        MatTooltipModule,
        MatRippleModule
    ],
    declarations: EXPORT_CONTAINERS,
    exports: EXPORT_CONTAINERS,
    providers: EXPORT_PROVIDERS,
    entryComponents: CONTAINERS
})
export class ComponentsModule { }
