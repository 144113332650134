import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CabeceraComponent } from './cabecera.component';
import { PerfilComponent } from './perfil/perfil.component';
import { OpcionMenuComponent } from '../menu/opcionMenu.component';
import { JqwidgetsModule } from '../../../shared/jqwidgets/jqwidgets.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SelectorPestanasComponent } from '../selectorPestanas/selectorPestanas.component';

export const CONTAINERS = [
    SelectorPestanasComponent,
    CabeceraComponent,
    PerfilComponent,
    OpcionMenuComponent,
];

export const EXPORT_CONTAINERS = [
    SelectorPestanasComponent,
    CabeceraComponent,
    PerfilComponent,
    OpcionMenuComponent,
];

export const EXPORT_PROVIDERS = [
    OpcionMenuComponent,
];

@NgModule({
    imports: [
        CommonModule,
        JqwidgetsModule,
        MatProgressBarModule,
        MatTooltipModule
    ],
    declarations: EXPORT_CONTAINERS,
    exports: EXPORT_CONTAINERS,
    providers: EXPORT_PROVIDERS,
    entryComponents: CONTAINERS
})
export class CabeceraModule { }
