import { Component, Input, ComponentRef, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, AfterViewChecked, ViewChildren } from '@angular/core';
import { PestanaComponent } from '../pestana/pestana.component';
import { jqxPopoverComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover';

@Component({
    selector: 'app-selector-pestanas',
    templateUrl: './selectorPestanas.component.html'
})
export class SelectorPestanasComponent implements AfterViewChecked {
    @Input() pestanas: ComponentRef<PestanaComponent>[];
    @Input() pestanaActiva: ComponentRef<PestanaComponent>;
    @Output() alSeleccionarPestana: EventEmitter<{ pestana: ComponentRef<PestanaComponent>, antesDe?: number }> = new EventEmitter();
    @Output() alCerrarPestana: EventEmitter<ComponentRef<PestanaComponent>> = new EventEmitter();

    @ViewChild('listaPrincipalPestanas') listaPrincipal: ElementRef<HTMLDivElement>;
    @ViewChild('listaSecundariaPestanas') listaSecundaria: ElementRef<HTMLDivElement>;
    @ViewChild('popoverPestanas', { read: jqxPopoverComponent }) popoverPestanas: jqxPopoverComponent;

    desborda: boolean;

    constructor(
        private detectorCambios: ChangeDetectorRef) {
    }

    ngAfterViewChecked() {
        let v_desborda: boolean;
        if (this.listaPrincipal && this.listaSecundaria) {
            v_desborda = this.marcaNoVisibles(
                this.listaPrincipal.nativeElement.getElementsByClassName('pestana') as HTMLCollectionOf<HTMLElement>,
                this.listaSecundaria.nativeElement.getElementsByTagName('li') as HTMLCollectionOf<HTMLElement>);
        } else {
            v_desborda = true;
        }

        if (this.popoverPestanas && this.desborda && !v_desborda) {
            this.popoverPestanas.close();
        }
        this.desborda = v_desborda;
        this.detectorCambios.detectChanges();
    }

    private marcaNoVisibles(p_elementosPrincipales: HTMLCollectionOf<HTMLElement>, p_elementosSecundarios: HTMLCollectionOf<HTMLElement>) {
        let v_desborda = false;
        Array.from(p_elementosPrincipales).forEach((p_elementoPrincipal, p_indice) => {
            const v_elementoSecundario = p_elementosSecundarios[p_indice];
            if (this.elementoVisible(p_elementoPrincipal)) {
                p_elementoPrincipal.classList.remove('pestana-no-visible');
                v_elementoSecundario.classList.remove('pestana-no-visible');
            } else {
                p_elementoPrincipal.classList.add('pestana-no-visible');
                v_elementoSecundario.classList.add('pestana-no-visible');
                v_desborda = true;
            }
        });
        return v_desborda;
    }

    activa(p_pestana: ComponentRef<PestanaComponent>) {
        this.alSeleccionarPestana.emit({ pestana: p_pestana });
    }

    activaNoVisible(p_pestana: ComponentRef<PestanaComponent>, p_indice: number) {
        this.popoverPestanas.close();
        const
            v_listaPrincipal = this.listaPrincipal.nativeElement.getElementsByClassName('pestana') as HTMLCollectionOf<HTMLElement>,
            v_listaSecundaria = this.listaSecundaria.nativeElement.getElementsByTagName('li') as HTMLCollectionOf<HTMLElement>;

        const v_anchoNecesario = v_listaPrincipal[p_indice].offsetWidth;
        const v_contenedor = this.listaPrincipal.nativeElement;
        while (p_indice > 0) {
            const v_elemento = v_listaPrincipal[p_indice];
            if (v_elemento.offsetLeft + v_anchoNecesario <= v_contenedor.offsetWidth) {
                break;
            }
            p_indice -= 1;
        }
        // debugger;

        this.alSeleccionarPestana.emit({ pestana: p_pestana, antesDe: p_indice });
    }

    cierra(p_pestana: ComponentRef<PestanaComponent>) {
        this.alCerrarPestana.emit(p_pestana);
    }

    private elementoVisible(p_elemento: HTMLElement): boolean {
        if (this.listaPrincipal) {
            const v_contenedor = this.listaPrincipal.nativeElement;
            return p_elemento.offsetLeft + p_elemento.offsetWidth <= v_contenedor.offsetWidth;
        } else {
            return false;
        }
    }

    totalmenteVisible(p_pestana): boolean {
        return true;
    }
}
