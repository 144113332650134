<jqxInput #jqxinput *ngIf="formatoComponente==='texto' && !multilinea && !campoEsEtiqueta" theme="material"
    [width]="tamanioAncho" height="35" [value]="valor" (focus)="focusFunction()" (focusout)="focusOutFunction()"
    (onChange)="alCambiarValorInput($event)" [placeHolder]="etiqueta" [maxLength]="400">
</jqxInput>

<div *ngIf="(formatoComponente==='texto' && !multilinea) && campoEsEtiqueta">

    <div class="distribucion-vertical">
        <span>{{etiqueta}}</span>
        <span [class]="estilosCampoEsEtiqueta">{{valor}}</span>
    </div>
</div>

<jqxTextArea #jqxtextarea *ngIf="formatoComponente==='texto' && multilinea" theme="material" [width]="tamanioAncho"
    [height]="17*lineas" [placeHolder]="etiqueta" (focus)="focusFunction()" (focusout)="focusOutFunction()"
    [attr.readonly]="soloLectura" (onChange)="alCambiarValorTextArea()" [source]="valor" [maxLength]="400">
</jqxTextArea>

<!-- <jqxNumberInput #jqxnumberinput *ngIf="formatoComponente==='numero'" theme="material" width="100" height="35"
    (focus)="focusFunction()" (focusout)="focusOutFunction()" (onChange)="alCambiarValorNumberInput($event)"
    [placeHolder]="etiqueta" inputMode="simple" [decimalDigits]="numeroDecimales">
</jqxNumberInput> -->

<jqxInput #jqxnumberinput *ngIf="formatoComponente==='numero'" theme="material" [width]="tamanioAncho" height="35"
    [value]="valorNumberInput" (focus)="focusFunction()" (focusout)="focusOutFunction()"
    (onChange)="alCambiarValorNumberInput($event)" [placeHolder]="etiqueta" [maxLength]="400">
</jqxInput>

<jqxDateTimeInput #jqxdatetimeinput
    *ngIf="(formatoComponente==='fecha' || formatoComponente==='hora' || formatoComponente==='fechaHora') && !campoEsEtiqueta"
    theme="material" [width]="tamanioAncho" height="35" [firstDayOfWeek]="firstDay"
    [showTimeButton]="formatoComponente==='hora'"
    [showCalendarButton]="formatoComponente==='fecha' || formatoComponente==='fechaHora'" [placeHolder]="etiqueta"
    [value]="valorDateTimeInput" (onValueChanged)="alCambiarValorDateTimeInput($event)" [culture]="'es-ES'"
    [disabled]="soloLectura">
</jqxDateTimeInput>

<div
    *ngIf="(formatoComponente==='fecha' || formatoComponente==='hora' || formatoComponente==='fechaHora') && campoEsEtiqueta">

    <div class="distribucion-vertical">
        <span>{{etiqueta}}</span>
        <div class="distribucion-horizontal">
            <span
                [class]="['calendario-' + id, estilosCampoEsEtiqueta].join(' ')">{{valorDateTimeInputFormateado}}</span>
            <span [class]="['calendario-' + id, 'icono-calendario'].join(' ')"><span class="icon-today"></span></span>
        </div>
    </div>
    <jqxPopover #dropDownButton [selector]="'.calendario-' + id" dropDownHorizontalAlignment="left">
        <jqxCalendar (onChange)="alCambiarValorDateTimeInput($event)">
        </jqxCalendar>
    </jqxPopover>
</div>