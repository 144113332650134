<a *ngIf="hijas.length === 0" href="javascript:void(0);">
    <span>{{etiqueta}}</span>
</a>

<ng-container *ngIf="hijas.length > 0">
    {{etiqueta}}
    <ul>
        <li app-opcion-menu *ngFor="let hija of hijas" [id]="hija.codigo" class="jqx-item" [codigo]="hija.codigo"
            [etiqueta]="hija.etiqueta" [hijas]="hija.hijas">
        </li>
    </ul>
</ng-container>