<div class="spyGrid">
    <!-- id="grid"
        [columns]="columns" [pageable]="true" [pagesize]="pageSize"
        [(pagesizeoptions)]="pageSizeOptions" [virtualmode]="true" [rendergridrows]="renderGridRows"
        [showtoolbar]="true" [sortable]="true" (onSort)="myGridOnSort($event)" [altrows]="true"
        [rendertoolbar]="createButtonsContainers" (onCellclick)="spyGridOnCellClick($event)"
        (onRowselect)="spyGridOnRowSelect($event)" (onRowunselect)="spyGridOnRowUnselect($event)"
        [columnsresize]="'true'" [columnsautoresize]="true" [columnsreorder]="true" [localization]="localization"
        [autoheight]="true" (onCelldoubleclick)="cellDoubleClick($event)" [rowdetails]="haveRowDetail()"
        [initrowdetails]="initRowDetails" [rowdetailstemplate]="rowDetailsTemplate" [selectionmode]="'singlecell'"
        (onPagechanged)="onPageChanged($event)"
    -->
    <!-- <jqxGrid #jqxgrid [width]="'100%'" [source]="adaptador" [columns]="columnasGrid" [sortable]="true"
        [columnsresize]="true" [altrows]="true" [autoheight]="true" [pageable]="false" [pagesize]="registrosPorPagina"
        [pagesizeoptions]="opcionesRegistrosPorPagina" (onRowselect)="alSeleccionarFila($event)"
        (onPagechanged)="alCambiarNumeroPagina($event)" (onPagesizechanged)="alCambiarRegistrosPorPagina($event)">
    </jqxGrid> -->

    <jqxGrid #jqxgrid [width]="tamanioAncho" [autoheight]="true" [source]="adaptador" [columns]="columnasGrid"
        [columnsresize]="true" [altrows]="true" [autoheight]="true" [pageable]="false"
        (onRowselect)="alSeleccionarFila($event)" [selectionmode]="modoSeleccion" [enablehover]="habilitaResalte"
        (onRowclick)="alPulsarFila($event)" [rowsheight]="40" (onCelldoubleclick)="dobleClicSobreCelda($event)"
        [showcolumnlines]="false" [showcolumnheaderlines]="false" [showheader]="true" [columnsresize]="true">
    </jqxGrid>

    <div *ngIf="tienePaginacion" class="tabla-paginacion">
        <!-- <input type="number" class="spyInput-value numero" [value]="numeroPagina"
            (change)="numeroPagina=$event.target.value" /> -->

        <span>
            <span>Filas a mostrar:</span>
            <input type="number" class="spyInput-value numero" [value]="registrosPorPagina"
                (change)="registrosPorPagina=$event.target.value" min="10" max="30" step="10" />
        </span>
        <span>{{((numeroPagina - 1) * registrosPorPagina) + 1}} a {{numeroPagina * registrosPorPagina}}</span>

        <i class="icon-arrow_left" (click)="paginaAnterior($event)"></i>
        <i class="icon-arrow_right" (click)="paginaSiguiente($event)"></i>
    </div>

    <!--
    <div *ngIf="mostrarBotones" class="spyGrid-actions">
        <button (click)="exportToExcelClick()" class="spyGrid-button">Exportar a Excel</button>
        <button (click)="exportToPDFClick()" class="spyGrid-button">Exportar a PDF</button>
        <button (click)="printClick()" class="spyGrid-button">Imprimir</button>
    </div>
    -->
</div>
<!--(onPagesizechanged)="onPageChanged($event)"-->