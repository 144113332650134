import { Injectable } from '@angular/core';
import { ResultadoAccion } from '../abstracto/acciones/evotec_acciones';

@Injectable({
    providedIn: 'root'
})
export class EventosService {
    private capturados: (() => ResultadoAccion)[] = [];
    private temporizador = null;

    private lanzarEventos(): Promise<ResultadoAccion> {
        return this.generaPromesa(this.capturados);
    }

    private generaPromesa(p_eventos: (() => ResultadoAccion)[]): Promise<ResultadoAccion> {
        const
            v_primero = p_eventos[0],
            v_resto = p_eventos.slice(1);
        let v_promesa = Promise.resolve(v_primero());
        if (v_resto.length > 0) {
            v_promesa = v_promesa.then(p => this.generaPromesa(v_resto));
        }
        return v_promesa;
    }

    capturaEvento(p_evento: () => ResultadoAccion): void {
        if (typeof p_evento !== 'function') {
            return;
        }

        this.capturados.push(p_evento);

        if (this.temporizador !== null) {
            clearTimeout(this.temporizador);
        }

        this.temporizador = setTimeout(() => {
            this.temporizador = null;
            this.lanzarEventos();
            this.capturados = [];
        }, 200);
    }
}
