import { Component, Input } from '@angular/core';
import { OpcionMenu } from './opcionMenu';

@Component({
    selector: '[app-opcion-menu]',
    templateUrl: './opcionMenu.component.html',
    styleUrls: []
})
export class OpcionMenuComponent {
    private _codigo: string;
    @Input() get codigo(): string { return this._codigo; }
    set codigo(p_valor: string) { this._codigo = p_valor; }

    private _etiqueta: string;
    @Input() get etiqueta(): string { return this._etiqueta; }
    set etiqueta(p_valor: string) { this._etiqueta = p_valor; }

    private _hijas: OpcionMenu[];
    @Input() get hijas(): OpcionMenu[] { return this._hijas; }
    set hijas(p_valor: OpcionMenu[]) { this._hijas = p_valor; }

    // private _alPulsar: () => void = () => { };

    // @HostListener('click')
    // get alPulsar(): () => void { return this._alPulsar; }
    // set alPulsar(p_manejador: () => void) { this._alPulsar = p_manejador; }
}
