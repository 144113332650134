import { Diccionario, formateaFecha, comoJson, ErrorDefinicion, valida } from '../evotec_comun';
import { ValorAccion, AccionAtributo, AccionObjeto, AccionVariable, Accion, esAccion } from '../especificacion/acciones';
import { TablaSimbolos, Ambito } from '../ejecucion/simbolos';
import { cambios, InstanciaObjeto, InstanciaArray, Modelo } from '../ejecucion/modelo';
import {
    TipoParametrizado, TipoFuncion, TipoArray, TipoObjeto,
    Tipo, NombreTipo, esNombreTipo,
    tipoNulo, tipoIndeterminado, tipoNumero, tipoLogico, tipoTexto, tipoFecha, tipoHora
} from './tipos';
import { Variable } from './variables';
import { Activacion } from './activacion';
import { Configuracion } from '../ejecucion/configuracion';
import {
    operacionSecuencia, operacionSi, operacionMientras, operacionModelo, operacionModeloInvocante, operacionObjeto, operacionInstanciaTipo,
    operacionInvocaFuncion, operacionInvocaApi, operacionCreaVariable, operacionVariable, operacionAtributo, operacionContexto, operacionVuelca,
    operacionDevuelve, operacionPregunta, operacionInforma, operacionNada, operacionNavega, operacionModal, operacionCierra, operacionCierraModal,
    Operaciones
} from './operaciones';

export function invocaAccionBase(p_parametros: object, p_contextoLlamada: any): ResultadoAccion | Promise<ResultadoAccion> {
    return Compilador.ejecuta({
        accion_id: '_invocaFuncion',
        funcion: 'navega',
        argumentos: [p_parametros]
    }, undefined, undefined, undefined, p_contextoLlamada);
}

export type ResultadoAccion =
    undefined | null | object | string | number | boolean | void |
    Promise<undefined | null | object | string | number | boolean | void>;

/**
 *
 * @param p_resultadoAccion
 * p_resultadoAccion es de tipo objeto
 */
export function resultadoAccionEsObjeto(p_resultadoAccion: ResultadoAccion): p_resultadoAccion is ObjetoAccion {
    return typeof p_resultadoAccion === 'object';
}

export type ObjetoAccion = Diccionario<ResultadoAccion>;

export type FuncionAccionResuelta = (p_activacion: Activacion) => ResultadoAccion;

export class AccionResuelta {
    readonly asincrona: boolean;
    readonly tipo: Tipo;
    evalua: FuncionAccionResuelta;
    readonly accion: ValorAccion;

    constructor(p_asincrona: boolean, p_tipo: Tipo, p_evalua: FuncionAccionResuelta, p_accion: ValorAccion) {
        this.asincrona = p_asincrona;
        this.tipo = p_tipo;
        this.evalua = p_evalua;
        this.accion = p_accion;
    }

    evaluaYNotificaCambios(p_activacion: Activacion): any {
        const v_resultado = Promise.resolve(this.evalua(p_activacion)).finally(() => cambios.notifica());
        return v_resultado;
    }
}

/**
 * 
 * @param p_objeto 
 * p_objeto es de la clase AccionResueltas
 */
export function esAccionResuelta(p_objeto: any): p_objeto is AccionResuelta {
    return p_objeto instanceof AccionResuelta;
}
interface ResultadoNavega {
    modelo: any;
    datos: any;
    vista: any;
}

type ResultadoActModelo = Diccionario;

interface ResultadoModal {
    modelo: any;
    datos: any;
    vista: any;
}

// tslint:disable-next-line: no-empty-interface
interface ResultadoCierraModal {
}

// tslint:disable-next-line: no-empty-interface
interface ResultadoCierraTab {
}

interface ResultadoInforma {
    mensaje: string;
}

interface AccionRespuesta<T = any> {
    accion: string;
    respuesta: T;
}

export interface RespuestaApi<T = AccionRespuesta | AccionRespuesta[]> {
    Ok: boolean;
    Mensaje?: string;
    Objeto: T;
}

/**
 * 
 * @param p_accionRespuesta 
 * La accion de respuesta que devuelve es navega y lo convierte en minuscula
 */
function esNavega(p_accionRespuesta: AccionRespuesta): p_accionRespuesta is AccionRespuesta<ResultadoNavega> {
    console.log(`Podemos quitar este '.toLowerCase()'?`);
    return p_accionRespuesta.accion.toLowerCase() === 'navega';
}

/**
 * 
 * @param p_accionRespuesta 
 * La accion de respuesta que devuelve es actualizaModelo.
 */
function esActualizaModelo(p_accionRespuesta: AccionRespuesta): p_accionRespuesta is AccionRespuesta<ResultadoActModelo> {
    return p_accionRespuesta.accion === 'actualizaModelo';
}

/**
 * 
 * @param p_accionRespuesta 
 * La accion de respuesta que devuelve es un modal.
 */
function esModal(p_accionRespuesta: AccionRespuesta): p_accionRespuesta is AccionRespuesta<ResultadoModal> {
    return p_accionRespuesta.accion === 'modal';
}

/**
 * 
 * @param p_accionRespuesta 
 * La accion de respuesta que devuelve es cieraModal.
 */
function esCierraModal(p_accionRespuesta: AccionRespuesta): p_accionRespuesta is AccionRespuesta<ResultadoCierraModal> {
    return p_accionRespuesta.accion === 'cierraModal';
}

/**
 * 
 * @param p_accionRespuesta 
 * La accion de respuesta que devuelve es cierraTab.
 */
function esCierraTab(p_accionRespuesta: AccionRespuesta): p_accionRespuesta is AccionRespuesta<ResultadoCierraTab> {
    return p_accionRespuesta.accion === 'cierraTab';
}

function esInforma(p_accionRespuesta: AccionRespuesta): p_accionRespuesta is AccionRespuesta<ResultadoInforma> {
    return p_accionRespuesta.accion === 'informa';
}

function mostrarMensajeInformativo(p_respuesta: RespuestaApi): RespuestaApi {
    const
        v_accionInforma = {
            accion: 'informa',
            respuesta: { mensaje: p_respuesta.Mensaje }
        },
        v_accionesRespuesta = [v_accionInforma].concat(p_respuesta.Objeto),
        v_respuesta: RespuestaApi = {
            Ok: p_respuesta.Ok,
            Objeto: v_accionesRespuesta
        };
    return v_respuesta;
}

/**
 * 
 * @param p_respuesta 
 * Si v_mensaje esta sin definir, da un error sin mensaje en la llamada al api.
 * Se limita los mensajes a 500 caracteres para que entre en la pantalla
 */
function mostrarError(p_respuesta: RespuestaApi): RespuestaApi {
    console.log(p_respuesta.Mensaje);

    let v_mensaje = p_respuesta.Mensaje;
    if (typeof v_mensaje === 'undefined') {
        v_mensaje = 'Error interno; Se ha producido un error sin mensaje en la llamada al API';
    }

    const v_etiquetas = v_mensaje.split('\n').map(p_parrafo => ({
        component: 'spyLabel',
        valor: p_parrafo.length > 1000 ? `${p_parrafo.substring(0, 1000)}...` : p_parrafo
    }));

    // Limito los mensajes a 500 caracteres ya que si los mensajes son demasiado largos no entran en la pantalla.
    // if (v_mensaje.length > 1000) {
    //     v_mensaje = `${v_mensaje.substring(0, 1000)}...`;
    // }

    p_respuesta['Ok'] = true;
    p_respuesta['Objeto'] = {
        accion: 'modal',
        respuesta: {
            modelo: {},
            datos: {},
            vista: {
                component: 'layoutVertical',
                components: [
                    {
                        component: 'spyCard',
                        etiqueta: '¡Ups, parece que algo va mal!',
                        icono: 'error',
                        width: '100%',
                        components: [
                            {
                                component: 'layoutVertical',
                                components: v_etiquetas
                            }]
                    }]
            }
        }
    };
    return p_respuesta;
}

export class Compilador {
    private static id = 0;
    readonly globales: Ambito;

    private readonly operaciones: Operaciones;
    readonly resuelvePromesa: ((p_valor: any) => void) | undefined;
    readonly rechazaPromesa: ((p_motivo: any) => void) | undefined;

    private static evaluaAccion(p_item: AccionResuelta, p_activacion: Activacion): Exclude<ResultadoAccion, undefined> {
        const v_valor = p_item.evalua(p_activacion);
        if (typeof v_valor === 'undefined') {
            return null;
        }
        return v_valor;
    }

    /**
     * 
     * @param p_valor 
     * Genera una acción de tipo AccionObjeto cuando el valor pasado sea un objeto. Si no lo es, devuelve el propio valor.
     */
    public static generaAccionObjeto(p_valor: null | Record<string, any> | string | number | boolean): AccionObjeto | null | object | string | number | boolean {
        if (p_valor === null) {
            return null;
        } else if (p_valor instanceof Date) {
            return p_valor;
        } else if (Array.isArray(p_valor)) {
            // debugger;
            p_valor = p_valor.map(p_item => Compilador.generaAccionObjeto(p_item));
            return p_valor;
        } else if (typeof p_valor === 'object') {
            const v_propiedades = Object.getOwnPropertyNames(p_valor);
            return {
                accion_id: '_objeto',
                propiedades: v_propiedades.reduce((p_anterior, p_propiedad) => {
                    if (typeof p_valor[p_propiedad] === 'undefined') {
                        p_anterior[p_propiedad] = undefined;
                    }
                    p_anterior[p_propiedad] = Compilador.generaAccionObjeto(p_valor[p_propiedad]);
                    return p_anterior;
                }, {} as Diccionario<ValorAccion>)
            };
        } else {
            return p_valor;
        }
    }

    /**
     * 
     * @param p_ruta 
     *  Descompone una expresión de propiedad en los distintos sus componentes.
     */
    static descomponerRuta(p_ruta: string): string[] {
        const v_componentes = p_ruta.split('.');
        return v_componentes;
    }

    // Genera una acción que lee o asigna un valor a una variable o una propiedad. La variable o la propiedad se especifica
    // como un array de string.
    // Cada uno de los elementos de ese array denota un nombre que se resolverá empezando del ámbito global de nombres.
    static generaAccionRuta(p_componentesRuta: string[], p_nuevoValor?: ValorAccion): AccionVariable | AccionAtributo {
        const v_nueva_accion = p_componentesRuta.slice(1).reduce<AccionVariable | AccionAtributo>((p_accion, p_componente) => {
            return {
                accion_id: '_atributo',
                objeto: p_accion,
                nombre: p_componente
            } as AccionAtributo;
        }, { accion_id: '_variable', nombre: p_componentesRuta[0] } as AccionVariable);

        if (typeof p_nuevoValor !== 'undefined') {
            v_nueva_accion.nuevoValor = p_nuevoValor;
        }
        return v_nueva_accion;
    }

    static resuelve(
        p_accion: Accion,
        p_modelos?: { nombre: string, valor: Variable }[],
        p_resuelve?: (p_valor: any) => void,
        p_rechaza?: (p_motivo: any) => void): AccionResuelta {

        const v_compilador = new Compilador(p_resuelve, p_rechaza);
        if (typeof p_modelos !== 'undefined') {
            p_modelos.forEach(({ nombre: p_nombre, valor: p_valor }) => {
                v_compilador.registraGlobal(p_nombre, p_valor);
            });
        }

        const
            v_ambito = v_compilador.globales,
            v_simbolos = new TablaSimbolos(v_ambito);
        const v_accion = v_compilador.resuelve(p_accion, v_simbolos);
        return v_accion;
    }

    static ejecuta(
        p_accion: Accion | AccionResuelta,
        p_modelos?: ({ nombre: string, valor: Variable }[]),
        p_resuelve?: (p_valor: any) => void,
        p_rechaza?: (p_motivo: any) => void,
        p_contextoLlamada?: any
    ): ResultadoAccion | Promise<ResultadoAccion> {

        const v_compilador = new Compilador(p_resuelve, p_rechaza);
        // if (p_modelos)
        //    v_compilador.registraGlobal("@modelo", p_modelos);
        if (typeof p_modelos !== 'undefined' && Array.isArray(p_modelos)) {
            p_modelos.forEach(({ nombre: p_nombre, valor: p_valor }) => {
                v_compilador.registraGlobal(p_nombre, p_valor);
            });
        }

        let v_accion: AccionResuelta;
        if (esAccion(p_accion)) {
            const
                v_ambito = v_compilador.globales,
                v_simbolos = new TablaSimbolos(v_ambito);
            v_accion = v_compilador.resuelve(p_accion, v_simbolos);
        } else {
            v_accion = p_accion;
        }

        const
            v_ranuras = v_compilador.globales.ranuras().map(p_ranura => p_ranura.valor),
            v_resultado = Promise.resolve(v_accion.evalua(new Activacion(v_ranuras, v_compilador.resuelvePromesa, v_compilador.rechazaPromesa, p_contextoLlamada)))
                .then(p_resultado => {
                    if (p_resultado instanceof InstanciaObjeto || p_resultado instanceof InstanciaArray) {
                        const v_resultado = p_resultado.valor();
                        return v_resultado;
                    } /*else if (typeof p_resultado === 'object') {
                        const 
                            v_propiedades = Object.getOwnPropertyNames(p_resultado),

                    }*/ else {
                        return p_resultado;
                    }
                });
        return v_resultado;
    }

    private procesaAccionRespuesta(p_accionRespuesta: AccionRespuesta<any>, p_modelo: Modelo) {
        if (typeof p_accionRespuesta.accion === 'undefined') {
            throw new ErrorDefinicion(`Operación no válida; No es una acción de respuesta ${comoJson(p_accionRespuesta)}`);
        }
        if (esNavega(p_accionRespuesta)) {
            // console.log(`*** AccionBase [id = ${v_idLlamada}] responde con acción '${v_accionRespuesta.accion}'.`);
            const v_accionNavega = {
                accion_id: '_navega',
                pantalla: p_accionRespuesta.respuesta
            };
            return v_accionNavega;

            // console.log(`*** llamada a AccionBase [id = ${v_idLlamada}] finalizada.`);
        }
        // si el backend solicita cerrar la pestaña
        else if (esCierraTab(p_accionRespuesta)) {
            // console.log(`*** AccionBase responde con acción "${v_accionRespuesta.accion}"; id = ${v_idLlamada}`);
            const v_accionCierra = {
                accion_id: 'cierra'
            };
            return v_accionCierra;

            // console.log(`*** llamada a AccionBase [id = ${v_idLlamada}] finalizada.`);
        }
        // si el backend solicita cerrar una modal
        else if (esCierraModal(p_accionRespuesta)) {
            // console.log(`*** AccionBase responde con acción "${v_accionRespuesta.accion}"; id = ${v_idLlamada}`);
            const v_accionCierraModal = {
                accion_id: 'cierraModal'
            };
            return v_accionCierraModal;
            // console.log(`*** llamada a AccionBase [id = ${v_idLlamada}] finalizada.`);
        }
        // y en el backend se solicita abrir un modal
        else if (esModal(p_accionRespuesta)) {
            // console.log(`*** AccionBase responde con acción "${v_accionRespuesta.accion}"; id = ${v_idLlamada}`);

            // TODO Hay casos en los que el primer componente que llega es una pestaña. Lo cambiamos por una tarjeta para salir del paso.
            if (p_accionRespuesta.respuesta.vista.component === 'spyTab') {
                const v_pestana = p_accionRespuesta.respuesta.vista;
                if (v_pestana.components.length === 1) {
                    p_accionRespuesta.respuesta.vista = v_pestana.components[0];
                } else {
                    p_accionRespuesta.respuesta.vista.component = 'spyCard';
                }
            }

            const v_accionModal = {
                accion_id: '_modal',
                pantalla: p_accionRespuesta.respuesta
            };
            return v_accionModal;
            // console.log(`*** llamada a AccionBase [id = ${v_idLlamada}] finalizada.`);
        }
        // si el backend solicita hacer una actualización de modelo
        else if (esActualizaModelo(p_accionRespuesta)) {
            // console.log(`*** AccionBase responde con acción "${v_accionRespuesta.accion}"; id = ${v_idLlamada}`);

            // Generamos una acción de actualización del modelo
            const x = p_modelo.resuelveModelo2();

            const
                v_propiedades = Object.getOwnPropertyNames(p_accionRespuesta.respuesta),
                v_respuesta = x(p_accionRespuesta.respuesta),
                v_acciones = v_propiedades.map(p_propiedad => {
                    return {
                        accion_id: '_atributo',
                        objeto: { accion_id: '_variable', nombre: '@modelo' },
                        nombre: p_propiedad,
                        nuevoValor: v_respuesta[p_propiedad]
                    } as AccionAtributo;
                });

            if (v_acciones.length > 1) {
                const v_accionSecuencia = {
                    accion_id: '_secuencia',
                    acciones: v_acciones
                };

                return v_accionSecuencia;
            } else if (v_acciones.length === 1) {
                return v_acciones[0];
            } else {
                return { accion_id: '_nada' };
            }

            // console.log(`*** llamada a AccionBase [id = ${v_idLlamada}] finalizada.`);
        } else if (esInforma(p_accionRespuesta)) {
            // console.log(`*** AccionBase responde con acción "${v_accionRespuesta.accion}"; id = ${v_idLlamada}`);
            const v_accionInforma = {
                accion_id: '_informa',
                mensaje: p_accionRespuesta.respuesta.mensaje
            };
            return v_accionInforma;
            // console.log(`*** llamada a AccionBase [id = ${v_idLlamada}] finalizada.`);
        } else {
            throw new ErrorDefinicion(`"${p_accionRespuesta.accion}" no es una acción de respuesta válida. Comprueba que la propiedad 'accion' tenga un valor válido.`);
        }
    }

    private procesaRespuesta(p_activacion: Activacion, p_respuesta: RespuestaApi<AccionRespuesta<any> | AccionRespuesta<any>[]>, p_modelo: Modelo) {
        // En caso de que la respuesta no sea Ok, se genera un modal con el mensaje
        if (p_respuesta.Ok) {
            if (typeof p_respuesta.Mensaje === 'string' && p_respuesta.Mensaje.length > 0) {
                p_respuesta = mostrarMensajeInformativo(p_respuesta);
            }
        } else {
            p_respuesta = mostrarError(p_respuesta);
        }

        // Si la respuesta es no es un array, generamos uno con el objeto recibido
        let v_accionesRespuesta: AccionRespuesta[];
        if (Array.isArray(p_respuesta.Objeto)) {
            v_accionesRespuesta = p_respuesta.Objeto;
        } else {
            v_accionesRespuesta = [p_respuesta.Objeto];
        }

        // const v_modelo = p_activacion.contextoLlamada.definicionLlamada as Modelo;

        // Generamos una secuencia de acciones con las acciones devueltas
        const v = {
            accion_id: '_secuencia',
            acciones: v_accionesRespuesta.map(p_accionRespuesta => this.procesaAccionRespuesta(p_accionRespuesta, p_modelo))
        };
        const v_accionSecuencia = this.resuelve(v);

        // Ejecutamos la secuencia y notificamos los cambios a los componentes
        const v_resultado = Promise.resolve(this.ejecuta(v_accionSecuencia, p_activacion.contextoLlamada)).finally(() => cambios.notifica());
        return v_resultado;
    }

    private navega(p_activacion: Activacion, p_parametros: Diccionario): Promise<any> {
        const v_idLlamada = ++Compilador.id;
        console.log(`*** llamando a AccionBase [id = ${v_idLlamada}].`);

        console.log(p_parametros);
        try {
            const x = JSON.stringify(p_parametros);
        }
        catch (ex) {
            debugger;
        }

        const v_tipoModelo = this.globales.existe('@modelo') ? this.globales.simbolo('@modelo').tipo : undefined;
        valida(v_tipoModelo instanceof TipoObjeto || typeof v_tipoModelo === 'undefined');

        const v_modelo = p_activacion.contextoLlamada.definicionModelo as Modelo;

        return Configuracion.accionBase({ PARAMETROS: p_parametros }, p_activacion, v_modelo, (p_respuesta, p_modelo) => this.procesaRespuesta(p_activacion, p_respuesta, p_modelo));
        console.log(`*** llamada a AccionBase [id = ${v_idLlamada}] iniciada.`);
    }

    private alerta(p_mensaje: any) {
        if (typeof p_mensaje === 'object' || Array.isArray(p_mensaje)) {
            alert(JSON.stringify(p_mensaje));
        } else {
            alert(p_mensaje);
        }
        return p_mensaje;
    }

    private agrega<T>(p_lista: T[], p_funcion: (p: T, q: T) => T): T {
        const
            v_primero = p_lista[0],
            v_resto = p_lista.slice(1);
        return v_resto.reduce(p_funcion, v_primero);
    }

    private consola(p_mensaje: any) {
        console.log(p_mensaje);
        return p_mensaje;
    }

    constructor(p_resuelvePromesa: ((p_valor: any) => void) | undefined, p_rechazaPromesa: ((p_motivo: any) => void) | undefined) {
        if (typeof p_resuelvePromesa === 'undefined') {
            p_resuelvePromesa = () => { };
        }

        if (typeof p_rechazaPromesa === 'undefined') {
            p_rechazaPromesa = () => { };
        }

        this.resuelvePromesa = p_resuelvePromesa;
        this.rechazaPromesa = p_rechazaPromesa;

        this.operaciones = {
            '_secuencia': operacionSecuencia
            , '_si': operacionSi
            , '_mientras': operacionMientras
            , '_modelo': operacionModelo
            , '_modeloInvocante': operacionModeloInvocante
            , '_objeto': operacionObjeto
            , '_instanciaTipo': operacionInstanciaTipo
            , '_invocaFuncion': operacionInvocaFuncion
            , 'invocaApi': operacionInvocaApi
            , '_creaVariable': operacionCreaVariable
            // , '_creaFuncion': this.operacionCreaFuncion
            , '_variable': operacionVariable
            , '_atributo': operacionAtributo
            , '_contexto': operacionContexto
            , '_vuelca': operacionVuelca
            , 'devuelve': operacionDevuelve
            , '_pregunta': operacionPregunta
            , '_informa': operacionInforma
            , '_nada': operacionNada
            , '_navega': operacionNavega
            , '_modal': operacionModal
            , 'cierra': operacionCierra
            , 'cierraModal': operacionCierraModal
        };

        this.globales = new Ambito({
            // 'cierra_vista': {
            //     tipo: new Variables.TipoFuncion([], 'indeterminado'),
            //     valor: () => { throw new Error('En desuso'); }
            // },
            'alerta': {
                tipo: new TipoParametrizado(['T'], new TipoFuncion(['T'], 'T')),
                valor: this.alerta.bind(this)
            },
            // 'cierraModal': {
            //     tipo: new Variables.TipoFuncion([], 'indeterminado'),
            //     valor: () => { throw new Error('En desuso'); }
            // },
            'navega': {
                tipo: new TipoFuncion([tipoIndeterminado], tipoIndeterminado, true, this.resuelveArgumentosNavega.bind(this)),
                valor: this.navega.bind(this)
            },
            'consola': {
                tipo: new TipoParametrizado(['T'], new TipoFuncion(['T'], 'T')),
                valor: this.consola.bind(this)
            },
            'array': {
                tipo: new TipoParametrizado(['T'], new TipoObjeto('array', {
                    '[]': new TipoFuncion([new TipoArray('T'), tipoNumero], 'T'),
                    'agrega': new TipoFuncion([new TipoArray('T'), new TipoFuncion(['T', 'T'], 'T')], 'T'),
                })),
                // tipo: new Variables.TipoParametrizado(['T'], new Variables.TipoArray('T')),
                valor: {
                    '[]': (p_lista: any[], p_indice: number) => {
                        return p_lista[p_indice];
                    },
                    'agrega': this.agrega.bind(this),
                }
            },
            'indeterminado': {
                tipo: tipoIndeterminado,
                valor: {
                    'to_string': (p_operando: any) => '' + p_operando,
                    'to_array': (p_operando: any) => p_operando,
                }
            },
            'nulo': {
                tipo: tipoNulo,
                valor: null
            },
            'logico': {
                tipo: tipoLogico,
                valor: {
                    '=': (p_operando1: boolean, p_operando2: boolean) => p_operando1 === p_operando2,
                    '<>': (p_operando1: boolean, p_operando2: boolean) => p_operando1 !== p_operando2,
                    'no': (p_operando: boolean) => !p_operando
                }
            },
            'numero': {
                tipo: tipoNumero,
                valor: {
                    '+': (p_operando1: number, p_operando2: number) => p_operando1 + p_operando2,
                    '-': (p_operando1: number, p_operando2: number) => p_operando1 - p_operando2,
                    '*': (p_operando1: number, p_operando2: number) => p_operando1 * p_operando2,
                    '/': (p_operando1: number, p_operando2: number) => p_operando1 / p_operando2,
                    '<': (p_operando1: number, p_operando2: number) => p_operando1 < p_operando2,
                    '<=': (p_operando1: number, p_operando2: number) => p_operando1 <= p_operando2,
                    '>': (p_operando1: number, p_operando2: number) => p_operando1 > p_operando2,
                    '>=': (p_operando1: number, p_operando2: number) => p_operando1 >= p_operando2,
                    '=': (p_operando1: number, p_operando2: number) => p_operando1 === p_operando2,
                    '<>': (p_operando1: number, p_operando2: number) => p_operando1 !== p_operando2,
                    'negativo': (p_operando: number) => -p_operando,
                    'to_string': (p_operando: number) => p_operando
                }
            },
            'texto': {
                tipo: tipoTexto,
                valor: {
                    '+': (p_operando1: string, p_operando2: string) => p_operando1 + p_operando2,
                    '<': (p_operando1: string, p_operando2: string) => p_operando1 < p_operando2,
                    '<=': (p_operando1: string, p_operando2: string) => p_operando1 <= p_operando2,
                    '>': (p_operando1: string, p_operando2: string) => p_operando1 > p_operando2,
                    '>=': (p_operando1: string, p_operando2: string) => p_operando1 >= p_operando2,
                    '=': (p_operando1: string, p_operando2: string) => p_operando1 === p_operando2,
                    '<>': (p_operando1: string, p_operando2: string) => p_operando1 !== p_operando2
                }
            },
            'fecha': {
                tipo: tipoFecha,
                valor: {
                    // '<': (p_operando1: string, p_operando2: string) => p_operando1 < p_operando2,
                    // '<=': (p_operando1: string, p_operando2: string) => p_operando1 <= p_operando2,
                    // '>': (p_operando1: string, p_operando2: string) => p_operando1 > p_operando2,
                    // '>=': (p_operando1: string, p_operando2: string) => p_operando1 >= p_operando2,
                    // '=': (p_operando1: string, p_operando2: string) => p_operando1 === p_operando2,
                    // '<>': (p_operando1: string, p_operando2: string) => p_operando1 !== p_operando2,
                    'to_string': (p_operando: Date) => p_operando
                        .toLocaleString('es-es', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })
                        .replace(/(\d+)\/(\d+)\/(\d+)\/(\d+)\/(\d+)\/(\d+)/, '$3/$1/$2 $4:$5:$6')
                }
            },
            'hora': {
                tipo: tipoHora,
                valor: {
                    '<': (p_operando1: string, p_operando2: string) => p_operando1 < p_operando2,
                    '<=': (p_operando1: string, p_operando2: string) => p_operando1 <= p_operando2,
                    '>': (p_operando1: string, p_operando2: string) => p_operando1 > p_operando2,
                    '>=': (p_operando1: string, p_operando2: string) => p_operando1 >= p_operando2,
                    '=': (p_operando1: string, p_operando2: string) => p_operando1 === p_operando2,
                    '<>': (p_operando1: string, p_operando2: string) => p_operando1 !== p_operando2
                }
            },
        });
    }

    registraGlobal(p_nombre: string, p_variable: Variable): void {
        this.globales.creaSimbolo(p_nombre, p_variable.tipo, p_variable.valor);
    }

    private normalizaTipo(p_tipo: Tipo | NombreTipo): Tipo {
        if (typeof p_tipo === 'undefined') {
            throw new RangeError(`Argumento 'p_tipo' no definido`);
        }

        let v_tipo: Tipo;
        if (esNombreTipo(p_tipo)) {
            v_tipo = this.globales.simbolo(p_tipo).tipo;
        } else {
            v_tipo = p_tipo;
        }
        return v_tipo;
    }

    resuelveArgumentos(p_accion: ValorAccion[], p_simbolos: TablaSimbolos) {
        const
            v_accion = p_accion.map(p_item => this.resuelve(p_item, p_simbolos)),
            v_asincrona = v_accion.some(p_item => p_item.asincrona);
        let v_evalua: FuncionAccionResuelta;
        if (v_asincrona) {
            v_evalua = p_activacion => Promise
                .all(([...v_accion.map(p_funcItem => Compilador.evaluaAccion(p_funcItem, p_activacion))]))
                .then(p_items => [...p_items]);
        } else {
            v_evalua = p_activacion => v_accion.map(p_funcItem => Compilador.evaluaAccion(p_funcItem, p_activacion));
        }
        const v_resultado = {
            asincrona: v_asincrona,
            tipo: v_accion.map(({ tipo: p_tipoItem }) => p_tipoItem),
            evalua: v_evalua,
            accion: v_accion
        };
        return v_resultado;
    }

    private resuelveArgumentoNavega(p_item: ValorAccion, p_simbolos: TablaSimbolos) {
        const v_accionResuelta = this.resuelve(p_item, p_simbolos);

        if (v_accionResuelta.tipo === tipoFecha) {
            const v_evaluaAnterior = v_accionResuelta.evalua;
            if (v_accionResuelta.asincrona) {
                v_accionResuelta.evalua = p_activacion => {
                    const v_resultado = v_evaluaAnterior(p_activacion);
                    valida(v_resultado instanceof Promise, `Se esperaba una promesa pero se obtuvo '${comoJson(v_resultado)}'`);
                    return v_resultado.then(formateaFecha);
                };
            } else {
                v_accionResuelta.evalua = p_activacion => {
                    const v_resultado = v_evaluaAnterior(p_activacion);
                    valida(typeof v_resultado === 'string', `Se esperaba un 'string' representando una fecha pero se obtuvo '${comoJson(v_resultado)}'`);
                    return formateaFecha(v_resultado);
                };
            }
            return v_accionResuelta;
        } else if (v_accionResuelta.tipo instanceof TipoObjeto) {
            const
                v_tipoObjeto = v_accionResuelta.tipo,
                v_propiedades = Object.getOwnPropertyNames(v_tipoObjeto.miembros),
                v_evaluaObjeto = v_propiedades.reduce<FuncionAccionResuelta>((p_evalua, p_propiedad) => {
                    const
                        v_evalua = p_evalua,
                        v_propiedad = v_tipoObjeto.miembros[p_propiedad];
                    if (this.normalizaTipo(v_propiedad) === tipoFecha) {
                        if (v_accionResuelta.asincrona) {
                            return p_activacion => {
                                const v_resultado = v_evalua(p_activacion);
                                valida(v_resultado instanceof Promise, `Se esperaba una promesa pero se obtuvo '${comoJson(v_resultado)}'`);
                                return v_resultado.then(formateaFecha);
                            }
                        } else {
                            return p_activacion => {
                                const v_objeto = v_evalua(p_activacion);
                                if (typeof v_objeto !== 'undefined' && v_objeto !== null) {
                                    const v_fecha = Configuracion.formatoFecha.fecha(v_objeto[p_propiedad]);
                                    v_objeto[p_propiedad] = Configuracion.formatoFechaApi.formateaFecha(v_fecha);
                                }

                                return v_objeto;
                            };
                        }
                    } else if (v_propiedad instanceof TipoObjeto) {
                        const
                            v_item = (p_item as AccionObjeto).propiedades[p_propiedad],
                            v_accionResuelta = this.resuelveArgumentoNavega(v_item, p_simbolos);
                        return p_activacion => {
                            const v_objeto = v_evalua(p_activacion);
                            const v_propiedad = v_accionResuelta.evalua(p_activacion);
                            v_objeto[p_propiedad] = v_propiedad;
                            return v_objeto;
                        };
                    } else {
                        return v_evalua;
                    }
                }, v_accionResuelta.evalua);
            v_accionResuelta.evalua = v_evaluaObjeto;
            return v_accionResuelta;
        } else {
            return v_accionResuelta;
        }
    }

    private resuelveArgumentosNavega(p_accion: ValorAccion[], p_simbolos: TablaSimbolos) {
        const
            v_accion = p_accion.map(p_item => this.resuelveArgumentoNavega(p_item, p_simbolos)),
            v_asincrona = v_accion.some(p_item => p_item.asincrona);
        let v_evalua: FuncionAccionResuelta;
        if (v_asincrona) {
            v_evalua = p_activacion => Promise
                .all(([...v_accion.map(p_funcItem => Compilador.evaluaAccion(p_funcItem, p_activacion))]))
                .then(p_items => [...p_items]);
        } else {
            v_evalua = p_activacion => v_accion.map(p_funcItem => Compilador.evaluaAccion(p_funcItem, p_activacion));
        }
        const v_resultado = {
            asincrona: v_asincrona,
            tipo: v_accion.map(({ tipo: p_tipoItem }) => p_tipoItem),
            evalua: v_evalua,
            accion: v_accion
        };
        return v_resultado;
    }

    resuelve(p_accion: ValorAccion, p_simbolos?: TablaSimbolos): AccionResuelta {
        if (typeof p_simbolos === 'undefined') {
            p_simbolos = new TablaSimbolos(this.globales);
        }

        if (typeof p_accion === 'undefined') {
            return new AccionResuelta(false, tipoIndeterminado, () => undefined, p_accion);
        } else if (p_accion === null) {
            return new AccionResuelta(false, tipoNulo, () => null, p_accion);
        } else if (esAccion(p_accion)) {
            const v_operacion = this.operaciones[p_accion.accion_id];
            valida(typeof v_operacion !== 'undefined', `'${p_accion.accion_id}' no es una acción válida o no se ha encontrado forma de resolverla.`);
            return v_operacion(this, p_accion, p_simbolos);
        } else if (Array.isArray(p_accion)) {
            const
                v_accion = p_accion.map(p_item => this.resuelve(p_item, p_simbolos)),
                v_asincrona = v_accion.some(p_item => p_item.asincrona);
            let v_evalua: FuncionAccionResuelta;
            if (v_asincrona) {
                v_evalua = p_activacion => Promise
                    .all(([...v_accion.map(p_func_item => p_func_item.evalua(p_activacion))]))
                    .then(p_items => [...p_items]);
            } else {
                v_evalua = p_activacion => v_accion.map(p_item => p_item.evalua(p_activacion));
            }
            const
                a = (v_accion.length > 0 && v_accion[0].tipo),
                b = tipoIndeterminado,
                y = a || b,
                x = new TipoArray(y);
            return new AccionResuelta(v_asincrona, x, v_evalua, p_accion);
        } else if (p_accion instanceof Date) {
            return new AccionResuelta(false, tipoFecha, () => p_accion, p_accion);
        } else if (typeof p_accion === 'object') {
            const v_accion = Compilador.generaAccionObjeto(p_accion);
            return this.resuelve(v_accion, p_simbolos);
        } else {
            const v_tipo = typeof p_accion;
            valida(v_tipo === 'string' || v_tipo === 'number' || v_tipo === 'boolean', `'${v_tipo}' no es un tipo válido para un valor de acción.`);

            const v_tipoAccion: string = {
                'boolean': 'logico',
                'number': 'numero',
                'string': 'texto'
            }[v_tipo];

            return new AccionResuelta(false, this.globales.simbolo(v_tipoAccion).tipo, () => p_accion, p_accion);
        }
    }

    ejecuta(p_accion: AccionResuelta, p_contextoLlamada: any): ResultadoAccion | Promise<ResultadoAccion> {
        const
            v_ranuras = this.globales.ranuras().map(p_ranura => p_ranura.valor),
            v_resultado = p_accion.evalua(new Activacion(v_ranuras, this.resuelvePromesa, this.rechazaPromesa, p_contextoLlamada));
        return v_resultado;
    }
}
