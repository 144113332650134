import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HandleError } from './service-helper';
import { AuthService } from './Auth/auth.service';
import { PerfilService } from './Perfilservice';
import { Task } from '../models/Task.model';
import { Observable, PartialObserver, BehaviorSubject } from 'rxjs';
import { RespuestaApi } from '../abstracto/acciones/evotec_acciones';
import { ConfirmacionService } from 'src/app/core/services/ConfirmacionService';

@Injectable()
export class TaskService {

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private readonly profileService: PerfilService,
    private confirmacionService: ConfirmacionService,
  ) {

  }

  v_perfil: any = this.profileService.activo;
  private _mostrarChart = new BehaviorSubject<Task>(null);
  private capacidadTarea: any;


  getRecursosMaquinas(): Promise<any> {
    const taskUrl = "http://servweb.cti.com/pruebas/SpyroAPI_pruebas/Plan_Gantt?empgrupo_cod=" + this.v_perfil.empgrupo_cod + "&emp_cod=" + this.v_perfil.emp_cod
    let headers = new HttpHeaders({
      'Accept': 'application/json',
      'sid': this.auth.token,
      'Cache-Control': 'no-cache'
    })
    return this.http.get(taskUrl, { headers: headers })
      .toPromise()
      .catch(HandleError);
  }

  setRecursoMaquina(task) {
    this.capacidadTarea = task;
    this._mostrarChart.next(task);
  }

  getGanttDataFail(mensaje) {
    this.confirmacionService.muestra(mensaje);
  }

  getDatosGraficaCapacidad(task): Observable<RespuestaApi> {
    const seccion = task.gpsecc_cod === null ? "" : task.gpsecc_cod;
    const grupo = task.gpmaqgrupo_cod === null ? "" : task.gpmaqgrupo_cod;
    const maquina = task.gpmaq_cod === null ? "" : task.gpmaq_cod;
    const taskUrl = "http://servweb.cti.com/pruebas/SpyroAPI_pruebas/Plan_Graf_Capacidad?" +
      "empgrupo_cod=" + this.v_perfil.empgrupo_cod + "&emp_cod=" + this.v_perfil.emp_cod + "&gpsecc_cod=" +
      seccion + "&gpmaqgrupo_cod=" + grupo + "&gpmaq_cod=" + maquina + "&n_semanas=8";
    let headers = new HttpHeaders({
      'Accept': 'application/json',
      'sid': this.auth.token,
      'Cache-Control': 'no-cache'
    })
    return this.http.get<RespuestaApi>(taskUrl, { headers: headers });
  }

  getChartDataFail(mensaje) {
    this.confirmacionService.muestra(mensaje);
  }

  getCalendarios(): Promise<any> {
    const taskUrl = "http://servweb.cti.com/pruebas/SpyroAPI_pruebas/Plan_Calendarios?empgrupo_cod=" + this.v_perfil.empgrupo_cod + "&emp_cod=" + this.v_perfil.emp_cod
    let headers = new HttpHeaders({
      'Accept': 'application/json',
      'sid': this.auth.token,
      'Cache-Control': 'no-cache'
    })
    return this.http.get(taskUrl, { headers: headers })
      .toPromise()
      .catch(HandleError);
  }

  subscribeMostrarChart(p_observador: PartialObserver<Task>) {
    return this._mostrarChart.subscribe(p_observador);
  }
  // insert(task: Task): Promise<Task> {
  //   return this.http.post(this.taskUrl, task)
  //     .toPromise()
  //     .catch(HandleError);
  // }


  // update(task: Task): Promise<void> {
  //   return this.http
  //     .put('${this.taskUrl}/${task.id}', task)
  //     .toPromise()
  //     .catch(HandleError);
  // }

  // remove(id: number): Promise<void> {
  //   return this.http.delete('${this.taskUrl}/${id}')
  //     .toPromise()
  //     .catch(HandleError);
  // }
}