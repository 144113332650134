import { Enlace, Modelo, InstanciaModelo, PropiedadArray, Propiedad, PropiedadObjeto, esNombreTipo, InstanciaArray, InstanciaObjeto } from './modelo';
import { Variable } from '../acciones/variables';
import { AccionResuelta } from '../acciones/evotec_acciones';
import { Diccionario, comoJson, ErrorValidacion, ErrorDefinicion } from '../evotec_comun';
import {
    DefinicionComponente, DescriptorEnlace, propiedadEstaEnlazada, SpyCard, __Bloque, SpyTab, SpyToolbar, SpyLabel,
    SpyCheck, SpyList, SpyInput, SpyGridColumn, SpyGrid, esDescriptorEnlace, SpyButton, SpyAgenda
} from '../especificacion/componentes';

type ActualizadorPropiedadComponente = (p_valor: any) => void;
type ActualizadoresPropiedadComponente = Diccionario<ActualizadorPropiedadComponente>;

export interface Visitante<TResultado> {
    visitaTarjeta(p_componente: ComponenteTarjeta, ...p_parametros: any[]): TResultado;
    visitaBloque(p_componente: ComponenteBloque, ...p_parametros: any[]): TResultado;
    visitaPestana(p_componente: ComponentePestana, ...p_parametros: any[]): TResultado;
    visitaBotonera(p_componente: ComponenteBotonera, ...p_parametros: any[]): TResultado;
    visitaDesplegable(p_componente: ComponenteDesplegable, ...p_parametros: any[]): TResultado;
    visitaTabla(p_componente: ComponenteDesplegable, ...p_parametros: any[]): TResultado;
    visitaColumnaTabla(p_componente: ComponenteColumnaTabla, ...p_parametros: any[]): TResultado;
    visitaEtiqueta(p_componente: ComponenteEtiqueta, ...p_parametros: any[]): TResultado;
    visitaBoton(p_componente: ComponenteBoton, ...p_parametros: any[]): TResultado;
    visitaCheck(p_componente: ComponenteCheck, ...p_parametros: any[]): TResultado;
    visitaCampo(p_componente: ComponenteCampo, ...p_parametros: any[]): TResultado;
    visitaAgenda(p_componente: ComponenteAgenda, ...p_parametros: any[]): TResultado;
}

type KeysOfUnion<T> = T extends any ? keyof T : never;

export type ComponenteLogico = ComponenteBloque | ComponenteTarjeta | ComponentePestana | ComponenteBoton | ComponenteCampo | ComponenteCheck | ComponenteAgenda | ComponenteDesplegable | ComponenteTabla | ComponenteColumnaTabla;

export class ComponenteDependiente {
    private readonly definicionModelo: Modelo;
    private readonly propiedadComponente: Extract<KeysOfUnion<ComponenteLogico>, KeysOfUnion<DefinicionComponente>>;
    public readonly $fila: number | undefined;
    private readonly enlace: DescriptorEnlace;
    private readonly componente: BaseComponenteLogico;

    constructor(p_definicionModelo: Modelo, p_fila: number | undefined, p_enlace: DescriptorEnlace, p_componente: BaseComponenteLogico, p_nombrePropiedadComponente: Extract<KeysOfUnion<ComponenteLogico>, KeysOfUnion<DefinicionComponente>>) {
        this.definicionModelo = p_definicionModelo;
        this.$fila = p_fila;
        this.enlace = p_enlace;
        this.componente = p_componente;
        this.propiedadComponente = p_nombrePropiedadComponente;
    }

    notifica(p_modelo: InstanciaModelo): void {
        const v_valor = this.definicionModelo.damePropiedad(this.enlace.modelo, p_modelo).valor;
        try {
            console.debug(`Actualizando '${this.propiedadComponente}' de componente '${this.componente.tipo}' con valor de <${this.enlace.modelo}> (${JSON.stringify(v_valor)})`);
        } catch (e) {
            if (!this.componente.definicion) {
                console.warn('??? Componente sin definición');
            }
            console.debug(`Actualizando '${this.propiedadComponente}' de componente '${this.componente.tipo}' con valor de <${this.enlace.modelo}>:`);
            console.debug(v_valor);
        }
        const v_definicionPropiedad = this.definicionModelo.damePropiedad(this.enlace.modelo);
        if (v_definicionPropiedad instanceof PropiedadArray) {
            if (!(v_valor instanceof InstanciaArray)) {
                throw new ErrorValidacion('Una propiedad de tipo array debe tener un valor de tipo array');
            }
            this.componente.actualiza(this.propiedadComponente, v_valor.elementos.map(p_valor => p_valor.valores));
        } else if (v_definicionPropiedad instanceof PropiedadObjeto) {
            if (!(v_valor instanceof InstanciaObjeto)) {
                throw new ErrorValidacion('Una propiedad de tipo array debe tener un valor de tipo array');
            }
            this.componente.actualiza(this.propiedadComponente, v_valor.valores);
        } else {
            this.componente.actualiza(this.propiedadComponente, v_valor);
        }
    }

    describe(): string {
        return this.componente.tipo;
    }
}

// Componente
export abstract class BaseComponenteLogico {
    tipo: string;
    definicionModelo: Modelo;
    definicion: DefinicionComponente;
    fila?: number;
    enlaces: Enlace[];
    actualizadores: ActualizadoresPropiedadComponente;
    estilos: string[];

    constructor(p_tipo: string, p_definicionModelo: Modelo, p_definicionComponente: DefinicionComponente, p_fila: number | undefined) {
        this.tipo = p_tipo;
        this.definicionModelo = p_definicionModelo;
        this.definicion = p_definicionComponente;
        this.actualizadores = {};
        this.enlaces = [];
        this.fila = p_fila;
        this.estilos = p_definicionComponente.estilos || [];
    }

    actualiza(p_propiedad: string, p_valor: any) {
        const v_actualizador = this.actualizadores[p_propiedad];
        if (typeof v_actualizador !== 'undefined') {
            v_actualizador(p_valor);
        }
    }

    // libera() {
    //     this.dependientes.forEach(p_dependienteComponente => {
    //         p_dependienteComponente.definicionPropiedadModelo.dependientes =
    //             p_dependienteComponente.definicionPropiedadModelo.dependientes
    //                 .filter(p_dependienteModelo => p_dependienteModelo !== p_dependienteComponente.enlace);
    //     });
    //     this.dependientes = [];
    // }

    abstract acepta<TResultado>(p_visitante: Visitante<TResultado>, ...p_parametros: any[]): TResultado;

    // Extrae las dependencias que hay en una expresión de propiedad.
    private determinaDependencias(p_modelo: Modelo, p_expresionPropiedad: string): Propiedad[] {
        const
            v_terminos = p_expresionPropiedad.split('.'),
            v_primerTermino = v_terminos[0],
            v_modelo = p_modelo.propiedades[v_primerTermino];
        if (typeof v_modelo === 'undefined') {
            throw new Error(`'${v_primerTermino}' no es una propiedad del modelo.`);
        }
        const
            v_restoTerminos = v_terminos.slice(1),
            { dependencias: v_dependencias } = v_restoTerminos
                .reduce(({ modelo: p_modelo, dependencias: p_dependencias }, p_nombrePropiedad) => {
                    if (p_modelo instanceof PropiedadObjeto) {
                        const v_propiedad = p_modelo.propiedades[p_nombrePropiedad];
                        if (typeof v_modelo === 'undefined') {
                            throw new Error(`'${p_nombrePropiedad} no es una propiedad del modelo.`);
                        }
                        const v_dependencias = p_dependencias.concat(v_propiedad);
                        return { modelo: v_propiedad, dependencias: v_dependencias };
                    } else {
                        throw new ErrorDefinicion(`No tiene la propiedad '${p_nombrePropiedad}' ya que no es un objeto`);
                    }
                }, {
                    modelo: v_modelo,
                    dependencias: [p_modelo.propiedades[v_primerTermino]] as Propiedad[]
                });
        return v_dependencias;
    }

    // Enlaza una propiedad del componente con una propiedad en el modelo
    enlazaPropiedad(p_definicionModelo: Modelo, p_nombrePropiedadComponente: Extract<KeysOfUnion<ComponenteLogico>, KeysOfUnion<DefinicionComponente>>, p_fila?: number) {
        const
            v_definicionEnlace = this.definicion[p_nombrePropiedadComponente as keyof DefinicionComponente];
        if (propiedadEstaEnlazada(v_definicionEnlace)) {
            // if (v_propiedad && v_propiedad.modelo) {
            const v_dependencias = this.determinaDependencias(p_definicionModelo, v_definicionEnlace.modelo);
            // if (v_dependencias.length > 1) {
            //    console.log("1");
            //    v_dependencias = [v_dependencias[0]];
            // }
            const v_propiedadModelo = v_dependencias[0]; //  p_definicionModelo.propiedades[v_propiedad.modelo];
            // let v_propiedadModelo = v_dependencias[v_dependencias.length - 1];
            //  p_definicionModelo.propiedades[v_propiedad.modelo];
            if (v_propiedadModelo) {
                const v_dependiente = new ComponenteDependiente(p_definicionModelo, p_fila, v_definicionEnlace, this, p_nombrePropiedadComponente);
                v_propiedadModelo.dependientes.push(v_dependiente);
                const v_enlace = new Enlace(p_nombrePropiedadComponente, v_definicionEnlace.modelo, v_propiedadModelo, v_definicionEnlace.modo || 'bidireccional', typeof v_definicionEnlace.modificable === 'undefined' ? true : v_definicionEnlace.modificable, typeof v_definicionEnlace.actualizaModelo === 'object' ? v_definicionEnlace.actualizaModelo : { operacion: v_definicionEnlace.actualizaModelo }, typeof v_definicionEnlace.actualizaComponente === 'object' ? v_definicionEnlace.actualizaComponente : { operacion: v_definicionEnlace.actualizaComponente });
                this.enlaces.push(v_enlace);
                return { definicionPropiedadModelo: v_propiedadModelo, enlace: v_enlace, dependiente: v_dependiente };
            }
        } else {
            const
                v_nombrePropiedadDefinicion = p_nombrePropiedadComponente as keyof DefinicionComponente,
                v_nombrePropiedadComponente = p_nombrePropiedadComponente as keyof this;
            this[v_nombrePropiedadComponente] = this.definicion[v_nombrePropiedadDefinicion];
        }
    }
}

export abstract class ComponenteContenedor extends BaseComponenteLogico {
    componentes: BaseComponenteLogico[] = [];

    constructor(p_tipo: string, p_definicionModelo: Modelo, p_definicionComponente: DefinicionComponente, p_fila?: number) {
        super(p_tipo, p_definicionModelo, p_definicionComponente, p_fila);
    }
}

// Componente Tarjeta
export class ComponenteTarjeta extends ComponenteContenedor {
    etiqueta?: string;
    icono?: string;

    constructor(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyCard, p_fila?: number) {
        super('tarjeta', p_definicionModelo, p_definicionComponente, p_fila);

        if (typeof p_definicionComponente.components !== 'undefined') {
            this.componentes =
                p_definicionComponente.components.map(p_componente => instanciaComponente(p_modeloInvocante, p_definicionModelo, p_componente, p_fila));
        }
        this.enlazaPropiedad(p_definicionModelo, 'etiqueta', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'icono', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'visible', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'ancho', p_fila);
    }

    static crea(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyCard, p_fila: number | undefined) {
        return new ComponenteTarjeta(p_modeloInvocante, p_definicionModelo, p_definicionComponente, p_fila);
    }

    acepta<TResultado>(p_visitante: Visitante<TResultado>, ...p_parametros: any[]): TResultado {
        return p_visitante.visitaTarjeta(this, ...p_parametros);
    }
}

// Componente Bloque
export class ComponenteBloque extends ComponenteContenedor {
    elemento: string;
    estilos: string[];

    constructor(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: __Bloque, p_fila: number | undefined, p_elemento: string, p_estilos: string[] | undefined) {
        super('bloque', p_definicionModelo, p_definicionComponente, p_fila);

        if (typeof p_definicionComponente.components !== 'undefined') {
            this.componentes =
                p_definicionComponente.components.map(p_componente => instanciaComponente(p_modeloInvocante, p_definicionModelo, p_componente, p_fila));
        }
        this.elemento = p_elemento;
        this.estilos = p_estilos;
        this.enlazaPropiedad(p_definicionModelo, 'visible', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'ancho', p_fila);
    }

    static creaInstanciador(p_elemento: string, p_estilos: string[]) {
        return (
            p_modeloInvocante: Variable | undefined,
            p_definicionModelo: Modelo,
            p_definicionComponente: __Bloque,
            p_fila: number | undefined,
            p_estilos2: string[] | undefined
        ) => {
            let v_estilos = p_estilos;
            if (Array.isArray(p_estilos2)) {
                v_estilos = v_estilos.concat(p_estilos2);
            }
            return new ComponenteBloque(p_modeloInvocante, p_definicionModelo, p_definicionComponente, p_fila, p_elemento, v_estilos);
        };
    }

    acepta<TResultado>(p_visitante: Visitante<TResultado>, ...p_parametros: any[]): TResultado {
        return p_visitante.visitaBloque(this, ...p_parametros);
    }
}

// Componente Pestaña.
export class ComponentePestana extends ComponenteContenedor {
    etiqueta?: string;

    constructor(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyTab, p_fila?: number) {
        super('pestaña', p_definicionModelo, p_definicionComponente, p_fila);

        if (typeof p_definicionComponente.components !== 'undefined') {
            this.componentes = p_definicionComponente.components
                .map(p_componente => instanciaComponente(p_modeloInvocante, p_definicionModelo, p_componente, p_fila));
        }
        this.enlazaPropiedad(p_definicionModelo, 'etiqueta', p_fila);
    }

    static crea(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyTab, p_fila: number | undefined) {
        return new ComponentePestana(p_modeloInvocante, p_definicionModelo, p_definicionComponente, p_fila);
    }

    acepta<TResultado>(p_visitante: Visitante<TResultado>, ...p_parametros: any[]): TResultado {
        return p_visitante.visitaPestana(this, ...p_parametros);
    }
}

// Componente Botonera
export class ComponenteBotonera extends ComponenteContenedor {
    constructor(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyToolbar, p_fila?: number) {
        super('botonera', p_definicionModelo, p_definicionComponente, p_fila);

        if (typeof p_definicionComponente.components !== 'undefined') {
            this.componentes = p_definicionComponente.components
                .map(p_componente => instanciaComponente(p_modeloInvocante, p_definicionModelo, p_componente, p_fila));
        }
    }

    static crea(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyToolbar, p_fila: number | undefined) {
        return new ComponenteBotonera(p_modeloInvocante, p_definicionModelo, p_definicionComponente, p_fila);
    }

    acepta<TResultado>(p_visitante: Visitante<TResultado>, ...p_parametros: any[]): TResultado {
        return p_visitante.visitaBotonera(this, ...p_parametros);
    }
}

// Componente Etiqueta
export class ComponenteEtiqueta extends BaseComponenteLogico {
    valor?: string | number | boolean | Enlace;
    visible?: boolean | Enlace;
    habilitado?: boolean | Enlace;
    negrita?: boolean | Enlace;
    cursiva?: boolean | Enlace;
    ancho?: string | Enlace;

    constructor(p_definicionModelo: Modelo, p_definicionComponente: SpyLabel, p_fila: number | undefined) {
        super('etiqueta', p_definicionModelo, p_definicionComponente, p_fila);

        this.enlazaPropiedad(p_definicionModelo, 'valor', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'visible', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'negrita', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'cursiva', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'ancho', p_fila);
    }

    static crea(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyLabel, p_fila: number | undefined) {
        return new ComponenteEtiqueta(p_definicionModelo, p_definicionComponente, p_fila);
    }

    acepta<TResultado>(p_visitante: Visitante<TResultado>, ...p_parametros: any[]): TResultado {
        return p_visitante.visitaEtiqueta(this, ...p_parametros);
    }
}

// Componente Check
export class ComponenteCheck extends BaseComponenteLogico {
    etiqueta?: string | Enlace;
    visible?: boolean | Enlace;
    habilitado?: boolean | Enlace;
    valor?: boolean;

    constructor(p_definicionModelo: Modelo, p_definicionComponente: SpyCheck, p_fila?: number) {
        super('casilla-verificacion', p_definicionModelo, p_definicionComponente, p_fila);

        this.enlazaPropiedad(p_definicionModelo, 'etiqueta', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'valor', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'visible', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'habilitado', p_fila);
    }

    static crea(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyCheck, p_fila: number | undefined) {
        return new ComponenteCheck(p_definicionModelo, p_definicionComponente, p_fila);
    }

    acepta<TResultado>(p_visitante: Visitante<TResultado>, ...p_parametros: any[]): TResultado {
        return p_visitante.visitaCheck(this, ...p_parametros);
    }
}

// Componente Desplegable
export class ComponenteDesplegable extends BaseComponenteLogico {
    etiqueta?: string | Enlace;
    visible?: boolean | Enlace;
    habilitado?: boolean | Enlace;
    seleccionado?: Enlace;
    registros?: Enlace;
    etiquetaRegistros?: any;

    constructor(p_definicionModelo: Modelo, p_definicionComponente: SpyList, p_fila?: number) {
        super('desplegable', p_definicionModelo, p_definicionComponente, p_fila);

        this.enlazaPropiedad(p_definicionModelo, 'etiqueta', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'visible', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'habilitado', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'seleccionado', p_fila);
        const v_enlaceRegistros = this.enlazaPropiedad(p_definicionModelo, 'registros', p_fila);
        if (typeof v_enlaceRegistros !== 'undefined') {
            const v_propiedad = p_definicionModelo.damePropiedad(v_enlaceRegistros.enlace.propiedad);
            if (!(v_propiedad instanceof PropiedadArray)) {
                throw new ErrorValidacion('No es una propiedad de tipo \'array\'');
            } else if (!esNombreTipo(v_propiedad.elementos)) {
                this.enlazaPropiedad(v_propiedad.elementos, 'etiquetaRegistros', p_fila);
            } else {
                throw new ErrorValidacion('Operación no válida');
            }
        }
    }

    static id = 0;

    static crea(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyList, p_fila?: number) {
        return new ComponenteDesplegable(p_definicionModelo, p_definicionComponente, p_fila);
    }

    static siguienteId() {
        return (ComponenteDesplegable.id++).toString();
    }

    acepta<TResultado>(p_visitante: Visitante<TResultado>, ...p_parametros: any[]): TResultado {
        return p_visitante.visitaDesplegable(this, ...p_parametros);
    }
}
export class ComponenteColumnaTabla extends BaseComponenteLogico {
    etiqueta: string | Enlace | undefined;
    ancho?: string | Enlace;
    celda: SpyLabel | SpyInput | SpyCheck;
    alNavegar: AccionResuelta | undefined;

    constructor(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyGridColumn, p_fila?: number) {
        super('columna', p_definicionModelo, p_definicionComponente, p_fila);

        // REVISAR a dia de hoy la tabla se apoya en el componente Jqxgrid, el cual tiene limitaciones en cuanto a los componentes
        // que pueden usarse en las celdas. Es por eso que p_definicionComponente.celda no se instancia y se asigna directamente a this.celda.
        // Eso debe corregirse
        this.celda = p_definicionComponente.celda;
        this.enlazaPropiedad(p_definicionModelo, 'etiqueta', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'ancho', p_fila);

        if (typeof p_definicionComponente.alNavegar !== 'undefined') {
            this.alNavegar = p_definicionModelo.resuelve(p_modeloInvocante, p_definicionComponente.alNavegar);
        }
    }

    acepta<TResultado>(p_visitante: Visitante<TResultado>, ...p_parametros: any[]): TResultado {
        return p_visitante.visitaColumnaTabla(this, ...p_parametros);
    }
}

// Componente Tabla
export class ComponenteTabla extends BaseComponenteLogico {
    columnas: ComponenteColumnaTabla[];
    numeroPagina: number | Enlace | undefined;
    registrosPorPagina: number | Enlace | undefined;
    registrosTotales: number | Enlace | undefined;
    ancho: string | Enlace;

    constructor(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyGrid, p_fila?: number) {
        super('tabla', p_definicionModelo, p_definicionComponente, p_fila);

        this.enlazaPropiedad(p_definicionModelo, 'visible', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'habilitado', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'numeroPagina', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'registrosPorPagina', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'registrosTotales', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'seleccionado', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'ancho', p_fila);

        const v_enlaceRegistros = this.enlazaPropiedad(p_definicionModelo, 'registros', p_fila);
        if (typeof v_enlaceRegistros !== 'undefined') {
            const v_propiedad = p_definicionModelo.damePropiedad(v_enlaceRegistros.enlace.propiedad);
            if (!(v_propiedad instanceof PropiedadArray)) {
                throw new ErrorDefinicion(`La propiedad 'registros' debe enlazarse a una propiedad de tipo 'array'.`);
            }
            if (v_propiedad.elementos instanceof Modelo) {
                const v_elementos = v_propiedad.elementos;
                this.columnas = p_definicionComponente.columnas.map(p_definicionColumna => new ComponenteColumnaTabla(p_modeloInvocante, v_elementos, p_definicionColumna, p_fila));
                console.log("TABLAAAAA--->>>");
                console.log(this.columnas);
            }
        }
    }

    static crea(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyGrid, p_fila: number | undefined) {
        return new ComponenteTabla(p_modeloInvocante, p_definicionModelo, p_definicionComponente, p_fila);
    }

    static id = 0;

    static siguienteId() {
        return (ComponenteTabla.id++).toString();
    }

    acepta<TResultado>(p_visitante: Visitante<TResultado>, ...p_parametros: any[]): TResultado {
        return p_visitante.visitaTabla(this, ...p_parametros);
    }
}
// Componente Campo
export class ComponenteCampo extends BaseComponenteLogico {
    etiqueta?: string | Enlace;
    valor?: string | number | boolean | Enlace;
    visible?: boolean | Enlace;
    habilitado?: boolean | Enlace;
    ancho?: string | Enlace;
    negrita?: boolean | Enlace;
    cursiva?: boolean | Enlace;
    multilinea: boolean;
    lineas: number;
    alNavegar?: AccionResuelta;

    constructor(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyInput, p_fila?: number) {
        super('campo', p_definicionModelo, p_definicionComponente, p_fila);

        if (esDescriptorEnlace(p_definicionComponente.multilinea)) {
            throw new ErrorDefinicion(`La propiedad 'multilinea' no es enlazable.`);
        }
        if (esDescriptorEnlace(p_definicionComponente.lineas)) {
            throw new ErrorDefinicion(`La propiedad 'lineas' no es enlazable.`);
        }
        this.multilinea = typeof p_definicionComponente.multilinea === 'boolean' && p_definicionComponente.multilinea || false;
        this.lineas = typeof p_definicionComponente.lineas === 'number' && p_definicionComponente.lineas || 1;
        this.enlazaPropiedad(p_definicionModelo, 'etiqueta', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'valor', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'visible', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'habilitado', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'ancho', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'negrita', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'cursiva', p_fila);
        if (typeof p_definicionComponente.alNavegar !== 'undefined') {
            if (p_modeloInvocante !== undefined) {
                this.alNavegar = p_definicionModelo.resuelve(p_modeloInvocante, p_definicionComponente.alNavegar);
            } else {
                this.alNavegar = p_definicionModelo.resuelve(undefined, p_definicionComponente.alNavegar);
            }
        }
    }

    static crea(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyInput, p_fila: number | undefined) {
        return new ComponenteCampo(p_modeloInvocante, p_definicionModelo, p_definicionComponente, p_fila);
    }

    acepta<TResultado>(p_visitante: Visitante<TResultado>, ...p_parametros: any[]): TResultado {
        return p_visitante.visitaCampo(this, ...p_parametros);
    }
}
// Componente Boton
export class ComponenteBoton extends BaseComponenteLogico {
    etiqueta?: string | Enlace;
    icono?: string | Enlace;
    alPulsar?: AccionResuelta;
    visible?: boolean | Enlace;
    habilitado?: boolean | Enlace;
    ancho?: string | Enlace;

    constructor(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyButton, p_fila?: number) {
        super('boton', p_definicionModelo, p_definicionComponente, p_fila);

        this.enlazaPropiedad(p_definicionModelo, 'etiqueta', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'icono', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'visible', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'habilitado', p_fila);
        this.enlazaPropiedad(p_definicionModelo, 'ancho', p_fila);
        // Evento 'alPulsar'
        // RBM 15/10/19 -> ver con LCQ
        // if (typeof p_definicionComponente.alPulsar === 'undefined') {
        //     throw new Error(`Operación no válida; un botón debe tener definido el evento 'alPulsar'`);
        // }
        if (typeof p_definicionComponente.alPulsar === 'undefined') {
            this.habilitado = false;
        }
        // FIN RBM 15/10/19 -> ver con LCQ
        if (p_modeloInvocante !== undefined) {
            this.alPulsar = p_definicionModelo.resuelve(p_modeloInvocante, p_definicionComponente.alPulsar);
        } else {
            this.alPulsar = p_definicionModelo.resuelve(undefined, p_definicionComponente.alPulsar);
        }
    }
    static crea(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyButton, p_fila: number | undefined) {
        return new ComponenteBoton(p_modeloInvocante, p_definicionModelo, p_definicionComponente, p_fila);
    }
    acepta<TResultado>(p_visitante: Visitante<TResultado>, ...p_parametros: any[]): TResultado {
        return p_visitante.visitaBoton(this, ...p_parametros);
    }
}

// Componente Agenda
export class ComponenteAgenda extends BaseComponenteLogico {
    registros?: Enlace;
    constructor(p_definicionModelo: Modelo, p_definicionComponente: SpyAgenda, p_fila?: number) {
        super('agenda', p_definicionModelo, p_definicionComponente, p_fila);

        const v_enlaceRegistros = this.enlazaPropiedad(p_definicionModelo, 'registros', p_fila);
        if (typeof v_enlaceRegistros !== 'undefined') {
            const v_propiedad = p_definicionModelo.damePropiedad(v_enlaceRegistros.enlace.propiedad);
            if (!(v_propiedad instanceof PropiedadArray)) {
                throw new ErrorDefinicion(`La propiedad 'registros' debe enlazarse con una propiedad de tipo 'array'.`);
            }
        }
    }

    static crea(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: SpyAgenda, p_fila: number | undefined) {
        return new ComponenteAgenda(p_definicionModelo, p_definicionComponente, p_fila);
    }

    acepta<TResultado>(p_visitante: Visitante<TResultado>, ...p_parametros: any[]): TResultado {
        return p_visitante.visitaAgenda(this, ...p_parametros);
    }
}
export function enlace(p_enlaces: Enlace[], p_nombreEnlace: string): Enlace | undefined {
    if (typeof p_enlaces === 'undefined') {
        return;
    }
    return p_enlaces.filter(p_enlace => p_enlace.propiedadComponente === p_nombreEnlace)[0];
}

const v_instanciadoresComponentes = {
    'spyCard': ComponenteTarjeta.crea,
    'layoutVertical': ComponenteBloque.creaInstanciador('div', ['distribucion-vertical']),
    'layoutHorizontal': ComponenteBloque.creaInstanciador('div', ['distribucion-horizontal']),
    'spyTab': ComponentePestana.crea,
    'spyToolbar': ComponenteBotonera.crea,
    'spyList': ComponenteDesplegable.crea,
    'spyLabel': ComponenteEtiqueta.crea,
    'spyCheck': ComponenteCheck.crea,
    'spyInput': ComponenteCampo.crea,
    'spyButton': ComponenteBoton.crea,
    'spyGrid': ComponenteTabla.crea,
    'spyAgenda': ComponenteAgenda.crea,
};

// procesa la definición de un componente
export function instanciaComponente(p_modeloInvocante: Variable | undefined, p_definicionModelo: Modelo, p_definicionComponente: DefinicionComponente, p_fila?: number): BaseComponenteLogico {
    if (typeof p_definicionComponente === 'undefined') {
        // debugger;
        throw new ErrorDefinicion('No es un componente válido.');
    }
    const v_tipo = p_definicionComponente.component;
    if (typeof v_tipo === 'undefined') {
        throw new ErrorDefinicion(`Imposible instanciar componente ${comoJson(p_definicionComponente)}.`);
    }
    const v_procesadorComponente: any = v_instanciadoresComponentes[v_tipo];
    if (!v_procesadorComponente) {
        throw new ErrorDefinicion(`'${v_tipo}' no es un componente válido.`);
    }
    return v_procesadorComponente(p_modeloInvocante, p_definicionModelo, p_definicionComponente, p_fila, p_definicionComponente.estilos);
}
