import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';

import { LoginModule } from './login/login.module';
import { LoginService } from './login/loginService';

export const CONTAINERS = [
];

export const EXPORT_CONTAINERS = [
];

@NgModule({
  imports: [
    CommonModule,
    // SweetAlert2Module,
    LoginModule
  ],
  declarations: EXPORT_CONTAINERS,
  exports: EXPORT_CONTAINERS,
  providers: [LoginService],
  entryComponents: CONTAINERS
})
export class StaticModule { }
