import { Component, OnInit, ViewContainerRef, ViewChild, HostBinding, OnDestroy, ElementRef, Input } from '@angular/core';
import { Variable } from '../../abstracto/acciones/variables';
import { ComponenteBloque } from '../../abstracto/ejecucion/componentes';
import { compruebaFormatoAncho } from '../../abstracto/evotec_comun';
import { Modelo, InstanciaModelo, InstanciaObjeto } from '../../abstracto/ejecucion/modelo';
import { BaseComponenteAngular } from '../UtilidadesComponentes';

@Component({
    selector: 'app-bloque',
    templateUrl: './bloque.component.html'
})
export class BloqueComponent implements BaseComponenteAngular<ComponenteBloque>, OnInit, OnDestroy {
    private static _id = 0;
    id: string;

    constructor() {
        this.id = `bloque-${BloqueComponent._id++}`;
    }

    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_fila: number,
        p_componente: ComponenteBloque,
        p_modeloInvocante: Variable): void {

        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    definicionModelo: Modelo | undefined;
    instanciaModelo: InstanciaModelo | undefined;
    modeloInvocante: Variable | undefined;
    fila: number | undefined;

    componente: ComponenteBloque | undefined;

    @ViewChild('vc', { read: ViewContainerRef, static: true }) contenedor: ViewContainerRef | undefined;

    // Propiedad 'visible'
    private _visible = true;
    get visible(): boolean { return this._visible; }
    set visible(p_valor: boolean) { this._visible = p_valor; }

    // Propiedad 'ancho'
    private _ancho: string | undefined;
    get ancho(): string | undefined { return this._ancho; }
    set ancho(p_valor: string | undefined) { this._ancho = p_valor; }

    @HostBinding('style.width')
    get tamanioAncho(): string | number | undefined {
        if (typeof this._ancho === 'undefined') {
            return undefined;
        }

        let v_tamanio: string | number | undefined;
        const v_ancho = this._ancho;
        v_tamanio = compruebaFormatoAncho(v_ancho);
        return v_tamanio;
    }

    get oculto(): boolean {
        return !this._visible;
    }

    // Estilos
    @HostBinding('class')
    get estilos() {
        if (typeof this.componente === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        const v_clases = this.componente.estilos;
        if (this.oculto) { v_clases.push('oculto'); }

        return v_clases.join(' ');
    }

    ngOnInit(): void {
        console.warn(`Creando ${this.id}`);
    }

    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
    }
}
