import { Component, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { Chart as ChartDHX } from 'dhx-suite';
import { TaskService } from '../../services/TaskService';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['chart.component.css'],
})
export class ChartComponent {
  @ViewChild('widget') chartContainer: ElementRef;
  chart: ChartDHX;
  container: ElementRef;
  descripcionTarea: string;
  maxCapacidad: number;
  wait: Promise<void>;
  subscripcionMostrarChart: Subscription;

  constructor(private taskService: TaskService) {
    this.maxCapacidad = 0;
  }

  getConfig(stacked): any {
    return {
      type: "bar",
      scales: {
        "bottom": {
          text: "col_text",
        },
        "left": {
          maxTicks: 10,
          max: this.maxCapacidad,
          min: 0,

        },
      },
      series: [
        {
          id: "capacidad",
          value: "capacidad",
          color: "none",
          fill: "#81C4E8",
          stacked: stacked,
        },
        {
          id: "ocupacion",
          value: "ocupacion",
          color: "none",
          fill: "#74A2E7",
          stacked: stacked,
        },
      ],
      legend: {
        series: ["capacidad", "ocupacion"],
        form: "rect",
        valign: "top",
        halign: "right"
      }
    }
  }

  ngOnInit() {
    // GET datos gráfico capacidad
    this.subscripcionMostrarChart = this.taskService.subscribeMostrarChart({
      next: p_task => {
        if (p_task !== null) {
          this.taskService.getDatosGraficaCapacidad(p_task).subscribe(data => {
            let respuesta: any = data.Objeto;
            if (data.Ok) {
              if (this.chart) {
                this.chart.destructor();
                this.maxCapacidad = 0;
              }
              respuesta.forEach(registro => {
                if (registro.capacidad > this.maxCapacidad) {
                  this.maxCapacidad = registro.capacidad + 100;
                }
              });
              this.descripcionTarea = p_task.text;
              this.chart = new ChartDHX("chart", this.getConfig(false));
              this.chart.data.parse(respuesta);
            } else {
              // Mensaje cuando falla la llamada al gráfico de capacidad
              this.taskService.getChartDataFail(data.Mensaje)
            }
          });
        }

      }
    });
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.destructor();
    }
  }
}