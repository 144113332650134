import { Component, ViewChild, OnInit, OnDestroy, ElementRef } from '@angular/core';

import { jqxMenuComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxmenu';
import { MenuService } from '../../services/MenuService';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PerfilService } from '../../services/Perfilservice';
import { PaginaService } from '../../services/PaginaService';
import { AppConfigService } from '../../services/AppConfigService';
import { procesa } from '../../abstracto/ejecucion/procesa';
import { AccionInvocaFuncion } from '../../abstracto/especificacion/acciones';


@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit, OnDestroy {
    innerWidth: number;

    // @HostListener('window:resize')
    onResize() {
        this.innerWidth = window.innerWidth;
        this.innerWidth < 1200 ? this.jqxmenu.minimize() : this.jqxmenu.restore();
    }

    @ViewChild('menuElements', { static: false }) menuElements: ElementRef;
    @ViewChild('jqxmenu', { static: false }) jqxmenu: jqxMenuComponent;

    constructor(
        private http: HttpClient,
        public perfilService: PerfilService,
        private menuService: MenuService,
        private paginaService: PaginaService,
        private appConfigService: AppConfigService
    ) { }

    menu: any[];

    setMenuSubscription: any;

    ngOnInit() {
        this.construyeMenu();
    }

    private construyeMenu() {
        const y = {
            modelo: {
                opcion: {
                    tipo: 'tipo',
                    propiedades: {
                        opcion: {
                            tipo: 'tipo',
                            propiedades: {
                                opcion: {
                                    tipo: 'tipo',
                                    propiedades: {
                                        opcion: {
                                            tipo: 'tipo',
                                            propiedades: {
                                                opcion: {
                                                    tipo: 'tipo',
                                                    propiedades: {
                                                        COD: { tipo: 'texto' },
                                                        ETIQUETA: { tipo: 'texto' },
                                                        FORMATO: { tipo: 'texto' },
                                                        PERMITIDO_SN: { tipo: 'texto' },
                                                        ICONO: { tipo: 'texto' }
                                                    }
                                                },
                                                COD: { tipo: 'texto' },
                                                ETIQUETA: { tipo: 'texto' },
                                                FORMATO: { tipo: 'texto' },
                                                PERMITIDO_SN: { tipo: 'texto' },
                                                ICONO: { tipo: 'texto' },
                                                Opciones: { tipo: 'array', elementos: 'opcion' }
                                            }
                                        },
                                        COD: { tipo: 'texto' },
                                        ETIQUETA: { tipo: 'texto' },
                                        FORMATO: { tipo: 'texto' },
                                        PERMITIDO_SN: { tipo: 'texto' },
                                        ICONO: { tipo: 'texto' },
                                        Opciones: { tipo: 'array', elementos: 'opcion' }
                                    }
                                },
                                COD: { tipo: 'texto' },
                                ETIQUETA: { tipo: 'texto' },
                                FORMATO: { tipo: 'texto' },
                                PERMITIDO_SN: { tipo: 'texto' },
                                ICONO: { tipo: 'texto' },
                                Opciones: { tipo: 'array', elementos: 'opcion' }
                            }
                        },
                        COD: { tipo: 'texto' },
                        ETIQUETA: { tipo: 'texto' },
                        FORMATO: { tipo: 'texto' },
                        PERMITIDO_SN: { tipo: 'texto' },
                        ICONO: { tipo: 'texto' },
                        Opciones: { tipo: 'array', elementos: 'opcion' }
                    }
                },
                menu: { tipo: 'array', elementos: 'opcion' }
            },
            datos: { menu: [] }
        };


        const
            x = procesa(undefined, y),
            v_modelo = x.modelo.definicion,
            v_instancia = x.modelo.instancia,
            v_parametros: object = { programa: this.appConfigService.appConfigData.accesoMenu },
            v_accion: AccionInvocaFuncion = {
                accion_id: '_invocaFuncion',
                funcion: 'navega',
                argumentos: [v_parametros]
            };

        const v_resultado = v_modelo.evalua(v_accion, v_instancia, undefined, undefined, undefined, { pestana: () => null });
        if (v_resultado instanceof Promise) {
            v_resultado.then(p_resultado => {
                const v_opciones = v_instancia.valor();
                const self = this;
                v_opciones.menu
                    .map(p_opcion => self.menuService.creaNodo(p_opcion, true, true, self.closeMenu))
                    .forEach(this.menuService.insertaNodoEn(this.menuElements.nativeElement));
                this.jqxmenu.createComponent({});
                // si entramos desde una tablet, el menú por defecto comprimido. (Sólo se comprueba al entrar la primera vez, no al cambiar de tamaño la ventana)
                if (window.innerWidth <= 512) {
                    this.paginaService.ocultaMenu();
                } else if (window.innerWidth <= 1024) {
                    this.paginaService.minimizaMenu();
                } else {
                    this.paginaService.restauraMenu();
                }

            });
        }
    }

    ngOnDestroy() {
        this.setMenuSubscription?.unsubscribe();
        if (this.jqxmenu.widgetObject) {
            this.jqxmenu.destroy();
        }
    }

    itemclick(p_evento) {
        const usmenu_cod = p_evento.target.getAttribute('data-usmenu_cod');

        if (usmenu_cod !== null) {
            this.menuService.abreOpcion(usmenu_cod);
        }
    }

    closeMenu() {
        const nl = document.querySelectorAll('.jqx-fill-state-pressed');

        const arr = [];
        for (let i = nl.length; i--; arr.unshift(nl[i])) { }

        arr.forEach(element => {
            this.jqxmenu.closeItem(element.id);
        });
    }
}
