import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: any;

  constructor(private http: HttpClient) { }

  // Lectura de archivo de configuración de la aplicación.
  loadAppConfig() {
    return this.http.get('../../../assets/json/config.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
      });
  }

  // Obtener la url a la que apuntar para realizar las llamadas.
  get appConfigData() {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig;
  }
}