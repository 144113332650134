import { Diccionario, comoJson, ErrorDefinicion, ErrorPruebaCordura, ErrorValidacion, valida } from '../evotec_comun';
import { AccionResuelta, esAccionResuelta, Compilador, ResultadoAccion } from '../acciones/evotec_acciones';
import { TipoObjeto, Miembros, tipoTexto, tipoNumero, tipoLogico, tipoFecha, tipoHora, Tipo, TipoArray } from '../acciones/tipos';
import { ComponenteDependiente } from './componentes';
import { Accion } from '../especificacion/acciones';
import { Variable } from '../acciones/variables';
import { ModoEnlace, DescriptorCalculo } from '../especificacion/componentes';
import { esTipoPredefinido, tiposPredefinidos } from '../especificacion/tipos';
import { EventoAlCambiar, esNombreAccion, Datos } from '../especificacion/interfazUsuario';
import { transformacionesEnlaces } from './transformaciones';

export class Cambio {
    readonly definicionModelo: Modelo;
    readonly instanciaModelo: InstanciaObjeto;
    readonly propiedadModelo: string;
    readonly fila?: number;
    readonly nuevoValor: any;
    readonly valorAnterior: any;
    readonly contextoLlamada: any;

    constructor(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_propiedadModelo: string,
        p_fila: number | undefined,
        p_nuevoValor: any,
        p_valorAnterior: any,
        p_contextoLlamada: any
    ) {
        if (!(p_instanciaModelo instanceof InstanciaObjeto)) {
            throw new ErrorValidacion('No es una instancia de objeto.');
        }
        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.propiedadModelo = p_propiedadModelo;
        this.fila = p_fila;
        this.nuevoValor = p_nuevoValor;
        this.valorAnterior = p_valorAnterior;
        this.contextoLlamada = p_contextoLlamada;
    }

    damePropiedad(): Propiedad {
        return this.definicionModelo.damePropiedad(this.propiedadModelo);
    }

    private afecta(p_dependiente: ComponenteDependiente): boolean {
        return p_dependiente.$fila === this.fila;
    }

    dependientesAfectados(): ComponenteDependiente[] {
        const v_dependientesAfectados = this.damePropiedad().dependientes.filter(p_dependiente => this.afecta(p_dependiente));
        return v_dependientesAfectados;
    }
}

export class ListaCambios {
    private tabla: Cambio[];

    constructor() {
        this.descarta();
    }

    push(p_cambio: Cambio): void {
        console.debug(`registrando cambio en '${p_cambio.propiedadModelo}'; de ${comoJson(p_cambio.valorAnterior)} pasa a ${comoJson(p_cambio.nuevoValor)}.`);

        this.tabla.push(p_cambio);
        if (this.tabla.length > 1000) {
            this.tabla = this.tabla.slice(1);
            console.warn('Hay muchos cambios por lo que no se tendrán en cuenta todos.');
        }
    }

    *[Symbol.iterator]() {
        yield* this.tabla;
    }

    // Notifica de los cambios a los componentes de la vista que están enlazados a ellos.
    notifica() {
        if (this.tabla.length === 0) {
            // nada que notificar.
            return;
        }

        console.debug(`Notificando cambios a componentes dependientes; ${this.tabla.length} cambio(s).`);

        const v_cambios = this.tabla;
        this.descarta();

        v_cambios.forEach((p_cambio, p_indice) => {
            // notifico a los elementos del DOM que sean dependientes de la propiedad del modelo y de la propiedad del componente a la que afecta el cambio
            // MEJORAR: descartar cambios repetidos
            const v_dependientesAfectados = p_cambio.dependientesAfectados();

            console.debug(`Cambio ${p_indice + 1} en '${p_cambio.propiedadModelo}' afecta a ${
                comoJson(v_dependientesAfectados.map(p_dependiente => p_dependiente.describe()))}`);

            v_dependientesAfectados.forEach(p_dependiente => p_dependiente.notifica(p_cambio.instanciaModelo));
        });

        console.debug('Cambios notificados a los componentes dependientes; no hay mas cambios.');
        // cambios = [];
    }

    // Por cada cambio producido lanza el evento 'alCambiar' y notifica a la interfaz de los nuevos valores del modelo.
    aplica() {
        console.warn(`----------------------------------------aplicando cambios; ${this.tabla.length} cambio(s).`);

        // notifica a la vista de los cambios realizados al modelo
        return Promise.all(this.tabla
            .filter(p_cambio => typeof p_cambio.damePropiedad().alCambiar !== 'undefined')
            .map((p_cambio, p_indice) => {
                console.debug(`cambio ${p_indice + 1} en '${p_cambio.propiedadModelo}' desencadena acción.`);

                const
                    v_propiedad = p_cambio.damePropiedad(),
                    v_alCambiar = v_propiedad.alCambiar as EventoAlCambiar;

                if (!(v_alCambiar instanceof AccionResuelta)) {
                    throw new ErrorValidacion(`Se esperaba una accion resuelta.`);
                }

                console.debug(`Ejecutando accion desencadenada por cambio en '${p_cambio.propiedadModelo}' de forma ${v_alCambiar.asincrona ? 'asincrona' : 'sincrona'}`);

                return p_cambio.definicionModelo.evalua(v_alCambiar, p_cambio.instanciaModelo, undefined, undefined, undefined, p_cambio.contextoLlamada);
            })
        ).finally(() => {
            // actualizar el modelo con los resultados obtenidos
            this.notifica();
            console.debug('Se han aplicado los cambios.');
        });
    }

    private descarta() {
        this.tabla = [];
    }
}

// Lista de cambios realizados en el modelo
export const cambios = new ListaCambios();

export class Enlace {
    propiedadComponente: string;
    propiedad: string;
    definicionPropiedad: Propiedad;
    modo: ModoEnlace;
    modificable: boolean;
    actualizaModelo: DescriptorCalculo;
    actualizaComponente: DescriptorCalculo;

    constructor(
        p_propiedadComponente: string,
        p_propiedad: string,
        p_definicionPropiedad: Propiedad,
        p_modo: ModoEnlace,
        p_modificable: boolean,
        p_actualizaModelo: DescriptorCalculo,
        p_actualizaComponente: DescriptorCalculo
    ) {
        this.propiedadComponente = p_propiedadComponente;
        this.propiedad = p_propiedad;
        this.definicionPropiedad = p_definicionPropiedad;
        this.modo = p_modo;
        this.modificable = p_modificable;
        this.actualizaModelo = p_actualizaModelo;
        this.actualizaComponente = p_actualizaComponente;
    }

    funcionTransforma(p_descriptor: DescriptorCalculo): (p_valor: any) => any {
        if (typeof p_descriptor === 'undefined') {
            return p_valor => p_valor;
        }

        const v_transforma = transformacionesEnlaces[p_descriptor.operacion];
        if (typeof v_transforma === 'undefined') {
            return p_valor => p_valor;
        }

        return p_valor => v_transforma(p_valor, p_descriptor);
    }

    transformaValor(p_valor, p_transforma: (p_valor: any) => any) {
        // if (this.definicionPropiedad.tipo === 'fecha') {
        //     debugger;
        // }

        // REVISA no debería ser el enlace quien determinase que ocurre en este caso??
        if (typeof p_valor === 'undefined') {
            p_valor = null;
        }


        if (typeof p_transforma === 'undefined') {
            return p_valor;
        }

        // let v_transforma = v_transformacionesEnlaces[p_descriptor.operacion];
        // if (typeof v_transforma === 'undefined') {
        //     v_transforma = p_valor => p_valor;
        // }

        const v_valor = p_transforma(p_valor);
        // console.debug(`Aplicando transformación ${comoJson(p_descriptor)}: <${p_valor}> pasa a ser <${v_valor}>.`);

        return v_valor;
    }
}

export type Propiedad = PropiedadSimple | PropiedadArray | PropiedadObjeto;

export type Tipos = Diccionario<Modelo>;
export type Acciones = Diccionario<Accion | AccionResuelta>;
export type Propiedades = Diccionario<Propiedad>;

export class PropiedadSimple {
    readonly tipo: string;
    alCambiar: EventoAlCambiar | undefined;
    readonly dependientes: ComponenteDependiente[];

    constructor(p_tipo: string, p_onChange: EventoAlCambiar | undefined) {
        this.tipo = p_tipo;
        this.dependientes = [];
        if (typeof p_onChange !== 'undefined') {
            this.alCambiar = p_onChange;
        }
    }

    ejecutaAccionAlCambiar(): boolean {
        return typeof this.alCambiar !== 'undefined';
    }

    valorInstancia(p_valor: any): any {
        const
            v_tipo = tiposPredefinidos[this.tipo],
            v_valorInterno = v_tipo.normaliza(p_valor);
        return v_valorInterno;
    }
}

export class PropiedadArray {
    readonly tipo: string;
    alCambiar: EventoAlCambiar | undefined;
    readonly dependientes: ComponenteDependiente[];
    elementos: string | Modelo;

    constructor(p_elementos: string | Modelo, p_onChange: EventoAlCambiar | undefined) {
        this.tipo = 'array';
        this.dependientes = [];
        if (typeof p_onChange !== 'undefined') {
            this.alCambiar = p_onChange;
        }
        this.elementos = p_elementos;
    }

    ejecutaAccionAlCambiar(): boolean {
        return typeof this.alCambiar !== 'undefined';
    }

    enlaza(p_tipos: Diccionario<Modelo>) {
        if (esNombreTipo(this.elementos)) {
            const v_elementos = p_tipos[this.elementos];
            if (typeof v_elementos === 'undefined') {
                if (esTipoPredefinido(this.elementos)) {
                    throw new ErrorDefinicion(`No se permiten arrays de tipos predefinidos: '${this.elementos}'.`);
                }
                throw new ErrorDefinicion(`El tipo '${this.elementos}' no existe.`);
            }
            this.elementos = v_elementos;
            return this;
        } else {
            throw new ErrorValidacion('Operación no válida.');
        }
    }

    valorInstancia(p_valor: any): any {
        return p_valor;
    }
}

export function esNombreTipo(p_tipo: string | Modelo): p_tipo is string {
    return typeof p_tipo === 'string';
}

export class PropiedadObjeto {
    readonly tipo: string;
    alCambiar: EventoAlCambiar | undefined;
    readonly dependientes: ComponenteDependiente[];
    tipos: Tipos | undefined;
    propiedades: Propiedades;
    acciones: Acciones | undefined;
    padres: Modelo[] | undefined;

    constructor(
        p_tipo: string,
        p_onChange: EventoAlCambiar | undefined) {

        this.tipo = p_tipo;
        this.dependientes = [];
        if (typeof p_onChange !== 'undefined') {
            this.alCambiar = p_onChange;
        }
    }

    ejecutaAccionAlCambiar(): boolean {
        return typeof this.alCambiar !== 'undefined';
    }

    enlaza(p_tipos: Diccionario<Modelo>) {
        const v_tipo = p_tipos[this.tipo];
        this.tipos = v_tipo.tipos;
        this.propiedades = v_tipo.propiedades;
        this.acciones = v_tipo.acciones;
        this.padres = v_tipo.padres;
    }

    valorInstancia(p_valor: any): any {
        return p_valor;
    }
}

// type Accesor = (p_nuevoValor?: any) => any;

export class Modelo {
    acciones: Acciones;
    propiedades: Propiedades;
    tipos: Tipos;
    padres: Modelo[];

    constructor(p_acciones: Acciones, p_propiedades: Propiedades, p_tipos: Tipos, p_padres: Modelo[]) {
        this.acciones = p_acciones;
        this.propiedades = p_propiedades;
        this.tipos = p_tipos;
        this.padres = p_padres;
    }

    instanciaModelo(p_datos: Datos, p_padres?: InstanciaObjeto[]): InstanciaObjeto {
        const v_instancia = this.instanciaModeloSinPadres(p_datos);
        if (!(v_instancia instanceof InstanciaObjeto)) {
            throw new ErrorValidacion('La instanciación del modelo no es un objeto.');
        }

        const v_padres = typeof p_padres === 'undefined' ? [] : p_padres;
        this.referenciaPadresInstancia(v_instancia, v_padres);
        v_instancia.padres = v_padres;

        this.ejecutaPruebaDeCordura(v_instancia);

        return v_instancia;
    }

    // instancia el modelo a partir de su definicion y carga datos iniciales
    private instanciaModeloSinPadres(p_datos: Datos): InstanciaObjeto {
        // A efectos de instanciación, un {} es equivalente a un null
        if (typeof p_datos !== 'undefined' && p_datos !== null && (Object.keys(p_datos).length === 0)) { // p_datos es un objeto vacío?
            p_datos = null;
        }

        // REVISAR en modo estricto no debería ser necesaria hacer esta comprobación
        if (typeof p_datos === 'undefined') {
            return new InstanciaObjeto(undefined);
        } else if (p_datos === null) {
            return new InstanciaObjeto(null);
        }

        // const v_datosInexistentes = Object.getOwnPropertyNames(p_datos)
        //     .filter(p_nombrePropiedadDatos => typeof this.propiedades[p_nombrePropiedadDatos] === 'undefined');

        // if (v_datosInexistentes.length > 0) {
        //     throw new ErrorValidacion(`${comoJson(v_datosInexistentes)} no son datos existentes en el modelo`);
        // }

        const
            v_propiedadesModelo = Object.getOwnPropertyNames(this.propiedades),
            v_valores = v_propiedadesModelo.reduce((p_valores, p_propiedadModelo) => {
                const v_propiedad = this.propiedades[p_propiedadModelo];
                if (v_propiedad instanceof PropiedadObjeto) {
                    const
                        v_modelo = this.tipos[v_propiedad.tipo],
                        v_dato = p_datos[p_propiedadModelo];
                    p_valores[p_propiedadModelo] = v_modelo.instanciaModeloSinPadres(v_dato);
                } else {
                    if (v_propiedad instanceof PropiedadArray) {
                        if (!(v_propiedad.elementos instanceof Modelo)) {
                            throw new ErrorValidacion(`Error interno; la propiedad '${p_propiedadModelo}' es de tipo 'array' pero sus elementos no son instancias de 'Modelo'`);
                        }
                        const
                            v_elementos = v_propiedad.elementos,
                            v_array = p_datos[p_propiedadModelo],
                            v_valor = new InstanciaArray(v_array && v_array.map((p_registro: Datos) => v_elementos.instanciaModeloSinPadres(p_registro)) || []);
                        p_valores[p_propiedadModelo] = v_valor;
                    } else {
                        const
                            v_nombreTipo = v_propiedad.tipo,
                            v_tipo = tiposPredefinidos[v_nombreTipo];
                        if (p_datos) {
                            const v_dato = p_datos[p_propiedadModelo];
                            if (typeof v_tipo !== 'undefined') {
                                // if (!v_tipo.esAsignable(v_dato)) {
                                //     throw new ErrorDefinicion(`El valor ${comoJson(v_dato)} no es asignable a una propiedad de tipo '${v_nombreTipo}'`);
                                // }
                                // p_valores[p_propiedadModelo] = v_tipo.normaliza(v_dato);
                                p_valores[p_propiedadModelo] = v_tipo.instancia(v_dato);
                            } else {
                                // REVISAR si no es un tipo predeterminado, habría que ver si son el mismo tipo.
                                // if (!v_dato) {
                                //    console.debug(`${p_propiedadModelo} = ${v_dato}`);
                                // }
                                // p_instancia[p_propiedadModelo] = this.tipos[v_nombreTipo].instanciaModelo(v_dato, p_padres).valores;
                                p_valores[p_propiedadModelo] = this.tipos[v_nombreTipo].instanciaModeloSinPadres(v_dato).valores;
                            }
                        } else {
                            throw new ErrorValidacion('aquí no debería llegar nunca ya que p_datos se ha comprobado en la entrada a instanciaModeloSinPadres');
                            let v_valorVacio;
                            if (typeof v_tipo === 'undefined' || typeof v_tipo.valorVacio === 'undefined') {
                                v_valorVacio = undefined;
                            } else {
                                v_valorVacio = v_tipo.valorVacio;
                            }
                            if (!esValorPropiedadInstanciaModeloObjeto(v_valorVacio)) {
                                throw new ErrorDefinicion(`El valor ${comoJson(v_valorVacio)} no es asignable a la propiedad de un objeto.`);
                            }
                            p_valores[p_propiedadModelo] = v_valorVacio;
                        }
                    }
                }
                return p_valores;
            }, {} as Diccionario),
            v_instancia = new InstanciaObjeto(v_valores);

        // this.referenciaPadresInstancia(v_instancia, [v_instancia].concat(p_padres));
        // if (this.padres.length !== v_instancia.padres.length) {
        //     debugger;
        // }
        return v_instancia;
    }

    // creo en los hijos de la instancia una referencia a esta instancia.
    private referenciaPadresInstancia(p_instancia: InstanciaObjeto, p_padres: InstanciaObjeto[]) {
        if (p_instancia.valores) {
            const v_propiedades = Object.getOwnPropertyNames(p_instancia.valores);
            v_propiedades.forEach(p_nombrePropiedad => {
                const
                    v_propiedad = this.propiedades[p_nombrePropiedad],
                    v_valor = p_instancia.valores[p_nombrePropiedad];

                if (v_propiedad instanceof PropiedadArray) {
                    if (!(v_valor instanceof InstanciaArray)) {
                        throw new ErrorValidacion('El valor de una propiedad de tipo array debe ser un array.');
                    }

                    const
                        v_padres = p_padres.concat(p_instancia),
                        v_elementos = v_valor.elementos.map(p_fila => {
                            if (typeof v_propiedad.elementos === 'string') {
                                throw new ErrorValidacion('No se esperaba un nombre de tipo.');
                            }
                            v_propiedad.elementos.referenciaPadresInstancia(p_fila, v_padres);
                            // return new InstanciaObjeto(p_fila);
                            //return { ...p_fila, ...{ padres: v_padres } };
                            p_fila.padres = v_padres;
                            return p_fila;
                        });
                    v_valor.elementos = v_elementos;
                } else if (v_propiedad instanceof PropiedadObjeto) {
                    if (!(v_valor instanceof InstanciaObjeto)) {
                        throw new ErrorValidacion('El valor de una propiedad de tipo objeto debe ser un objeto.');
                    }

                    const v_padres = p_padres.concat(p_instancia);
                    v_valor.padres = v_padres;
                }
            });
        }
    }

    // devuelve la definicion de una propiedad expresada en forma de ruta
    damePropiedad(p_propiedad: string): Propiedad;

    // devuelve la definicion y el valor de una propiedad expresada en forma de ruta
    damePropiedad(p_propiedad: string, p_instanciaModelo: InstanciaModelo): {
        propiedad: Propiedad;
        valor: InstanciaModelo;
    };

    damePropiedad(p_propiedad: string, p_instancia?: InstanciaObjeto): Propiedad | {
        propiedad: Propiedad;
        valor: any;
    } {
        const
            v_terminos = p_propiedad.split('.'),
            v_primerTermino = v_terminos[0],
            v_definicion = this.propiedades[v_primerTermino];

        if (typeof v_definicion === 'undefined') {
            throw new Error(`No se ha encontrado la propiedad '${v_primerTermino}'`);
        }

        const
            v_restoTerminos = v_terminos.slice(1),
            v_propiedades = v_restoTerminos
                .reduce((p_anterior, p_termino) => {
                    const v_propiedad = p_anterior[p_anterior.length - 1].definicion;
                    if (typeof v_propiedad === 'undefined') {
                        throw new Error(`No se ha encontrado la propiedad '${p_termino}'`);
                    } else if (!(v_propiedad instanceof PropiedadObjeto)) {
                        throw new Error('Sólo los objetos tienen propiedades.');
                    }

                    return p_anterior.concat({ nombre: p_termino, definicion: v_propiedad.propiedades[p_termino] });
                }, [{ nombre: v_primerTermino, definicion: this.propiedades[v_primerTermino] }]),
            v_propiedad = v_propiedades[v_propiedades.length - 1];
        if (typeof p_instancia === 'undefined') {
            return v_propiedad.definicion;
        }
        // const
        //     v_valores = p_instancia.valores,
        //     v_valor = v_restoTerminos
        //         .reduce((p_anterior, p_propiedad) => {
        //             if (typeof p_anterior === 'undefined' || p_anterior === null) {
        //                 return p_anterior;
        //             }
        //             const v_anterior = p_anterior as Diccionario;
        //             return v_anterior[p_propiedad];
        //         }, v_valores[v_primerTermino]);

        const
            x = p_instancia.valores[v_primerTermino],
            v_valor = v_restoTerminos
                .reduce(([p_anterior, p_propiedad], p_nombrePropiedad, p_indice) => {
                    if (p_propiedad instanceof PropiedadObjeto) {
                        if (!(p_anterior instanceof InstanciaObjeto)) {
                            throw new Error('Una propiedad de tipo objeto debe tener valor de tipo objeto.');
                        }
                        if (typeof p_anterior.valores === 'undefined' || p_anterior.valores === null) {
                            return [p_anterior.valores, v_propiedades[p_indice + 1].definicion];
                        }
                        return [p_anterior.valores[p_nombrePropiedad], v_propiedades[p_indice + 1].definicion];
                    } else {
                        throw new Error('Solo puede obtenerse el valor de una propiedad de un objeto.');
                    }
                }, [p_instancia.valores[v_primerTermino], v_propiedades[0].definicion]);
        return {
            propiedad: v_propiedad.definicion,
            valor: v_valor[0]
        };
    }

    // Resuelve las acciones de un modelo convirtiendolas en acciones resueltas y listas para ser invocadas, dando como
    // resultado un nuevo modelo.
    resuelveAcciones(): Modelo {
        const
            // Se resuelven las acciones definidas a nivel del modelo.
            v_acciones = Object.getOwnPropertyNames(this.acciones)
                .reduce((p_acciones, p_accion) => {
                    const v_accion = this.acciones[p_accion];
                    if (esAccionResuelta(v_accion)) {
                        throw new ErrorValidacion('La acción ya está resuelta.');
                    }
                    p_acciones[p_accion] = this.resuelve(undefined, v_accion);
                    return p_acciones;
                }, {} as Diccionario<AccionResuelta>),
            // Se resuelven las acciones onChange de las propiedades.
            v_propiedades = Object.getOwnPropertyNames(this.propiedades)
                .reduce((p_propiedades, p_nombre) => {
                    const v_propiedad = this.damePropiedad(p_nombre);
                    if (v_propiedad.ejecutaAccionAlCambiar()) {
                        if (esNombreAccion(v_propiedad.alCambiar)) {
                            const v_alCambiar = v_acciones[v_propiedad.alCambiar];
                            // RBM 15/10/19 -> ver con LCQ
                            // if (typeof v_alCambiar === 'undefined') {
                            //     throw new Error(`Operación no válida; La acción '${v_propiedad.alCambiar}' no se ha definido en el modelo actual.`);
                            // }
                            // v_propiedad.alCambiar = v_alCambiar;
                            v_propiedad.alCambiar = v_alCambiar;
                            // FIN RBM 15/10/19 -> ver con LCQ
                        } else {
                            const v_alCambiar = this.resuelve(undefined, v_propiedad.alCambiar as any);
                            v_propiedad.alCambiar = v_alCambiar;
                        }
                    }
                    p_propiedades[p_nombre] = v_propiedad;
                    return p_propiedades;
                }, {} as Propiedades), v_nuevoModelo = new Modelo(v_acciones, v_propiedades, this.tipos, []);
        return v_nuevoModelo;
    }

    // Creo en los hijos de la instancia una referencia a esta instancia.
    referenciaPadres(p_padres: Modelo[]): void {
        const v_propiedades = Object.getOwnPropertyNames(this.propiedades);
        v_propiedades.forEach(p_propiedad => {
            const v_propiedad = this.damePropiedad(p_propiedad);
            if (v_propiedad instanceof PropiedadArray) {
                if (esNombreTipo(v_propiedad.elementos)) {
                    throw new ErrorValidacion(`No se esperaba un nombre de tipo: ${v_propiedad.elementos}`);
                }
                v_propiedad.elementos = new Modelo(v_propiedad.elementos.acciones, v_propiedad.elementos.propiedades, v_propiedad.elementos.tipos, p_padres);
            }
        });
    }

    // valida que la instancia se corresponda al modelo
    ejecutaPruebaDeCordura(p_instancia: InstanciaObjeto) {
        if (typeof p_instancia.valores === 'undefined' || p_instancia.valores === null) {
            return;
        }
        const v_propiedades = Object.getOwnPropertyNames(p_instancia.valores);
        v_propiedades
            .forEach(p_nombrePropiedad => {
                const
                    v_propiedad = this.damePropiedad(p_nombrePropiedad),
                    v_valor = p_instancia.valores[p_nombrePropiedad];

                if (v_propiedad instanceof PropiedadObjeto) {
                    if (!(v_valor instanceof InstanciaObjeto)) {
                        throw new ErrorPruebaCordura(`Se esperaba una 'InstanciaObjeto' para la propiedad '${p_nombrePropiedad}'`);
                    }
                    const v_modeloPropiedad = this.tipos[v_propiedad.tipo];
                    v_modeloPropiedad.ejecutaPruebaDeCordura(v_valor);
                } else if (v_propiedad instanceof PropiedadArray) {
                    if (!(v_valor instanceof InstanciaArray)) {
                        throw new ErrorPruebaCordura(`Se esperaba una 'InstanciaArray' para la propiedad '${p_nombrePropiedad}'`);
                    }

                    v_valor.elementos.forEach(p_elemento => {
                        if (typeof v_propiedad.elementos === 'string') {
                            throw new ErrorPruebaCordura('No se esperaba un nombre de tipo');
                        }
                        v_propiedad.elementos.ejecutaPruebaDeCordura(p_elemento);
                    });

                } else if (v_propiedad instanceof PropiedadSimple) {
                    const v_tipoPredefinido = tiposPredefinidos[v_propiedad.tipo];
                    if (!v_tipoPredefinido.esAsignable(v_valor)) {
                        // debugger;
                        v_tipoPredefinido.esAsignable(v_valor);
                        throw new ErrorPruebaCordura(`${comoJson(v_valor)} no es asignable a una propiedad de tipo '${v_propiedad.tipo}'`);
                    }
                } else {
                    throw new ErrorPruebaCordura('Error interno; tipo de propiedad indeterminado');
                }
            });
    }

    generaProxy(p_instanciaModelo: InstanciaObjeto) {
        throw new Error('Obsoleto');
        // function generaProxy(p_modelo: Modelo, p_accesor: Accesor) {
        //     const
        //         v_propiedades = Object.getOwnPropertyNames(p_modelo.propiedades),
        //         v_propiedadesProxy = v_propiedades.reduce((p_proxy, p_propiedad) => {
        //             const v_propiedad = p_modelo.damePropiedad(p_propiedad);
        //             if (v_propiedad instanceof PropiedadObjeto) {
        //                 const v_tipo = p_modelo.tipos[v_propiedad.tipo];
        //                 p_proxy[p_propiedad] = v_generaProxyParaObjeto(v_tipo, (...p_argumentos) => {
        //                     const
        //                         v_objeto = p_accesor(),
        //                         v_valor = typeof v_objeto === 'undefined' ? v_objeto : v_objeto[p_propiedad];
        //                     if (p_argumentos.length === 0) {
        //                         return v_valor;
        //                     } else {
        //                         const
        //                             [v_nuevoValor] = p_argumentos.map(p_argumento => v_tipo.instanciaModelo(p_argumento).valores),
        //                             v_resultado = typeof v_objeto === 'undefined' ? v_objeto : v_objeto[p_propiedad] = v_nuevoValor;
        //                         cambios.push(new Cambio(this, { ...v_objeto }, p_propiedad, undefined, v_nuevoValor, v_valor, p_contextoLlamada));
        //                         return v_resultado;
        //                     }
        //                 });
        //                 p_proxy[p_propiedad].propiedadObjeto = true;
        //             } else if (v_propiedad instanceof PropiedadSimple) {
        //                 p_proxy[p_propiedad] = (...p_argumentos) => {
        //                     const
        //                         v_objeto = p_accesor(),
        //                         v_valor = typeof v_objeto === 'undefined' ? v_objeto : v_objeto[p_propiedad];
        //                     if (p_argumentos.length === 0) {
        //                         return v_valor;
        //                     } else {
        //                         let [v_nuevoValor] = p_argumentos;
        //                         const v_tipo = tiposPredefinidos[v_propiedad.tipo];
        //                         v_nuevoValor = v_tipo.instancia(v_nuevoValor);
        //                         const v_resultado = typeof v_objeto === 'undefined' ? v_objeto : v_objeto[p_propiedad] = v_nuevoValor;
        //                         cambios.push(new Cambio(this, { ...v_objeto }, p_propiedad, undefined, v_nuevoValor, v_valor, p_contextoLlamada));
        //                         return v_resultado;
        //                     }
        //                 };
        //                 p_proxy[p_propiedad].propiedadSimple = true;
        //                 p_proxy[p_propiedad].valor = () => p_accesor()[p_propiedad];
        //             } else if (v_propiedad instanceof PropiedadArray) {
        //                 // throw new Error('No implementado aún');
        //                 p_proxy[p_propiedad] = (...p_argumentos) => {
        //                     const
        //                         v_objeto = p_accesor(),
        //                         v_valor = typeof v_objeto === 'undefined' ? v_objeto : v_objeto[p_propiedad].elementos;
        //                     if (p_argumentos.length === 0) {
        //                         return v_valor;
        //                     } else {
        //                         const
        //                             [v_nuevoValor] = p_argumentos,
        //                             v_resultado = typeof v_objeto === 'undefined' ? v_objeto : v_objeto[p_propiedad].elementos = v_nuevoValor;
        //                         cambios.push(new Cambio(this, { ...v_objeto }, p_propiedad, undefined, v_nuevoValor, v_valor, p_contextoLlamada));
        //                         return v_resultado;
        //                     }
        //                 };
        //                 p_proxy[p_propiedad].propiedadArray = true;
        //             } else {
        //                 throw new Error('No implementado aún');
        //             }
        //             return p_proxy;
        //         }, {});

        //     const v_proxy = (...p_argumentos) => {
        //         if (p_argumentos.length === 0) {
        //             const v_valor = p_accesor();
        //             return typeof v_valor === 'undefined' || v_valor === null ? v_valor : v_propiedadesProxy;
        //         } else {
        //             const [v_nuevoValor] = p_argumentos;
        //             return p_accesor(v_nuevoValor);
        //         }
        //     };
        //     v_proxy.valor = p_accesor;
        //     v_proxy.otraPropiedad = true;
        //     return v_proxy;
        // };

        // const v_proxy = v_generaProxyParaObjeto(this, (...p_argumentos) => {
        //     if (p_argumentos.length === 0) {
        //         return p_instanciaModelo.valores;
        //     } else {
        //         const [v_nuevoValor] = p_argumentos;
        //         return p_instanciaModelo.valores = this.instanciaModelo(v_nuevoValor).valores;
        //     }
        // });

        // return v_proxy;
    }

    convierteModelo(p_instancia: InstanciaObjeto, p_contextoLlamada: any) {
        // const v_this = this;

        function mapeaValor(p_instancia: InstanciaObjeto, p_definicionModelo: Modelo) {
            if (typeof p_instancia.valores === 'undefined') {
                return undefined;
            }
            if (p_instancia.valores === null) {
                return null;
            }

            const
                v_propiedades = Object.getOwnPropertyNames(p_instancia.valores),
                v_modelo = v_propiedades
                    .reduce((p_objeto, p_propiedad) => {
                        const v_funcion: ((p_nuevoValor: any) => any) & { observable: boolean } = p_nuevoValor => {
                            const v_propiedad = p_definicionModelo.damePropiedad(p_propiedad);
                            const v = tiposPredefinidos[v_propiedad.tipo];
                            // debugger;
                            if (typeof p_nuevoValor !== 'undefined') {
                                if (v) {
                                    p_nuevoValor = v.normaliza(p_nuevoValor);
                                } /*else {
                                    const v2 = v_this.tipos[v_propiedad.tipo];
                                    if (v2) {
                                        p_nuevoValor = v2.instanciaModelo(p_nuevoValor);
                                    }
                                }*/
                            }
                            const v_valorAnterior = p_instancia.valores[p_propiedad];
                            if (typeof p_nuevoValor === 'undefined') {
                                if (v_propiedad instanceof PropiedadArray) {
                                    if (!(v_valorAnterior instanceof InstanciaArray)) {
                                        throw new ErrorValidacion('Una propiedad de tipo array solo acepta valores de tipo array.');
                                    }
                                    return v_valorAnterior.elementos.map(p_elemento => p_elemento.valores);
                                } else if (v_propiedad instanceof PropiedadObjeto) {
                                    if (!(v_valorAnterior instanceof InstanciaObjeto)) {
                                        throw new ErrorValidacion('Una propiedad de tipo objeto solo acepta valores de tipo objeto.');
                                    }
                                    return v_valorAnterior.valores;
                                }
                                return v_valorAnterior;
                                // if (v.propiedad instanceof PropiedadObjeto) {
                                //     return convierteModelo(v.propiedad, v.valor);
                                // }
                                // return v_valorAnterior;
                            } else {
                                cambios.push(new Cambio(p_definicionModelo, p_instancia, p_propiedad, undefined, p_nuevoValor, v_valorAnterior, p_contextoLlamada));
                                if (v_propiedad instanceof PropiedadArray) {
                                    if (!(v_valorAnterior instanceof InstanciaArray)) {
                                        throw new ErrorValidacion('Una propiedad de tipo array solo acepta valores de tipo array.');
                                    }
                                    const v_tipo = v_propiedad.elementos;
                                    if (esNombreTipo(v_tipo)) {
                                        throw new ErrorValidacion('Error interno; no se esperaba un nombre de tipo.');
                                    }
                                    let v_nuevoValor: InstanciaObjeto[];
                                    if (p_nuevoValor === null) {
                                        v_nuevoValor = [];
                                    } else if (!Array.isArray(p_nuevoValor)) {
                                        throw new ErrorValidacion(`Error interno; se esperaba un array como nuevo valor pero se obtuvo ${comoJson(p_nuevoValor)}.`);
                                    } else {
                                        v_nuevoValor = p_nuevoValor.map(p_valor => v_tipo.instanciaModelo(p_valor, [p_instancia]));
                                    }
                                    return v_valorAnterior.actualizaElementos(v_nuevoValor);
                                } else {
                                    // REVISA aquí hay algo mal. Si la propiedad no es un array (porque entramos por el else) como puede ser que luego
                                    // se compruebe si el nombre del tipo es 'array'??
                                    // const
                                    //     v_nombreTipo = v_propiedad.tipo,
                                    //     v_tipo = tiposPredefinidos[v_nombreTipo];
                                    // if (v_nombreTipo === 'array' || typeof v_tipo === 'undefined') {
                                    //     const v_nuevoValor = p_definicionModelo.tipos[v_nombreTipo].instanciaModeloSinPadres(p_nuevoValor);
                                    //     if (v_nuevoValor && Array.isArray(v_nuevoValor)) {
                                    //         throw new Error('No es un array');
                                    //     }
                                    // }
                                    if (v_propiedad instanceof PropiedadObjeto) {
                                        return (p_instancia.valores[p_propiedad] as InstanciaObjeto).valores = p_nuevoValor;
                                    } else {
                                        return p_instancia.valores[p_propiedad] = p_nuevoValor;
                                    }
                                }
                            }
                        };
                        v_funcion.observable = true;
                        Object.defineProperty(p_objeto, p_propiedad, { value: v_funcion });
                        return p_objeto;
                    }, {});
            return v_modelo;
        }
        const
            v_tipo = this.convierteTipos(),
            v_valor = mapeaValor(p_instancia, this), v_modelo = { tipo: v_tipo, valor: v_valor };
        return v_modelo;
    }

    // REVISA hay que revisar este mapeo. Está a medias/mal y el resultado es un mapeo incompleto que podría no funcionar en todos los casos.
    convierteTipos(): TipoObjeto {
        const
            v_equivalencias: Diccionario<Tipo> = {
                'logico': tipoLogico,
                'numero': tipoNumero,
                'texto': tipoTexto,
                'fecha': tipoFecha,
                'hora': tipoHora
            },
            v_tipos = Object.getOwnPropertyNames(this.tipos || {})
                .reduce((p_objeto, p_nombreTipo) => {
                    const v_tipo = this.tipos[p_nombreTipo];
                    p_objeto[p_nombreTipo] = new TipoObjeto('objeto', Object.getOwnPropertyNames(v_tipo.propiedades)
                        .reduce((p_objeto, p_propiedad) => {
                            if (esTipoPredefinido(v_tipo.propiedades[p_propiedad].tipo)) {
                                p_objeto[p_propiedad] = v_equivalencias[v_tipo.propiedades[p_propiedad].tipo];
                            } else {
                                p_objeto[p_propiedad] = v_tipo.propiedades[p_propiedad].tipo;
                            }
                            return p_objeto;
                        }, {}));
                    return p_objeto;
                }, {} as Miembros),
            v_propiedades = Object.getOwnPropertyNames(this.propiedades || {})
                .reduce((p_objeto, p_nombrePropiedad) => {
                    const v_propiedad = this.propiedades[p_nombrePropiedad];
                    if (v_propiedad instanceof PropiedadArray) {
                        if (esNombreTipo(v_propiedad.elementos)) {
                            p_objeto[p_nombrePropiedad] = new TipoArray(v_tipos[v_propiedad.elementos]);
                        } else {
                            p_objeto[p_nombrePropiedad] = new TipoArray(v_propiedad.elementos.convierteTipos());
                        }
                    } else if (esTipoPredefinido(v_propiedad.tipo) /* || this.tipos[v_propiedad.tipo] */) {
                        p_objeto[p_nombrePropiedad] = v_equivalencias[v_propiedad.tipo];
                    } else if (typeof v_tipos[v_propiedad.tipo] !== 'undefined') {
                        p_objeto[p_nombrePropiedad] = v_tipos[v_propiedad.tipo];
                    }
                    return p_objeto;
                }, {} as Miembros),
            v_miembros = {
                ...v_tipos,
                ...v_propiedades
            },
            v_tipo = new TipoObjeto('objeto', v_miembros);
        return v_tipo;
    }

    resuelve(p_modeloInvocante: Variable | undefined, p_accion: Accion): AccionResuelta {
        const
            v_modeloInvocanteTipo = p_modeloInvocante !== undefined ? p_modeloInvocante.tipo as TipoObjeto : undefined,
            v_padres = this.padres,
            v_tipoModelo = this.convierteTipos();

        let v_modelos = typeof v_padres === 'undefined' ? [] : v_padres
            .map((p_modelo, p_indice) => ({
                nombre: p_indice === 0 ? '@padre' : `@padre${p_indice}`,
                valor: new Variable(p_modelo.convierteTipos(), null)
            }))
            .concat({ nombre: '@modelo', valor: new Variable(v_tipoModelo, null) });

        if (typeof v_modeloInvocanteTipo !== 'undefined') {
            v_modelos = v_modelos.concat({ nombre: '@modeloInvocante', valor: new Variable(v_modeloInvocanteTipo, null) });
        }

        return Compilador.resuelve(p_accion, v_modelos);
    }

    evalua(
        p_accion: Accion | AccionResuelta,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable | undefined,
        p_resuelve: (p_valor: any) => void,
        p_rechaza: (p_motivo: any) => void,
        p_contextoLlamada: any): ResultadoAccion {

        const
            v_contextoLlamada = { ...p_contextoLlamada, definicionModelo: this },
            { tipo: v_tipoModelo, valor: v_valorModelo } = this.convierteModelo(p_instanciaModelo, v_contextoLlamada);
        let v_padres = typeof this.padres === 'undefined' ? [] : this.padres
            .map((p_padre, p_indice) => {
                const { tipo: v_tipoPadre, valor: v_valorPadre } = p_padre.convierteModelo(p_instanciaModelo.padres[0], v_contextoLlamada);
                return {
                    nombre: p_indice === 0 ? '@padre' : '@padre' + p_indice,
                    valor: new Variable(v_tipoPadre, v_valorPadre)
                };
            })
            .concat({ nombre: '@modelo', valor: new Variable(v_tipoModelo, v_valorModelo) });

        if (p_modeloInvocante !== undefined) {
            v_padres = v_padres.concat({ nombre: '@modeloInvocante', valor: p_modeloInvocante });
        }

        const v_resultado = Compilador.ejecuta(p_accion, v_padres, p_resuelve, p_rechaza, v_contextoLlamada);
        // if (v_resultado instanceof Promise) {
        //     console.log('xx');
        //     v_resultado = v_resultado.then(p_resultado => {
        //         if (typeof p_resultado === 'function' && p_resultado.hasOwnProperty('valor')) {
        //             return p_resultado.valor();
        //         } else {
        //             return p_resultado;
        //         }
        //     });
        // } else {
        //     if (typeof v_resultado === 'function' && v_resultado.hasOwnProperty('valor')) {
        //         return v_resultado.valor();
        //     } else {
        //         return v_resultado;
        //     }
        // }
        return v_resultado;
    }

    resuelveModelo() {
        const
            v_miembros = Object.getOwnPropertyNames(this.propiedades)
                /*.filter(p_nombreMiembro => p_modelo.miembros[p_nombreMiembro].tipo === 'accion')*/,
            v = v_miembros.reduce((p, p_nombreMiembro) => {
                const v_miembro = this.propiedades[p_nombreMiembro];
                if (v_miembro instanceof PropiedadObjeto) {
                    const v_modelo = this.tipos[v_miembro.tipo];

                    // debugger;

                    const v_f = v_modelo.resuelveModelo();

                    // return () => { p(); p = resuelveModelo(v_modelo); }

                    return p_objeto => {
                        const v_nuevoObjeto = p(p_objeto);
                        // debugger;
                        const v_objeto = p_objeto[p_nombreMiembro]();
                        v_nuevoObjeto[p_nombreMiembro] = v_f(v_objeto);

                        return v_nuevoObjeto;
                    };
                } else if (v_miembro instanceof PropiedadSimple) {
                    const
                        v_nombreTipo = v_miembro.tipo,
                        v_tipo = tiposPredefinidos[v_nombreTipo],
                        v_serializa = v_tipo.serializa;
                    return p_objeto => {
                        valida(typeof p_objeto !== 'undefined');
                        const v_nuevoObjeto = p(p_objeto);
                        if (p_objeto !== null) {
                            const
                                v_valorInterno = typeof p_objeto[p_nombreMiembro] === 'function' ? p_objeto[p_nombreMiembro]() : p_objeto[p_nombreMiembro],
                                v_valorSerializado = v_serializa(v_valorInterno);
                            v_nuevoObjeto[p_nombreMiembro] = v_valorSerializado;
                        } else {
                            v_nuevoObjeto[p_nombreMiembro] = null;

                        }
                        return v_nuevoObjeto;
                    };

                } else if (v_miembro instanceof PropiedadArray) {
                    const v_elemento = v_miembro.elementos;
                    valida(v_elemento instanceof Modelo);
                    // debugger;
                    const v_f = v_elemento.resuelveModelo();

                    return p_objeto => {
                        const v_nuevoObjeto = p(p_objeto);
                        // debugger;
                        const v_array = p_objeto[p_nombreMiembro]();
                        if (typeof v_array !== 'undefined' && v_array !== null) {
                            valida(Array.isArray(v_array));
                            const v_nuevoArray = v_array.map(p_elemento => v_f(p_elemento));
                            v_nuevoObjeto[p_nombreMiembro] = v_nuevoArray;
                        }
                        return v_nuevoObjeto;
                    };
                }
                return p;
            }, (p_objeto: any) => ({}));

        return v;
    }

    resuelveModelo2() {
        const
            v_miembros = Object.getOwnPropertyNames(this.propiedades),
            v_resultado = v_miembros.reduce((p, p_nombreMiembro) => {
                const v_miembro = this.propiedades[p_nombreMiembro];
                if (v_miembro instanceof PropiedadObjeto) {
                    const v_modelo = this.tipos[v_miembro.tipo];

                    const v_f = v_modelo.resuelveModelo2();

                    return p_objeto => {
                        valida(typeof p_objeto !== 'undefined');
                        const v_nuevoObjeto = p(p_objeto);
                        // debugger;
                        const v_objeto = p_objeto[p_nombreMiembro];
                        if (typeof v_objeto !== 'undefined' && v_objeto !== null) {
                            v_nuevoObjeto[p_nombreMiembro] = v_f(v_objeto);
                        } else {
                            v_nuevoObjeto[p_nombreMiembro] = v_objeto;
                        }

                        return v_nuevoObjeto;
                    };
                } else if (v_miembro instanceof PropiedadSimple) {
                    const
                        v_nombreTipo = v_miembro.tipo,
                        v_tipo = tiposPredefinidos[v_nombreTipo],
                        v_instancia = v_tipo.instancia;
                    return p_objeto => {
                        valida(typeof p_objeto !== 'undefined');
                        const v_nuevoObjeto = p(p_objeto);
                        if (p_objeto !== null) {
                            const
                                v_valorInterno = p_objeto[p_nombreMiembro],
                                v_valorSerializado = v_instancia(v_valorInterno);
                            v_nuevoObjeto[p_nombreMiembro] = v_valorSerializado;
                        } else {
                            v_nuevoObjeto[p_nombreMiembro] = null;
                        }
                        return v_nuevoObjeto;
                    };

                } else if (v_miembro instanceof PropiedadArray) {
                    const v_elemento = v_miembro.elementos;
                    valida(v_elemento instanceof Modelo);
                    // debugger;
                    const v_f = v_elemento.resuelveModelo2();

                    return p_objeto => {
                        valida(typeof p_objeto !== 'undefined');
                        const v_nuevoObjeto = p(p_objeto);
                        const v_array = p_objeto[p_nombreMiembro];
                        if (typeof v_array !== 'undefined' && v_array !== null) {
                            valida(Array.isArray(v_array));
                            const v_nuevoArray = v_array.map(p_elemento => v_f(p_elemento));
                            v_nuevoObjeto[p_nombreMiembro] = v_nuevoArray;
                        } else {
                            v_nuevoObjeto[p_nombreMiembro] = v_array;
                        }
                        return v_nuevoObjeto;
                    };
                }
                return p;
            }, (p_objeto: any) => ({}));

        return v_resultado;
    }
}

// export interface InstanciaModeloValoresArray {
//     elementos: InstanciaModelo[];
//     actualizaElementos: (p_elementos: any) => void;
// }

// export type InstanciaModeloValoresObjeto = Diccionario<string | number | boolean | Diccionario>;

// export type InstanciaModeloValores = InstanciaModeloValoresArray | InstanciaModeloValoresObjeto | string | number | boolean;

// export class InstanciaModelo<T extends InstanciaModeloValores = InstanciaModeloValores> {
//     valores: T;
//     padres: InstanciaModelo[];

//     constructor(p_valores: T) {
//         this.valores = p_valores;
//         this.padres = [];
//     }

//     valorPropiedad(p_definicionModelo: Modelo, p_expresionPropiedad: string) {
//         const { propiedad: v_propiedad, valor: v_valor } = p_definicionModelo.damePropiedad(p_expresionPropiedad, this);

//         if (v_propiedad instanceof PropiedadArray) {
//             return (v_valor as InstanciaModeloValoresArray).elementos.map(p_elemento => p_elemento.valores);
//         } else {
//             return v_valor;
//         }
//     }
// }

export type InstanciaModelo = InstanciaObjeto | InstanciaArray | boolean | number | string | null | undefined;

export class InstanciaObjeto {
    private propiedades: Diccionario<InstanciaModelo>;
    padres: InstanciaObjeto[];

    constructor(p_valores: Diccionario) {
        this.valores = p_valores;
        this.padres = [];
    }

    get valores(): Diccionario<InstanciaModelo> { return this.propiedades; }
    set valores(p_valores: Diccionario<InstanciaModelo>) {
        if (p_valores instanceof (InstanciaObjeto) || p_valores instanceof (InstanciaArray) ||
            typeof p_valores === 'boolean' || typeof p_valores === 'number' || typeof p_valores === 'string') {
            throw new TypeError('Se esperaba un objeto con los valores de la instancia.');
        }
        this.propiedades = p_valores;
    }

    valorPropiedad(p_definicionModelo: Modelo, p_expresionPropiedad: string) {
        const { propiedad: v_propiedad, valor: v_valor } = p_definicionModelo.damePropiedad(p_expresionPropiedad, this);

        if (v_propiedad instanceof PropiedadArray) {
            if (!(v_valor instanceof InstanciaArray)) {
                throw new TypeError('Una propiedad de tipo array debe tener un valor de tipo array.');
            }
            return v_valor.elementos.map(p_elemento => p_elemento.valores);
        } else if (v_propiedad instanceof PropiedadObjeto) {
            if (!(v_valor instanceof InstanciaObjeto)) {
                throw new TypeError('Una propiedad de tipo objeto debe tener un valor de tipo objeto.');
            }
            return v_valor.valores;
        } else {
            return v_valor;
        }
    }

    valor() {
        const
            v_propiedades = Object.getOwnPropertyNames(this.propiedades),
            v_objeto = v_propiedades.reduce((p_objeto, p_nombrePropiedad) => {
                const v_propiedad = this.propiedades[p_nombrePropiedad];
                if (v_propiedad instanceof InstanciaObjeto || v_propiedad instanceof InstanciaArray) {
                    p_objeto[p_nombrePropiedad] = v_propiedad.valor();
                } else {
                    p_objeto[p_nombrePropiedad] = v_propiedad;
                }
                return p_objeto;
            }, {} as Diccionario);
        return v_objeto;
    }
}

export class InstanciaArray {
    elementos: InstanciaObjeto[];

    constructor(p_elementos: InstanciaObjeto[]) {
        if (!Array.isArray(p_elementos) || !p_elementos.every(p_elemento => (p_elemento instanceof InstanciaObjeto))) {
            throw new Error('Se esperaba un array de instancias de objetos');
        }
        this.elementos = p_elementos;
    }

    actualizaElementos(p_elementos: InstanciaObjeto[]) {
        console.debug(p_elementos);
        this.elementos = p_elementos;
    }

    valor() {
        const v_array = this.elementos.map(p_elemento => {
            if (p_elemento instanceof InstanciaObjeto) {
                return p_elemento.valor();
            } else {
                throw new Error('Un array solo puede contener objetos');
            }
        });
        return v_array;
    }
}

export function esValorPropiedadInstanciaModeloObjeto(p_valor: any): p_valor is InstanciaModelo {
    return p_valor instanceof InstanciaObjeto ||
        p_valor instanceof InstanciaArray ||
        typeof p_valor === 'string' ||
        typeof p_valor === 'number' ||
        typeof p_valor === 'boolean' ||
        p_valor === null ||
        typeof p_valor === 'undefined';
}
