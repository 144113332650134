import { Injectable } from '@angular/core';
import { Perfil } from '../models/Perfil.model';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PerfilService {
    private perfil: Perfil | null;

    public readonly alCambiar = new Subject<Perfil | null>();

    constructor() {
        this.perfil = this.activo;
    }

    get activo(): Perfil | null {
        if (!(this.perfil instanceof Perfil)) {
            const v_perfilSerializado = localStorage.getItem('perfil');
            let v_perfil = v_perfilSerializado !== null ? JSON.parse(v_perfilSerializado) : null;
            if (v_perfil === null) {
                this.alCambiar.next(null);
                return null;
            } else {
                // si había un perfil en el local storage, no será una instancia de Perfil
                // por lo que se crea una.
                v_perfil = new Perfil(
                    v_perfil.usuario,
                    v_perfil.nombre,
                    v_perfil.cultureInfo,
                    v_perfil.idiomas_cod, v_perfil.idiomas_nombre,
                    v_perfil.gvagente_cod, v_perfil.gvagente_nombre,
                    v_perfil.empgrupo_cod, v_perfil.empgrupo_nombre,
                    v_perfil.emp_cod, v_perfil.emp_nombre
                );
                this.perfil = v_perfil;
            }
        }
        return this.perfil;
    }

    set activo(p_nuevoPerfil: Perfil | null) {
        this.alCambiar.next(p_nuevoPerfil);

        if (p_nuevoPerfil instanceof Perfil) {
            this.perfil = p_nuevoPerfil;
            localStorage.setItem('perfil', JSON.stringify(p_nuevoPerfil));
        } else {
            this.perfil = null;
            localStorage.removeItem('perfil');
        }
    }
}
