import { Component, OnInit, OnDestroy, ComponentRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseComponenteAngular } from '../UtilidadesComponentes';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'app-contenedor-modal',
    templateUrl: './contenedorModal.component.html',
})
export class ContenedorModalComponent implements OnInit, OnDestroy {
    private static _id = 0;
    id: string;

    readonly alAbrirModal = new BehaviorSubject<ComponentRef<BaseComponenteAngular>>(null);
    private resuelve: (p_valor: boolean) => void;
    abierto = false;

    @ViewChild('modal', { read: ModalComponent }) modal: ModalComponent;
    @ViewChild('contenedorNuevoModal', { read: ContenedorModalComponent }) contenedorNuevoModal: ContenedorModalComponent;

    constructor(
    ) {
        this.id = `contenedor-modal-${ContenedorModalComponent._id++}`;
    }

    ngOnInit() {
        console.warn(`Creando ${this.id}`);
    }

    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
    }

    abreModal(p_componente: ComponentRef<BaseComponenteAngular>, p_resuelve: (p_valor: boolean) => void) {
        if (this.abierto) {
            this.contenedorNuevoModal.abreModal(p_componente, p_resuelve);
        } else {
            this.abierto = true;
            this.alAbrirModal.next(p_componente);
            this.resuelve = p_resuelve;
        }
    }

    cierraModal(p_ok: boolean) {
        if (this.abierto) {
            if (this.contenedorNuevoModal.abierto) {
                this.contenedorNuevoModal.cierraModal(p_ok);
            } else {
                // this.modal.cierra().then(() => this.resuelve(p_ok));
                // this.resuelve(p_ok);
                this.modal.cierra();
                this.resuelve(p_ok);
            }
        }
    }

    private _estado: 'modal-abriendo' | 'modal-cerrando' | null = null;
    get estado(): string {
        if (this._estado === null) {
            return '';
        } else {
            return this._estado;
        }
    }
}
