import { DescriptorCalculo } from "../especificacion/componentes";
import { Diccionario, comoJson } from "../evotec_comun";


type TransformacionValor = (p_valor: any, p_transformacion: DescriptorCalculo) => any;
type TransformacionesValor = Diccionario<TransformacionValor>;

interface TransformacionFormatea extends DescriptorCalculo {
    formato: string;
}

class TransformacionesEnlaces {
    static mayusculas(p_valor: string): string {
        if (typeof p_valor !== 'string') {
            console.warn(`${p_valor} no es un valor de tipo 'string'.`);
            return '';
        }
        return p_valor.toUpperCase();
    }

    static minusculas(p_valor: string): string {
        if (typeof p_valor !== 'string') {
            console.warn(`${p_valor} no es un valor de tipo 'string'.`);
            return '';
        }
        return p_valor.toLowerCase();
    }

    static igual(p_valor: any, p_otroValor: any): boolean {
        return p_valor === p_otroValor;
    }

    static esComparacion(p_transformacion: DescriptorCalculo): p_transformacion is TransformacionFormatea {
        return typeof p_transformacion === 'object' && p_transformacion.hasOwnProperty('valor');
    }

    static formatea(p_valor: any, p_formato: string): string {
        const v_argumentos = [p_valor];
        return p_formato.replace(/{(\d+)}/g, (p_coincidencia, p_indice) => typeof v_argumentos[p_indice] !== 'undefined' ? v_argumentos[p_indice] : p_coincidencia);
    }

    static esTransformacionFormatea(p_transformacion: DescriptorCalculo): p_transformacion is TransformacionFormatea {
        return typeof p_transformacion === 'object' && typeof (p_transformacion.formato) === 'string';
    }
}

export const transformacionesEnlaces: TransformacionesValor = {
    // 'ninguna': p_valor => p_valor,
    'mayusculas': TransformacionesEnlaces.mayusculas,
    'minusculas': TransformacionesEnlaces.minusculas,
    'S_es_true': p_valor => p_valor === 'S',
    'N_es_true': p_valor => p_valor === 'N',
    'true_es_S': p_valor => p_valor ? 'S' : 'N',
    'true_es_N': p_valor => p_valor ? 'N' : 'S',
    'vacio_es_true': p_valor => !!p_valor,
    'vacio_es_false': p_valor => !p_valor,
    'null_es_true': p_valor => p_valor === null,
    'null_es_false': p_valor => p_valor !== null,
    'no': p_valor => !p_valor,
    'igual': (p_valor, p_transformacion) => p_valor === p_transformacion.valor,
    'distinto': (p_valor, p_transformacion) => p_valor !== p_transformacion.valor,
    'mayor': (p_valor, p_transformacion) => p_valor > p_transformacion.valor,
    'mayorIgual': (p_valor, p_transformacion) => p_valor >= p_transformacion.valor,
    'menor': (p_valor, p_transformacion) => p_valor < p_transformacion.valor,
    'menorIgual': (p_valor, p_transformacion) => p_valor <= p_transformacion.valor,
    'equivalencia': (p_valor, p_transformacion) => p_transformacion.tabla.filter((p: { clave: any, valor: any }) => p.clave === p_valor)[0].valor,
    'formatea': (p_valor, p_transformacion) => {
        if (TransformacionesEnlaces.esTransformacionFormatea(p_transformacion)) {
            return TransformacionesEnlaces.formatea(p_valor, p_transformacion.formato);
        } else {
            console.debug(`${comoJson(p_transformacion)} no es una transformación 'formatea' válida.`);
            return '';
        }
    }
}
