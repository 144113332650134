export class Perfil {
    readonly iniciales: string;
    readonly color: string;

    constructor(
        public readonly usuario: string, // por ahora este dato no viene nunca relleno ya que el /usuarios/actual no lo devuelve
        public readonly nombre: string,
        public readonly cultureInfo: string,
        public readonly idiomas_cod: string,
        public readonly idiomas_nombre: string,
        public readonly gvagente_cod: string,
        public readonly gvagente_nombre: string,
        public readonly empgrupo_cod: string,
        public readonly empgrupo_nombre: string,
        public readonly emp_cod: string,
        public readonly emp_nombre: string
    ) {
        this.iniciales = Perfil.calculaIniciales(nombre);
        this.color = Perfil.calculaColor(this.iniciales);
    }

    private static calculaIniciales(p_nombre: string): string {
        const
            v_nombre = p_nombre
                .replace(/\(.*\)|\(.*$/, '') // quito todo lo que vaya entre parentesis o lo que vaya después de un parentesis abierto
                .toUpperCase()
                .split(' ')
                .filter(p => !['DE', 'LA', 'DEL'].includes(p)),
            v_iniciales = v_nombre
                .map(p => p[0])
                .slice(0, 3)
                .join('');
        return v_iniciales;
    }

    private static colores = ['magenta', 'violeta', 'azul', 'verde', 'amarillo', 'gris', 'rojo', 'naranja', 'lila', 'turquesa', 'verde-azulado', 'marron'];

    private static calculaColor(p_iniciales: string): string {
        const
            v_sumatorioAscii = Array.from(p_iniciales).reduce(
                (p_sumatorioAscii, p_caracter) => {
                    return p_sumatorioAscii + p_caracter.charCodeAt(0);
                }, 0),
            v_indice = v_sumatorioAscii % Perfil.colores.length,
            v_color = Perfil.colores[v_indice];
        return 'marron';
        return v_color;
    }
}
