<!-- <ul *ngIf="menu && menu.length > 0">
    <li app-opcion-menu *ngFor="let opcion of menu" [codigo]="opcion.codigo" [etiqueta]="opcion.etiqueta"
        [hijas]="opcion.hijas"></li>
</ul>  -->

<div id="jqxWidget">
    <!-- <div *ngIf="menu && menu.length > 0"> -->
    <jqxMenu #jqxmenu [auto-create]="false" mode="vertical" (onItemclick)="itemclick($event)" [clickToOpen]="false"
        [autoCloseOnClick]="true">

        <!-- <ul class="jqx-menu-ul">
                <li app-opcion-menu *ngFor="let opcion of menu" [id]="codigo" class="jqx-item" [codigo]="opcion.codigo"
                    [etiqueta]="opcion.etiqueta" [hijas]="opcion.hijas">
                </li>
            </ul> -->

        <ul class="jqx-menu-ul" #menuElements> </ul>
    </jqxMenu>
    <!-- </div> -->
</div>