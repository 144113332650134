import { Diccionario, formatoFechaYYYYMMDD_HHMMSS } from '../evotec_comun';
import { Configuracion } from '../ejecucion/configuracion';

interface TipoPredefinido {
    valorVacio: any;
    // normaliza un valor interno
    normaliza(p_valorInstancia: any): any;
    desdeTexto(p_texto: any): any;
    esAsignable(p_valorInstancia: any): boolean;
    // convierte un valor del api en su equivalente interno
    instancia(p_valor: any): any;
    // convierte un valor interno en su equivalente para el api
    serializa(p_valor: any): any;
}

type TiposPredefinidos = Diccionario<TipoPredefinido>;

class TipoPredefinidoFecha implements TipoPredefinido {
    valorVacio: any = '';

    normaliza(p_valor: any) {
        if ((typeof p_valor === 'string' && p_valor === '') || p_valor === null) {
            return null;
        }
        return Configuracion.formatoFecha.formateaFecha(p_valor);
    }

    desdeTexto(p_texto: any) {
        return p_texto;
    }

    esAsignable(p_valorInstancia: any): boolean {
        return p_valorInstancia === null || typeof p_valorInstancia === 'undefined' || (typeof p_valorInstancia === 'string' && Configuracion.formatoFecha.esFecha(p_valorInstancia)) || p_valorInstancia instanceof Date;
    }

    instancia(p_valor: any): any {
        if (typeof p_valor === 'undefined' || p_valor === null) {
            return p_valor;
        }
        if ((typeof p_valor === 'string' && p_valor === '')) {
            return null;
        }

        const v_fecha = Configuracion.formatoFechaApi.fecha(p_valor);
        return Configuracion.formatoFecha.formateaFecha(v_fecha);
    }

    serializa(p_valor: any) {
        if (typeof p_valor === 'undefined' || p_valor === null) {
            return p_valor;
        }
        if ((typeof p_valor === 'string' && p_valor === '')) {
            return null;
        }

        const v_fecha = Configuracion.formatoFecha.fecha(p_valor);
        return Configuracion.formatoFechaApi.formateaFecha(v_fecha);
    }
}

class TipoPredefinidoFechaHora implements TipoPredefinido {
    valorVacio: any = '';

    normaliza(p_valor: any) {
        if ((typeof p_valor === 'string' && p_valor === '') || p_valor === null) {
            return null;
        }
        return Configuracion.formatoFecha.formateaFecha(p_valor);
    }

    desdeTexto(p_texto: any) {
        return p_texto;
    }

    esAsignable(p_valorInstancia: any): boolean {
        return p_valorInstancia === null || typeof p_valorInstancia === 'undefined' || (typeof p_valorInstancia === 'string' && Configuracion.formatoFecha.esFecha(p_valorInstancia)) || p_valorInstancia instanceof Date;
    }

    instancia(p_valor: any): any {
        if (typeof p_valor === 'undefined' || p_valor === null) {
            return p_valor;
        }
        if ((typeof p_valor === 'string' && p_valor === '')) {
            return null;
        }

        const v_fecha = Configuracion.formatoFechaHoraApi.fecha(p_valor);
        return Configuracion.formatoFecha.formateaFecha(v_fecha);
    }

    serializa(p_valor: any) {
        if (typeof p_valor === 'undefined' || p_valor === null) {
            return p_valor;
        }
        if ((typeof p_valor === 'string' && p_valor === '')) {
            return null;
        }

        const v_fecha = Configuracion.formatoFechaHora.fecha(p_valor);
        return Configuracion.formatoFechaApi.formateaFecha(v_fecha);
    }
}

class TipoPredefinidoHora implements TipoPredefinido {
    valorVacio: any = '';

    normaliza(p_valor: any) {
        return p_valor;
    }

    desdeTexto(p_texto: any) {
        return p_texto;
    }

    esAsignable(p_valorInstancia: any): boolean {
        return p_valorInstancia === null || typeof p_valorInstancia === 'undefined' || typeof p_valorInstancia === 'string';
    }

    instancia(p_valor: any): any {
        return p_valor;
    }

    serializa(p_valor: any) {
        return p_valor;
    }
}

class TipoPredefinidoNumero implements TipoPredefinido {
    valorVacio: any = 0;

    normaliza(p_valor: any) {
        return p_valor;
    }

    desdeTexto(p_texto: any) {
        if (typeof p_texto === 'string' && p_texto === '') {
            return null;
        }
        const v_resultado = p_texto * 1;
        if (isNaN(v_resultado)) {
            return 0;
        }
        return v_resultado;
    }

    esAsignable(p_valorInstancia: any): boolean {
        return p_valorInstancia === null || (typeof p_valorInstancia === 'string' && p_valorInstancia === '') || typeof p_valorInstancia === 'undefined' || typeof p_valorInstancia === 'number';
    }

    instancia(p_valor: any): any {
        return p_valor;
    }

    serializa(p_valor: any) {
        return p_valor;
    }
}

class TipoPredefinidoTexto implements TipoPredefinido {
    valorVacio: any = '';

    normaliza(p_valor: any) {
        return p_valor;
    }
    desdeTexto(p_texto: any) {
        return p_texto;
    }
    esAsignable(p_valorInstancia: any): boolean {
        return p_valorInstancia === null || typeof p_valorInstancia === 'undefined' || typeof p_valorInstancia === 'string';
    }

    instancia(p_valor: any): any {
        return p_valor;
    }

    serializa(p_valor: any) {
        return p_valor;
    }
}

class TipoPredefinidoArray implements TipoPredefinido {
    valorVacio: any = [];

    normaliza(p_valor: any) {
        return p_valor;
    }
    desdeTexto(p_texto: any) {
        throw new Error('Operación no válida');
    }

    esAsignable(p_valorInstancia: any): boolean {
        return p_valorInstancia === null || typeof p_valorInstancia === 'undefined' || Array.isArray(p_valorInstancia)
    }

    instancia(p_valor: any): any {
        return p_valor;
    }

    serializa(p_valor: any) {
        return p_valor;
    }
}

class TipoPredefinidoLogico implements TipoPredefinido {
    valorVacio: any = false;

    normaliza(p_valor: any) {
        return p_valor;
    }
    desdeTexto(p_texto: any) {
        return p_texto;
    }
    esAsignable(p_valorInstancia: any): boolean {
        return p_valorInstancia === null || typeof p_valorInstancia === 'undefined' || typeof p_valorInstancia === 'boolean';
    }

    instancia(p_valor: any): any {
        return p_valor;
    }

    serializa(p_valor: any) {
        return p_valor;
    }
}

const
    tipoPredefinidoFecha = new TipoPredefinidoFecha(),
    tipoPredefinidoHora = new TipoPredefinidoHora(),
    tipoPredefinidoFechaHora = new TipoPredefinidoFechaHora(),
    tipoPredefinidoNumero = new TipoPredefinidoNumero(),
    tipoPredefinidoTexto = new TipoPredefinidoTexto(),
    tipoPredefinidoArray = new TipoPredefinidoArray(),
    tipoPredefinidoLogico = new TipoPredefinidoLogico();

export const tiposPredefinidos: TiposPredefinidos = {
    fecha: tipoPredefinidoFecha,
    date: tipoPredefinidoFecha,
    hora: tipoPredefinidoHora,
    time: tipoPredefinidoHora,
    fechaHora: tipoPredefinidoFechaHora,
    numero: tipoPredefinidoNumero,
    number: tipoPredefinidoNumero,
    texto: tipoPredefinidoTexto,
    string: tipoPredefinidoTexto,
    array: tipoPredefinidoArray,
    logico: tipoPredefinidoLogico,
};

export function esTipoPredefinido(p_nombre: string): boolean {
    return typeof tiposPredefinidos[p_nombre] !== 'undefined';
}
