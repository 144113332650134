import { Injectable } from '@angular/core';

enum EstadoMenu {
    Normal = 0,
    Minimizado = 1,
    Oculto = 2,
}

@Injectable({
    providedIn: 'root'
})
export class PaginaService {
    private estadoMenu = EstadoMenu.Normal;
    private tareasEnCurso = 0;

    restauraMenu() {
        this.estadoMenu = EstadoMenu.Normal;
    }

    minimizaMenu() {
        this.estadoMenu = EstadoMenu.Minimizado;
    }

    ocultaMenu() {
        this.estadoMenu = EstadoMenu.Oculto;
    }

    get menuMinimizado(): boolean {
        return this.estadoMenu === EstadoMenu.Minimizado;
    }

    get menuOculto(): boolean {
        return this.estadoMenu === EstadoMenu.Oculto;
    }

    muestraSpinner() {
        this.tareasEnCurso++;
    }

    ocultaSpinner() {
        this.tareasEnCurso--;
    }

    get ocupado(): boolean {
        return this.tareasEnCurso > 0;
    }
}
