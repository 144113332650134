<div class="gantt_control">
  <input type="radio" id="scale0" class="gantt_radio" name="scale" value="hour">
  <label for="scale0">Horas</label>
  <input type="radio" id="scale1" class="gantt_radio" name="scale" value="day">
  <label for="scale1">Días</label>

  <input type="radio" id="scale2" class="gantt_radio" name="scale" value="week">
  <label for="scale2">Semanas</label>

  <input type="radio" id="scale3" class="gantt_radio" name="scale" value="month" [checked]="true">
  <label for="scale3">Meses</label>

  <input type="radio" id="scale4" class="gantt_radio" name="scale" value="quarter">
  <label for="scale4">Trimestres</label>

  <input type="radio" id="scale5" class="gantt_radio" name="scale" value="year">
  <label for="scale5">Años</label>
  <!-- <div class="filters_wrapper" id="filters_wrapper"> -->
  <!-- <span>Filtrar por estado:</span> -->
  <!-- <label class="checked_label" id="scale0">
      <input type="checkbox" name="TODOS" value="TODOS" checked />
      <i class="material-icons icon_color">check_box</i>TODOS
    </label> -->

  <!-- <label id="scale1">
      <input type="checkbox" name="INV" value="INV" checked />
      <i class="material-icons icon_color_inv">check_box</i>INV
    </label>

    <label id="scale2">
      <input type="checkbox" name="PLAN" value="PLAN" checked />
      <i class="material-icons icon_color_plan">check_box</i>PLAN
    </label>

    <label id="scale3">
      <input type="checkbox" name="PROG" value="PROG" checked />
      <i class="material-icons icon_color_prog">check_box</i>PROG
    </label>
  </div> -->
</div>
<div #gantt_here class='gantt-chart'></div>
<app-chart></app-chart>