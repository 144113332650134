import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateViaAuthGuard } from '../canActivate.guard';
import { PaginaComponent } from '../components/pagina/pagina.component';
import { LoginComponent } from '../../static/login/login.component';

const rutas: Routes = [
    {
        path: 'Main',
        canActivate: [CanActivateViaAuthGuard],
        component: PaginaComponent,
        children: [{
            path: 'login',
            component: LoginComponent, outlet: 'Main'
        }]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(rutas)
    ],
    exports: [
        RouterModule
    ]
})
export class MainRoutingModule { }
