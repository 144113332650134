import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { jqxSchedulerComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxscheduler';
import { Variable } from '../../abstracto/acciones/variables';
import { ComponenteAgenda } from '../../abstracto/ejecucion/componentes';
import { Modelo, InstanciaModelo, InstanciaObjeto } from '../../abstracto/ejecucion/modelo';
import { BaseComponenteAngular } from '../UtilidadesComponentes';

@Component({
    selector: 'app-agenda',
    templateUrl: './agenda.component.html',
    styleUrls: []
})
export class AgendaComponent implements BaseComponenteAngular<ComponenteAgenda>, OnInit, AfterViewInit {
    @Input() id: string;
    @ViewChild('schedulerReference', { static: true })
    scheduler: jqxSchedulerComponent;

    _registros: any = [];
    source: any = {};
    dataAdapter: any = new jqx.dataAdapter(this.source);
    date: any = new jqx.date('todayDate');
    dataFields: any = {
        from: 'start',
        to: 'end',
        id: 'id',
        description: 'description',
        subject: 'subject',
        resourceId: 'calendar'
    };
    resources: any = {};
    views: any[] = [
        {
            type: 'dayView',
            timeRuler: { scale: 'hour', formatString: 'HH:mm' }
        },
        {
            type: 'weekView',
            timeRuler: {
                scale: 'hour',
                formatString: 'HH:mm'
            },
            workTime: {
                fromDayOfWeek: 1,
                toDayOfWeek: 5,
                fromHour: 7,
                toHour: 19
            }
        },
        {
            type: 'monthView',
            dayViewNavigation: true,
            showWeekNumbers: true,
            timeRuler: {
                scale: 'hour',
                formatString: 'HH:mm'
            }
        },
        {
            type: 'agendaView',
            timeRuler: { scale: 'hour', formatString: 'HH:mm' }
        },
        // { type: 'timelineDayView' },
        // { type: 'timelineWeekView' },
        { type: 'timelineMonthView' }
    ];

    loc: any = {
        firstDay: 1,
        days: {
            // full day names
            names: [
                'domingo',
                'lunes',
                'martes',
                'miércoles',
                'jueves',
                'viernes',
                'sábado'
            ],
            // abbreviated day names
            namesAbbr: ['dom', 'lun', 'mar', 'mie', 'jue', 'vie', 'sab'],
            // shortest day names
            namesShort: ['D', 'L', 'M', 'X', 'J', 'V', 'S']
        },
        months: {
            // full month names (13 months for lunar calendards -- 13th month should be "" if not lunar)
            names: [
                'enero',
                'febrero',
                'marzo',
                'abril',
                'mayo',
                'junio',
                'julio',
                'agosto',
                'septiembre',
                'octubre',
                'noviembre',
                'diciembre',
                ''
            ],
            // abbreviated month names
            namesAbbr: [
                'ene',
                'feb',
                'mar',
                'abr',
                'may',
                'jun',
                'jul',
                'ago',
                'sep',
                'oct',
                'nov',
                'dec',
                ''
            ]
        },
        agendaViewString: 'Agenda',
        dayViewString: 'Diaria',
        weekViewString: 'Semanal',
        monthViewString: 'Mensual',
        timelineMonthViewString: 'Cronología',
        emptyDataString: 'No se ha encontrado ningún evento',

        agendaDateColumn: 'Fecha',
        agendaTimeColumn: 'Hora',
        agendaAppointmentColumn: 'Evento'
    };

    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_fila: number,
        p_componente: ComponenteAgenda,
        p_modeloInvocante: Variable
    ): void {
        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    definicionModelo!: Modelo;
    instanciaModelo!: InstanciaModelo;
    modeloInvocante!: Variable;
    fila!: number;

    componente!: ComponenteAgenda;

    // Propiedad 'registros'
    // private _registros: object[] | undefined;
    get registros(): object[] {
        if (typeof this._registros === 'undefined') {
            throw new Error(
                'Error interno; el componente no se ha inicializado aún.'
            );
        }
        return this._registros;
    }
    set registros(p_valor: object[]) {
        if (typeof this._registros === 'undefined') {
            throw new Error(
                'Error interno; el componente no se ha inicializado aún.'
            );
        }
        this._registros = p_valor;
    }

    ngOnInit() { }

    ngAfterViewInit(): void {
        this.source = {
            dataType: 'array',
            dataFields: [
                { name: 'id', type: 'string' },
                { name: 'description', type: 'string' },
                { name: 'subject', type: 'string' },
                { name: 'calendar', type: 'string' },
                { name: 'start', type: 'date' },
                { name: 'end', type: 'date' }
            ],
            id: 'id',
            localData: this._registros
        };

        this.dataAdapter = new jqx.dataAdapter(this.source);
        this.resources = {
            colorScheme: 'scheme02',
            dataField: 'calendar',
            source: new jqx.dataAdapter(this.source)
        };
    }

    mySchedulerOnBindingComplete(p_evento: any): void {
        if (this._registros !== undefined && this._registros.length > 0) {
            const myAppointment = this._registros[0];
            this.scheduler.showLegend(true);
            this.scheduler.ensureAppointmentVisible(myAppointment.id);
        }
    }

    mySchedulerOnViewChange(event: any) {
        if (event.args.newViewType === 'weekView') {
            if (this._registros !== undefined && this._registros.length > 0) {
                const myAppointment = this._registros[0];
                this.scheduler.ensureAppointmentVisible(myAppointment.id);
            }
        }
    }
}
