import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { Variable } from '../../abstracto/acciones/variables';
import { igual, pixeles, compruebaFormatoAncho } from '../../abstracto/evotec_comun';
import { ComponenteTabla } from '../../abstracto/ejecucion/componentes';
import { Modelo, InstanciaModelo, InstanciaObjeto } from '../../abstracto/ejecucion/modelo';
import { BaseComponenteAngular } from '../UtilidadesComponentes';
import { PerfilService } from '../../services/Perfilservice';
import { getLocalization } from './localization';

@Component({
    selector: 'app-tabla',
    templateUrl: './tabla.component.html',
    styleUrls: []
})
export class TablaComponent implements BaseComponenteAngular<ComponenteTabla>, OnInit {
    private static _id = 0;
    id: string;

    constructor(private readonly profileService: PerfilService) {
        this.id = `campo-${TablaComponent._id++}`;
    }

    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_fila: number,
        p_componente: ComponenteTabla,
        p_modeloInvocante: Variable): void {

        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    definicionModelo: Modelo | undefined;
    instanciaModelo: InstanciaModelo | undefined;
    modeloInvocante: Variable | undefined;
    fila: number | undefined;

    componente: ComponenteTabla | undefined;

    // Propiedad 'etiqueta'
    private _etiqueta: string | undefined;
    get etiqueta(): string | undefined { return this._etiqueta; }
    set etiqueta(p_valor: string | undefined) { this._etiqueta = p_valor; }

    // Propiedad 'seleccionado'
    private _seleccionado: any = null;
    get seleccionado(): any { return this._seleccionado; }
    set seleccionado(p_valor: any) {
        if (typeof this._grid === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        if (igual(p_valor, this._seleccionado)) {
            return;
        }

        this._seleccionado = p_valor;
        this.resaltarFilaSeleccionada();
        // if (typeof this._registros !== 'undefined') {
        //     const v_indice = this._registros.findIndex(p_registro => igual(p_registro, this._seleccionado));
        //     if (v_indice === -1) {
        //         if (this._grid.getselectedrowindexes().length > 0) {
        //             this._grid.clearselection();
        //         }
        //     } else {
        //         const v_indiceActual = this._grid.getselectedrowindex();
        //         if (v_indiceActual !== v_indice) {
        //             this._grid.selectrow(v_indice);
        //         }
        //     }
        // }

        if (typeof this.seleccionadoCambiado !== 'undefined') {
            this.seleccionadoCambiado(this.seleccionado);
        }
    }

    seleccionadoCambiado: ((p_valor: any) => void) | undefined;

    alSeleccionarFila(p_evento: any) {
        if (typeof this._registros === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        const v_registro = this._registros[p_evento.args.rowindex];
        if (igual(v_registro, this.seleccionado)) {
            // this.seleccionado = null;
            return;
        }
        this.seleccionado = v_registro;
    }

    alPulsarFila(p_evento: any) {
        // const v_indiceActual = this._grid.getselectedrowindex();

        // if (p_evento.args.rowindex === v_indiceActual) {
        //     this._seleccionado = null;
        //     this._grid.clearselection();
        //     this._grid.unselectrow(v_indiceActual);
        // }
    }

    resaltarFilaSeleccionada() {
        if (typeof this._registros !== 'undefined' && typeof this._seleccionado !== 'undefined') {
            const v_indice = this._registros.findIndex(p_registro => igual(p_registro, this._seleccionado));
            if (v_indice === -1) {
                if (this._grid.getselectedrowindexes().length > 0) {
                    this._grid.clearselection();
                }
            } else {
                const v_indiceActual = this._grid.getselectedrowindex();
                if (v_indiceActual !== v_indice) {
                    this._grid.selectrow(v_indice);
                }
            }
        } else {
            if (this._grid.getselectedrowindexes().length > 0) {
                this._grid.clearselection();
            }
        }
    }


    get modoSeleccion(): string {
        if (typeof this.seleccionadoCambiado === 'undefined') {
            return 'none';
        } else {
            return 'singlerow';
        }
    }

    get habilitaResalte(): boolean {
        return typeof this.seleccionadoCambiado !== 'undefined';
    }

    private _modificable: boolean | undefined;
    get modificable(): boolean {
        if (typeof this._modificable === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        return this._modificable;
    }
    set modificable(p_valor: boolean) { this._modificable = p_valor; }

    get soloLectura(): boolean | null {
        if (this._modificable) {
            return null;
        } else {
            return true;
        }
    }

    private _formato: string | undefined;
    get formato(): string {
        if (typeof this._formato !== 'undefined') {
            return this._formato;
        } else {
            return '';
        }
    }
    set formato(p_valor: string) { this._formato = p_valor; }

    // Propiedad 'registros'
    private _registros: object[] | undefined;
    get registros(): object[] {
        if (typeof this._registros === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        return this._registros;
    }
    set registros(p_registros: object[]) {
        if (typeof this._grid === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        //const v_seleccionado = this.seleccionado;

        if (Array.isArray(this._registros)) {
            if (typeof p_registros === 'undefined') {
                this._registros.splice(0, this._registros.length);
            } else {
                this._registros.splice(0, this._registros.length, ...p_registros);
            }
        } else {
            this._registros = p_registros;
        }

        //this.seleccionado = v_seleccionado;

        this._grid.updatebounddata('cells');
        this.resaltarFilaSeleccionada();
    }

    // Propiedad 'columnas'
    private _columnas: any[] | undefined;
    get columnas(): any[] {
        if (typeof this._columnas === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        return this._columnas;
    }

    set columnas(p_valor: any[]) {
        // Probar formato fecha que viene de la propiedad formato en el generador Angular
        this._columnas = p_valor;

        this.columnasGrid = this._columnas.map(p_columna => {
            const alineacion = this.alineacionesTabla(p_columna);
            const
                v_descriptorColumna = this.descriptorColumna[p_columna.tipo],
                v_formatoCelda = typeof v_descriptorColumna !== 'undefined' ? v_descriptorColumna.formato : undefined,
                v_anchoColumna = typeof v_descriptorColumna !== 'undefined' ? v_descriptorColumna.ancho : this.calculaAnchoColumna(p_columna.ancho),
                v_columna = {
                    text: p_columna.etiqueta,
                    datafield: p_columna.propiedadModelo,
                    align: alineacion,
                    cellsalign: alineacion,
                    type: this.buscaEstiloFormatoDecimales(p_columna) !== "" ? "decimal" : null,
                    cellsformat: this.buscaEstiloFormatoDecimales(p_columna),
                    cellclassname: this.establecerMillaresColumna(p_columna),
                    cellsrenderer: this.buscaEstiloFormatoDecimales(p_columna) !== "" ? this.formatearValue : null
                };

            if (typeof v_formatoCelda !== 'undefined') {
                v_columna['cellsformat'] = v_formatoCelda;
            }
            if (typeof v_anchoColumna !== 'undefined') {
                v_columna['width'] = v_anchoColumna;
            }


            return v_columna;
        });
        this.registros = this._registros;
    }

    private readonly descriptorColumna = {
        'fecha': {
            tipo: 'date',
            formato: 'dd/MM/yyyy',
            ancho: pixeles(`${'dd/MM/yyyy'.length}car`)
        },
    };

    calculaAnchoColumna(p_ancho): string | number | undefined {
        if (typeof p_ancho === 'undefined') {
            return undefined;
        }
        const
            v_ancho: string | number = compruebaFormatoAncho(p_ancho);
        return v_ancho;
    }
    alineacionPorFormato(formato) {
        switch (formato) {
            case 'numero':
                return 'right';
            case 'texto':
                return 'left';
            case 'fecha':
                return 'center';
            default:
                return 'center';
        }
    }

    alineacionesTabla(columna) {
        let alineacion: string;
        if (columna.estilos.length === 0) {
            /** Si no hay array de estilos la alineación se hace por formato */
            alineacion = this.alineacionPorFormato(columna.tipo);
        } else {
            /** Si hay array de estilos se comprueba si tiene algún estilo de alineación
             * y, si no, la alineación se hace por formato
             */
            const v_estilosAlineacion = ['texto-derecha', 'texto-izquierda', 'texto-centrado'];
            alineacion = columna.estilos.map(p_estilo => {
                if (v_estilosAlineacion.includes(p_estilo)) {
                    return p_estilo
                }
            }).join('');
            if (alineacion === '') {
                alineacion = this.alineacionPorFormato(columna.tipo);
            }
        }
        return alineacion;
    }

    formatearValue(row, column, value, defaulthtml, columnproperties, rowdata) {
        let formattedValue: any = value;
        let numeroDecimalesColumna = columnproperties.cellsformat.substr(1, 1);
        formattedValue = formattedValue.toFixed(numeroDecimalesColumna).replace(".", ",");
        if (columnproperties.cellclassname === "millares") {
            formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
        return '<div class="jqx-grid-cell-' + columnproperties.cellsalign + '-align" style="margin-top: 11.5px;">' + formattedValue + '</div>';
    }


    // actualizaEtiqueta(p_indice: number, p_etiqueta: string) {
    //     if (typeof this._grid === 'undefined' || typeof this.columnasGrid === 'undefined') {
    //         throw new Error('Error interno; el componente no se ha inicializado aún.');
    //     }

    // const v_dataField = this.columnasGrid[p_indice].datafield;

    // this.columnasGrid[p_indice].text = p_etiqueta;

    // this._grid.setcolumnproperty(v_dataField, 'text', p_etiqueta);
    // }

    columnasGrid: any[] | undefined;

    // Propiedad 'visible'
    private _visible: boolean | undefined;
    get visible(): boolean | undefined { return this._visible; }
    set visible(p_valor: boolean | undefined) { this._visible = p_valor; }

    @HostBinding('class.oculto')
    get oculto(): boolean {
        if (typeof this._visible === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        return !this._visible;
    }

    // Propiedad 'ancho'
    private _ancho: string | undefined;
    get ancho(): string | undefined { return this._ancho; }
    set ancho(p_valor: string | undefined) { this._ancho = p_valor; }

    get tamanioAncho(): string | number | undefined {
        if (typeof this._ancho === 'undefined') {
            return '100%';
        }

        let v_tamanio: string | number | undefined;
        const v_ancho = this._ancho;
        v_tamanio = compruebaFormatoAncho(v_ancho);
        return v_tamanio;
    }

    // Propiedad 'habilitado'
    private _habilitado: boolean | undefined;
    get habilitado(): boolean | undefined { return this._habilitado; }
    set habilitado(p_valor: boolean | undefined) { this._habilitado = p_valor; }

    @HostBinding('attr.disabled')
    get deshabilitado(): boolean | null {
        if (this._habilitado) {
            return null;
        } else {
            return true;
        }
    }

    // Propiedad 'numeroPagina'
    private _numeroPagina: number | undefined;
    get numeroPagina(): number | undefined {
        return this._numeroPagina;
    }
    set numeroPagina(p_valor: number | undefined) {
        if (p_valor === this._numeroPagina) {
            return;
        }

        this._numeroPagina = p_valor;
        if (typeof this.numeroPaginaCambiada !== 'undefined') {
            this.numeroPaginaCambiada(p_valor);
        }
    }

    numeroPaginaCambiada = (p_valor: any) => { };

    // Propiedad 'registrosPorPagina'
    private _registrosPorPagina: number | undefined;
    get registrosPorPagina(): number | undefined {
        // if (typeof this._registrosPorPagina === 'undefined') {
        //     throw new Error('Error interno; el componente no se ha inicializado aún.');
        // }
        return this._registrosPorPagina;
    }
    set registrosPorPagina(p_valor: number | undefined) {
        if (p_valor === this._registrosPorPagina) {
            return;
        }
        this._registrosPorPagina = p_valor;
        if (typeof this.registrosPorPaginaCambiado !== 'undefined') {
            this.registrosPorPaginaCambiado(p_valor);
        }
    }

    registrosPorPaginaCambiado = (p_valor: any) => { };

    readonly opcionesRegistrosPorPagina = [2, 4, 5, 6, 8, 10, 20, 50];

    get tienePaginacion(): boolean {
        const v_tienePaginacion = typeof this.numeroPagina !== 'undefined' && typeof this.registrosPorPagina !== 'undefined';
        return v_tienePaginacion;
    }

    @ViewChild('jqxgrid', { static: true }) _grid: jqxGridComponent | undefined;
    private _origenDatos: any;
    private _adaptador: any;
    private _idioma: any;
    get idioma(): any { return this._idioma; }
    get adaptador(): any { return this._adaptador; }

    // alCambiarNumeroPagina(event: any): void {
    //     this.numeroPagina = event.args.pagenum + 1;
    // }

    // alCambiarRegistrosPorPagina(event: any): void {
    //     if (typeof this._grid === 'undefined') {
    //         throw new Error('Error interno; el componente no se ha inicializado aún.');
    //     }
    //     this._grid.gotopage(event.args.pagenum);
    //     this.registrosPorPagina = event.args.pagesize;
    // }

    dobleClicSobreCelda(p_evento: any): void {
        const
            v_indiceColumna: number = p_evento.args.columnindex,
            v_indiceFila: number = p_evento.args.rowindex,
            v_clic = this.columnas[v_indiceColumna].alNavegar;
        if (typeof v_clic === 'function') {
            v_clic(v_indiceFila);
        }
    }

    paginaAnterior(p_evento: any): void {
        const v_numeroPagina = this.numeroPagina - 1;
        this.numeroPagina = Math.max(1, v_numeroPagina);
    }

    paginaSiguiente(p_evento: any): void {
        this.numeroPagina = this.numeroPagina + 1;
    }

    formatoDecimal(estilo) {
        let expresionDecimal = /^d\d$/; //d1, d2, d3, ...
        return expresionDecimal.test(estilo);
    }

    buscaEstiloFormatoDecimales(columna) {
        if (columna.estilos.length > 0) {
            let indexFormatoDecimal = columna.estilos.findIndex(estilo => this.formatoDecimal(estilo));
            if (indexFormatoDecimal > -1) {
                return columna.estilos[indexFormatoDecimal];
            } else {
                return "";
            }
        }
        return ""
    }

    establecerMillaresColumna(columna) {
        if (columna.estilos.length > 0) {
            let indexFormatoDecimal = columna.estilos.findIndex(estilo => estilo === "m");
            if (indexFormatoDecimal > -1) {
                return "millares";
            } else {
                return "";
            }
        }
        return ""
    }

    ngOnInit(): void {
        if (typeof this._columnas === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        this._origenDatos = {
            datatype: 'array',
            localdata: this.registros,
            datafields: this._columnas.map(p_columna => {
                const v_descriptorColumna = this.descriptorColumna[p_columna.tipo];
                if (typeof v_descriptorColumna === 'undefined') {
                    return {
                        name: p_columna.propiedadModelo,
                        type: this.buscaEstiloFormatoDecimales(p_columna) !== "" ? "float" : null
                    };
                } else {
                    return {
                        name: p_columna.propiedadModelo,
                        type: this.buscaEstiloFormatoDecimales(p_columna) !== "" ? "float" : null
                    };
                }
            })
        };

        const perfil = this.profileService.activo;
        let idioma = getLocalization(perfil.cultureInfo);
        idioma.emptydatastring = "No hay registros para mostrar";
        // idioma.thousandsseparator = '';
        this._grid.localizestrings(idioma);

        this._adaptador = new jqx.dataAdapter(this._origenDatos);
    }
    ngAfterViewInit(): void {
    }

    // Estilos
    // @HostBinding('class')
    // get estilos() {
    //     if (typeof this.componente === 'undefined') {
    //         throw new Error('Error interno; el componente no se ha inicializado aún.');
    //     }
    //     console.log("ESTILOS TABLA --->>");
    //     console.log(this.componente);
    //     return true;
    //     //  const v_clases = ['campo'].concat(this.componente.estilos);
    //     //  if (this.formato === 'numero') {
    //     //      const v_estilosAlineacion = ['texto-derecha', 'texto-izquierda', 'texto-centrado'];
    //     //      if (!this.componente.estilos.some(p_estilo => v_estilosAlineacion.includes(p_estilo))) {
    //     //          v_clases.push('texto-derecha');
    //     //      }
    //     //  }
    //     //  if (this.oculto) { v_clases.push('oculto'); }
    //     //  if (this.negrita) { v_clases.push('negrita'); }
    //     //  if (this.cursiva) { v_clases.push('cursiva'); }
    //     //  if (this.foco) { v_clases.push('focused'); }
    //     //  if (!this.modificable) { v_clases.push('no-modificable'); }
    //     //  //if (typeof this.ancho !== 'undefined') { v_clases.push(`ancho-${this.ancho}`); }

    //     //  return v_clases.join(' ');
    // }
}
