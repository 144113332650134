<div id="popover-user">
    <jqxPopover #jqxpopover [showCloseButton]="false" [selector]="'.menu-user'" [theme]="'material'"
        [arrowOffsetValue]="115" [offset]="{ left: -116, top:0 }">
        <div class="popover-user distribucion-vertical">
            <div class="popover-header distribucion-horizontal">
                <div class="img-user">
                    <div [class]="['iniciales-usuario', 'car-'+iniciales.length, color].join(' ')">
                        <span>{{iniciales}}</span>
                    </div>
                </div>
                <div class="distribucion-horizontal">
                    <span class="nombre-usuario">{{nombre}}</span>
                    <span>{{gvagente_nombre}}</span>
                </div>
            </div>
            <div class="distribucion-vertical">
                <span>{{emp_nombre}}</span>
                <span>{{empgrupo_nombre}}</span>
                <span>{{idiomas_nombre}}</span>
            </div>
            <div class="distribucion-horizontal">
                <div class="boton con-etiqueta centrado" (click)="cierraSesion()">
                    <span>Cerrar sesión</span>
                </div>
            </div>
        </div>
    </jqxPopover>
</div>
<li><a class="menu-user"><span class="ico-user"></span></a></li>