import { Diccionario } from '../evotec_comun';
import { Accion } from './acciones';

export type DefinicionComponente = Layout | SpyTab | SpyCard | SpyGrid | SpyLabel | SpyInput | SpyButton | SpyCheck | SpyList;

// Componentes
type TipoComponente =
    'layoutVertical'
    | 'layoutHorizontal'
    | 'spyTab'
    | 'spyToolbar'
    | 'spyList'
    | 'spyCard'
    | 'spyLabel'
    | 'spyCheck'
    | 'spyInput'
    | 'spyButton'
    | 'spyGrid';

// Enlaces
export type ModoEnlace = 'unidireccional' | 'bidireccional';

export interface DescriptorCalculo extends Diccionario {
    operacion: string;
}

export interface DescriptorEnlace {
    modelo: string;
    modificable?: boolean;
    modo?: ModoEnlace;
    actualizaModelo?: string | DescriptorCalculo;
    actualizaComponente?: string | DescriptorCalculo;
}

export interface DescriptorPseudoEnlace {
    modelo: string;
}

export function esDescriptorEnlace(p_valor: any | DescriptorEnlace): p_valor is DescriptorEnlace {
    return typeof p_valor === 'object' && p_valor !== null && p_valor.hasOwnProperty('modelo');
}

export function propiedadEstaEnlazada(p_propiedad: boolean | number | string | object | any[] | null | undefined): p_propiedad is DescriptorEnlace {
    return typeof p_propiedad !== 'undefined' && p_propiedad !== null && p_propiedad.hasOwnProperty('modelo');
}

interface ComponenteBase {
    component: TipoComponente;

    estilos?: string[];
    visible?: boolean | DescriptorEnlace;
    habilitado?: boolean | DescriptorEnlace;
    ancho?: string | DescriptorEnlace;
    alineacion?: any;
}

// tslint:disable-next-line: class-name
export interface __Bloque extends ComponenteBase {
    components: ComponenteBase[];
    elemento?: string;
}

export interface Layout extends ComponenteBase {
    components: DefinicionComponente[];
}

export interface SpyTab extends ComponenteBase {
    components: DefinicionComponente[];
    etiqueta?: string | DescriptorEnlace;
}

export interface SpyCard extends ComponenteBase {
    components: DefinicionComponente[];
    etiqueta?: string | DescriptorEnlace;
    icono?: string | DescriptorEnlace;
}

export interface SpyToolbar extends ComponenteBase {
    components: DefinicionComponente[];
}

export interface SpyButton extends ComponenteBase {
    etiqueta?: string | DescriptorEnlace;
    icono?: string | DescriptorEnlace;
    alPulsar?: Accion;
}

// tslint:disable-next-line: no-empty-interface
export interface SpyAgenda extends ComponenteBase {
    registros?: DescriptorEnlace;
}

export interface SpyList extends ComponenteBase {
    registros?: DescriptorEnlace;
    seleccionado?: DescriptorEnlace;
    etiquetaRegistros?: DescriptorPseudoEnlace;
}

export interface SpyGridColumn extends ComponenteBase {
    etiqueta: string | DescriptorEnlace;
    celda: SpyLabel | SpyCheck | SpyInput;
    alNavegar?: Accion;
}

export interface SpyGrid extends ComponenteBase {
    registros?: DescriptorEnlace;
    numeroPagina?: number | DescriptorEnlace;
    registrosPorPagina?: number | DescriptorEnlace;
    registrosTotales?: number | DescriptorEnlace;
    seleccionado?: DescriptorEnlace;

    columnas: SpyGridColumn[];
}

export interface SpyAgenda extends ComponenteBase {
    registros?: DescriptorEnlace;
}
export interface SpyCheck extends ComponenteBase {
    etiqueta?: string | DescriptorEnlace;
    valor?: boolean | DescriptorEnlace;
}

export interface SpyLabel extends ComponenteBase {
    valor: string | DescriptorEnlace;
}

export interface SpyInput extends ComponenteBase {
    etiqueta?: string | DescriptorEnlace;
    valor?: number | string | null | DescriptorEnlace;
    ancho?: string | DescriptorEnlace;
    multilinea?: boolean | DescriptorEnlace;
    lineas?: number | DescriptorEnlace;
    negrita: boolean | DescriptorEnlace;
    cursiva: boolean | DescriptorEnlace;
    alNavegar?: Accion;
    presenta?: 'textArea'; // REVISAR
}

export interface SpyLovInput extends ComponenteBase {
    etiqueta?: string | DescriptorEnlace;
    valor?: any | DescriptorEnlace;
    valorDescripcion?: any | DescriptorEnlace;
    ancho?: string | DescriptorEnlace;
    negrita?: boolean | DescriptorEnlace;
    cursiva?: boolean | DescriptorEnlace;
    alInvocarLov?: Accion;
    alNavegar?: Accion;
}



