<app-cabecera></app-cabecera>

<div class="pagina-area-trabajo">
    <app-menu></app-menu>
    
    <app-contenedor-pestana></app-contenedor-pestana>
</div>

<jqxNotification #jqxnotification [width]="'auto'" [position]="'bottom-right'" [opacity]="0.95" [autoOpen]="false"
    [autoClose]="false" [closeOnClick]="false">
    <div>
        <span id="mensaje-notificacion"></span>
    </div>
</jqxNotification>

<app-contenedor-modal #contenedorModal></app-contenedor-modal>