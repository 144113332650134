<div #listaPrincipalPestanas>
    <div *ngFor="let pestana of pestanas" (click)="activa(pestana)" class="pestana"
        [class.oculta]="!totalmenteVisible(pestana)">
        <a [class.off]="pestanaActiva !== pestana" [class.barrido]="pestana.instance.spinnerActivo"
            [title]="pestana.instance.etiqueta">
            <span>{{pestana.instance.etiqueta}}</span>
            <i class="cierra-pestana icon-close" (click)="cierra(pestana)"></i>
        </a>
    </div>
</div>
<span
    [class]="['boton-lista-pestanas', desborda ? '' : 'boton-lista-pestanas-oculto'].join(' ')">
    <span class='icon-double_arrow_right'>
    </span>
</span>

<jqxPopover #popoverPestanas selector=".boton-lista-pestanas" dropDownHorizontalAlignment="left">
    <ul #listaSecundariaPestanas class="selector-pestanas-lista-secundaria">
        <li *ngFor="let pestana of pestanas;  index as indice" (click)="activaNoVisible(pestana, indice)">
            <span>{{pestana.instance.etiqueta}}</span>
        </li>
    </ul>
</jqxPopover>