<div class="tarjeta-titulo">
    <app-titulo-tarjeta *ngIf="tieneIcono || tieneEtiqueta" #contenedorTitulo [icono]="icono" [etiqueta]="etiqueta"></app-titulo-tarjeta>

    <!-- <div class="tarjeta-titulo-icono-etiqueta">
        <span *ngIf="tieneIcono" [class]="['tarjeta-titulo-icono', icono].join(' ')"></span>
        <h4 *ngIf="conBarraTitulo" class="tarjeta-titulo-etiqueta">
            <span>{{etiqueta}}</span>
        </h4>
    </div> -->

    <ng-container #contenedorOpciones></ng-container>
</div>
<ng-container #contenedorContenido></ng-container>