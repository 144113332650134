/**
 * Clase Activacion.
 */
export class Activacion {
    private ranuras: any[];
    private ranuraBase: number;
    readonly resuelvePromesa: ((p_valor: any) => void) | undefined;
    readonly rechazaPromesa: ((p_motivo: any) => void) | undefined;
    readonly contextoLlamada: any;

    constructor(p_ranuras: any[], p_resuelvePromesa: ((p_valor: any) => void) | undefined, p_rechazaPromesa: ((p_motivo: any) => void) | undefined, p_contextoLlamada: any) {
        this.ranuras = p_ranuras;

        this.ranuraBase = 0;

        this.resuelvePromesa = p_resuelvePromesa;
        this.rechazaPromesa = p_rechazaPromesa;
        this.contextoLlamada = p_contextoLlamada;
    }

    /**
     *
     * @param p_ranura
     * @param p_nuevoValor
     * Devuelve la ranura absoluta segun  la ranura mas la ranura base
     */
    ranura(p_ranura: number, p_nuevoValor?: any): any {
        const v_valor = this.ranuraAbsoluta(this.ranuraBase + p_ranura, p_nuevoValor);
        return v_valor;
    }

    /**
     *
     * @param p_ranura
     * @param p_nuevoValor
     * Si el nuevo valor esta sin definir devuelve
     */
    ranuraAbsoluta(p_ranura: number, p_nuevoValor?: any): any {
        if (typeof p_nuevoValor === 'undefined') {
            return this.ranuras[p_ranura];
        } else {
            return this.ranuras[p_ranura] = p_nuevoValor;
        }
    }

    /**
     *
     * @param p_numeroRanuras
     * @param p_argumentos
     * Crea un nuevo registro de activación ampliando el número de ranuras.
     */
    creaRegistro(p_numeroRanuras: number, p_argumentos: any[]): void {
        const v_nuevasRanuras = p_argumentos
            .concat(new Array(p_numeroRanuras - p_argumentos.length).fill(undefined, 0, p_numeroRanuras));
        this.ranuras = this.ranuras.concat(v_nuevasRanuras);
        this.ranuraBase = this.ranuras.length;
    }

    /**
     *
     * @param p_numeroRanuras
     * Elimina las ranuras indicadas
     */
    eliminaRegistro(p_numeroRanuras: number): void {
        this.ranuras = this.ranuras.slice(0, this.ranuras.length - p_numeroRanuras);
        this.ranuraBase = this.ranuras.length;
    }
}