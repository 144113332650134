import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../core/services/Auth/auth.service';
import { PerfilService } from '../../core/services/Perfilservice';
import { AppConfigService } from '../../core/services/AppConfigService';
import { Observable, Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Perfil } from '../../core/models/Perfil.model';
import { ConfirmacionService } from 'src/app/core/services/ConfirmacionService';

const httpOptions = {
    headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    })
};

@Injectable()
export class LoginService {
    constructor(private http: HttpClient, private router: Router,
        private auth: AuthService,
        private perfilService: PerfilService,
        private appConfigService: AppConfigService,
        private confirmacionService: ConfirmacionService
    ) { }

    companySubscription: Subscription;
    loginSubscription: Subscription;

    // Funciones
    // --- Loguearse
    /**
     * Se envian los datos a la API y devuelve un JSON
     * con token en caso de ser correcto
     */
    public logIn(data: any): Observable<any> {
        const v_url = this.appConfigService.appConfigData.apiUrl + '/Login';
        return this.http.post(v_url, data, httpOptions);
    }
    // Funciones
    // --- Login del usuario
    /**
     * Tal vez haya una forma mejor, pero por ahora sobreescribe el observable anterior
     * por cadavez que se hace el login
     */
    public iniciaSesion(p_formulario: FormGroup): void {
        const dbname = this.appConfigService.appConfigData.tsname === "" ? p_formulario.value.bd : this.appConfigService.appConfigData.tsname;
        const v_usuario = `=${p_formulario.value.nombre}/${p_formulario.value.password}@${dbname}`;
        this.loginSubscription = this.logIn(v_usuario).subscribe(p_respuesta => {
            if (p_respuesta.Ok) {
                return this.loginSuccess(p_respuesta.Objeto);
            }
            if (!p_respuesta.Ok) {
                return this.loginFail(p_respuesta);
            }
        });
    }

    private getCompany(): Observable<any> {
        const v_url = this.appConfigService.appConfigData.apiUrl + '/Usuarios/Actual';
        return this.http.get(v_url, {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                'sid': this.auth.token
            })
        });
    }

    // --- Login correcto
    /**
     * Envía el token al servicio de auth guardándolo y crea el observable que recoge los datos
     * del perfil de la función en el servicio API y los envía a la función setProfile(response) de aquí
     */
    loginSuccess(p_sid: string) {
        this.auth.token = p_sid;

        const companyObservable = this.getCompany();

        this.companySubscription = companyObservable.subscribe(
            p_respuesta => {
                if (p_respuesta.Ok) {
                    this.cargaPerfil(p_respuesta.Objeto);
                }
            }
        );
    }

    // --- Login incorrecto
    /**
     * El usuario no ha insertado bien alguna de las credenciales
     */
    loginFail(respuesta: any) {
        console.log("LOGIN FAIL --->>>");
        console.log(respuesta);
        this.confirmacionService.muestraLogin(respuesta.Mensaje);

    }

    // --- Asignar el perfil
    /**
     * Rellena el perfil enviándosela al servicio PerfilService a la función setProfile(profile: Perfil)
     */
    cargaPerfil(p_perfil: any) {
        const
            v_perfil = new Perfil(
                p_perfil.USUARIO,
                p_perfil.NOMBRE,
                p_perfil.CultureInfo,
                p_perfil.IDIOMAS_COD,
                p_perfil.IDIOMAS_NOMBRE,
                p_perfil.GVAGENTE_COD,
                p_perfil.GVAGENTE_NOMBRE,
                p_perfil.Predeterminada_EMPGRUPO_COD,
                p_perfil.Predeterminada_EMPGRUPO_NOMBRE,
                p_perfil.Predeterminada_EMP_COD,
                p_perfil.Predeterminada_EMP_NOMBRE
            );
        this.perfilService.activo = v_perfil;
        return this.router.navigate(['/Main']);
    }
}
