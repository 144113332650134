import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { jqxMenuComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxmenu';
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { jqxDateTimeInputComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdatetimeinput';
import { jqxSchedulerComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxscheduler';
import { jqxPopoverComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover';
import { jqxDropDownListComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdropdownlist';
import { jqxDropDownButtonComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdropdownbutton';
import { jqxCalendarComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxcalendar';
import { jqxInputComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxinput';
import { jqxTextAreaComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea';
import { jqxNumberInputComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxnumberinput';
import { jqxNotificationComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxnotification';
import { jqxProgressBarComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxprogressbar';
// import { jqxBarGaugeComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxbargauge';

export const CONTAINERS = [
    jqxMenuComponent,
    jqxGridComponent,
    jqxDateTimeInputComponent,
    jqxSchedulerComponent,
    jqxPopoverComponent,
    jqxDropDownListComponent,
    jqxDropDownButtonComponent,
    jqxCalendarComponent,
    jqxInputComponent,
    jqxTextAreaComponent,
    jqxNumberInputComponent,
    jqxNotificationComponent,
    jqxProgressBarComponent,
    // jqxBarGaugeComponent,
];

export const EXPORT_CONTAINERS = [
    jqxMenuComponent,
    jqxGridComponent,
    jqxDateTimeInputComponent,
    jqxSchedulerComponent,
    jqxPopoverComponent,
    jqxDropDownListComponent,
    jqxDropDownButtonComponent,
    jqxCalendarComponent,
    jqxInputComponent,
    jqxTextAreaComponent,
    jqxNumberInputComponent,
    jqxNotificationComponent,
    jqxProgressBarComponent,
    // jqxBarGaugeComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: EXPORT_CONTAINERS,
    exports: EXPORT_CONTAINERS,
    providers: [],
    entryComponents: CONTAINERS
})
export class JqwidgetsModule { }
