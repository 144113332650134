import { Component, HostBinding, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, Renderer2, ɵConsole, AfterViewChecked } from '@angular/core';
import { getLocalization } from './localization';
import { PerfilService } from '../../services/Perfilservice';
import { jqxTextAreaComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea';
import { jqxDateTimeInputComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdatetimeinput';
import { jqxInputComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxinput';
import { jqxNumberInputComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxnumberinput';
import { jqxDropDownButtonComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdropdownbutton';
import { Variable } from '../../abstracto/acciones/variables';
import { formateaHora, pixelesTexto, compruebaFormatoAncho, igual, valida, formateaFechaPresentacion } from '../../abstracto/evotec_comun';
import { ComponenteCampo } from '../../abstracto/ejecucion/componentes';
import { Modelo, InstanciaModelo, InstanciaObjeto } from '../../abstracto/ejecucion/modelo';
import { BaseComponenteAngular } from '../UtilidadesComponentes';
import { Configuracion } from '../../abstracto/ejecucion/configuracion';
import { EventosService } from '../../services/EventosService';
import { ResultadoAccion } from '../../abstracto/acciones/evotec_acciones';
import { jqxPopoverComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover';

@Component({
    selector: 'app-campo',
    templateUrl: './campo.component.html',
    styleUrls: []
})
export class CampoComponent implements BaseComponenteAngular<ComponenteCampo>, OnInit, OnDestroy, AfterViewInit {

    private static _id = 0;
    id: string;

    constructor(private readonly profileService: PerfilService,
        private eventosService: EventosService,
        private renderer: Renderer2
    ) {
        this.id = `campo-${CampoComponent._id++}`;
    }

    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_fila: number,
        p_componente: ComponenteCampo,
        p_modeloInvocante: Variable): void {

        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    definicionModelo: Modelo | undefined;
    instanciaModelo: InstanciaModelo | undefined;
    modeloInvocante: Variable | undefined;
    fila: number | undefined;

    componente: ComponenteCampo | undefined;

    @ViewChild('jqxinput', { read: jqxInputComponent }) set jqxinput(p_input: jqxInputComponent) {
        if (typeof p_input !== 'undefined') {
            if (this.modificable) {
                p_input.elementRef.nativeElement.querySelector('input').removeAttribute('disabled');
            } else {
                p_input.elementRef.nativeElement.querySelector('input').setAttribute('disabled', 'disabled');
            }
            // p_input.elementRef.nativeElement.querySelector('input').readOnly = !this.modificable;
        }
    }
    // @ViewChild('jqxnumberinput') jqxnumberinput: jqxInputComponent | undefined;
    @ViewChild('jqxnumberinput', { read: jqxInputComponent }) set jqxnumberinput(p_numberInput: jqxInputComponent) {
        if (typeof p_numberInput !== 'undefined') {
            if (this.modificable) {
                p_numberInput.elementRef.nativeElement.querySelector('input').removeAttribute('disabled');
            } else {
                p_numberInput.elementRef.nativeElement.querySelector('input').setAttribute('disabled', 'disabled');
            }
            // p_numberInput.elementRef.nativeElement.querySelector('input').readOnly = !this.modificable;
        }
    }

    // @ViewChild('jqxinput', { static: false })
    // _input: jqxInputComponent | undefined;

    @ViewChild('jqxtextarea') _textArea: jqxTextAreaComponent | undefined;
    @ViewChild('jqxdatetimeinput') _dateTimeInput: jqxDateTimeInputComponent | undefined;

    @ViewChild('dropDownButton', { read: jqxPopoverComponent }) dropDownButton: jqxPopoverComponent;

    formatoComponente: any;

    format: string = 'd';
    localization: any;
    @Input() firstDay: number = 0;

    // Propiedad 'etiqueta'
    private _etiqueta: string | undefined;
    get etiqueta(): string | undefined { return this._etiqueta; }
    set etiqueta(p_valor: string | undefined) { this._etiqueta = p_valor; }

    // Propiedad 'valor'
    private _valor: string | number | Date | undefined;
    get valor(): string | number | Date | undefined {
        return this._valor;
    }
    set valor(p_valor: string | number | Date | undefined) {
        if (igual(p_valor, this._valor)) {
            return;
        }

        this._valor = p_valor;
        this.refrescaValorComponentes();

        if (typeof this.valorCambiado !== 'undefined') {
            this.eventosService.capturaEvento(() => this.valorCambiado(p_valor));
        }
    }

    valorDateTimeInput: Date;
    valorDateTimeInputFormateado: string; // en formato de presentación

    valorWorkTime: number; // horas trabajadas

    valorNumberInput: string;

    obtenerHorasTrabajadas() {
        if (this.etiqueta === 'Horas trabajadas') {
            return this.valor;
        }
    }

    refrescaValorComponentes() {
        if (this.formato === 'fecha' || this.formato === 'fechaHora') {
            /** REVISAR BIEN!!! - si la fecha viene vacía */
            let v_fecha = null
            if (this._valor !== null) {
                valida(typeof this._valor === 'string' || typeof this._valor === 'undefined', `Se esperaba un valor tipo 'string' pero se obtuvo un ${typeof this._valor}`);
                v_fecha = Configuracion.formatoFecha.fecha(this._valor);
            }
            this.valorDateTimeInput = v_fecha;
            this.valorDateTimeInputFormateado = formateaFechaPresentacion(v_fecha);
        } else if (this.formato === 'hora') {
            valida(typeof this._valor === 'string' || typeof this._valor === 'undefined', `Se esperaba un valor tipo 'string' pero se obtuvo un ${typeof this._valor}`);
            if (this._valor !== '') {
                const
                    v_fecha = new Date(0),
                    v_segundos = this._valor
                        .split(':')
                        .reverse()
                        .reduce((prev, curr, i, array) => prev + parseInt(curr, 10) * Math.pow(60, i + 3 - array.length), 0) +
                        v_fecha.getTimezoneOffset() * 60;
                v_fecha.setTime(v_segundos * 1000);
                return v_fecha;
            }
        } else if (this.formato === 'numero') {
            let numeroDecimales = this.establecerNumeroDecimales(this.componente.estilos);
            let millares: boolean = this.componente.estilos.includes("m");
            if (millares) {
                this.valorNumberInput = Number(this._valor).toLocaleString(undefined, { minimumFractionDigits: numeroDecimales });
            } else {
                this.valorNumberInput = Number(this._valor).toFixed(numeroDecimales);
                this.valorNumberInput = this.valorNumberInput.replace(".", ",");
            }
        }
    }


    alCambiarValorInput(p_evento: any) {
        valida(this.formato === 'texto', `El campo no es de tipo 'texto'.`);
        if (this.formato === 'texto') {
            this.valor = p_evento.target.value;
        }
    }

    alCambiarValorNumberInput(p_evento: any) {
        valida(this.formato === 'numero', `El campo no es de tipo 'numero'.`);
        if (this.formato === 'numero') {
            let valorCambiado = p_evento.target.value;
            valorCambiado = valorCambiado.replace(",", ".")
            this.valor = parseFloat(valorCambiado);
        }
    }

    alCambiarValorTextArea(p_evento: any) {
        if (typeof this._textArea === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        this.valor = this._textArea.val();
    }

    alCambiarValorDateTimeInput(p_evento: any) {
        valida(this.formato === 'fecha' || this.formato === 'fechaHora' || this.formato === 'hora', `El campo no es de tipo 'fecha', 'fechaHora' ni 'hora'.`);
        const v_fecha = p_evento.args.date;
        if (this.formato === 'fecha' || this.formato === 'fechaHora') {
            this.valor = Configuracion.formatoFecha.formateaFecha(p_evento.args.date);
        } else if (this.formato === 'hora') {
            this.valor = formateaHora(v_fecha);
        }

        if (typeof this.dropDownButton !== 'undefined' && this.dropDownButton !== null) {
            this.dropDownButton.close();
        }
    }

    refrescaValor(p_valor: string | number | Date | undefined) {
        if (igual(p_valor, this._valor)) {
            return;
        }
        if (this._textArea && this.formato === 'texto') {
            this._textArea.val(p_valor as string);
        } else if (this._dateTimeInput && (this.formato === 'fecha' || this.formato === 'hora' || this.formato === 'fechaHora')) {
            // if (typeof p_valor !== 'undefined') {
            //     this._dateTimeInput.setDate(Configuracion.formatoFecha.fecha(p_valor as string));
            // } else {
            //     this._dateTimeInput.setDate(null);
            // }
        }
        this._valor = p_valor;
        this.refrescaValorComponentes();
    }

    valorCambiado: (p_valor: any) => ResultadoAccion = (p_valor) => { };

    private _modificable: boolean | undefined;

    get modificable(): boolean | undefined { return this._modificable; }
    set modificable(p_valor: boolean | undefined) { this._modificable = p_valor; }

    @Input() get soloLectura(): boolean | null {
        if (this._modificable) {
            return null;
        } else {
            return true;
        }
    }

    private _formato: 'texto' | 'numero' | 'fecha' | 'hora' | 'fechaHora' | undefined;
    get formato(): 'texto' | 'numero' | 'fecha' | 'hora' | 'fechaHora' | undefined {
        return this._formato;
    }
    set formato(p_valor: 'texto' | 'numero' | 'fecha' | 'hora' | 'fechaHora' | undefined) {
        this._formato = p_valor;
    }

    // Propiedad 'visible'
    private _visible = true;
    get visible(): boolean { return this._visible; }
    set visible(p_valor: boolean) { this._visible = p_valor; }

    //@HostBinding('class.oculto')
    get oculto(): boolean {
        return !this._visible;
    }

    // Propiedad 'habilitado'
    private _habilitado = true;
    get habilitado(): boolean { return this._habilitado; }
    set habilitado(p_valor: boolean) { this._habilitado = p_valor; }

    @HostBinding('attr.disabled')
    get deshabilitado(): boolean | null {
        if (this._habilitado) {
            return null;
        } else {
            return true;
        }
    }

    // Propiedad 'negrita'
    private _negrita: boolean | undefined;
    //@HostBinding('class.negrita')
    get negrita(): boolean | undefined { return this._negrita; }
    set negrita(p_valor: boolean | undefined) { this._negrita = p_valor; }

    // Propiedad 'cursiva'
    private _cursiva: boolean | undefined;
    get cursiva(): boolean | undefined { return this._cursiva; }
    set cursiva(p_valor: boolean | undefined) { this._cursiva = p_valor; }

    // Propiedad 'multilinea'
    private _multilinea: boolean | undefined;
    get multilinea(): boolean | undefined { return this._multilinea; }
    set multilinea(p_valor: boolean | undefined) { this._multilinea = p_valor; }

    // Propiedad '_lineas'
    private _lineas: number | undefined;
    get lineas(): number | undefined { return this._lineas; }
    set lineas(p_valor: number | undefined) { this._lineas = p_valor; }

    // Propiedad 'ancho'
    private _ancho: string | undefined;
    get ancho(): string | undefined { return this._ancho; }
    set ancho(p_valor: string | undefined) { this._ancho = p_valor; }

    // get pixelesAncho(): string | number | undefined {
    //     if (this.formato === 'texto' && typeof this._ancho === 'undefined') {
    //         return '100%';
    //     }

    //     let v_pixelesEtiqueta: number;
    //     if (typeof this._etiqueta === 'string') { // Comprueba que la etiqueta es de tipo texto.
    //         v_pixelesEtiqueta = pixelesTexto(this._etiqueta) * 1.2; // le da un tamaño a la etiqueta en pixeles multiplicado por 1.2
    //     } else {
    //         v_pixelesEtiqueta = 0; // Si no es string no pone nada
    //     }
    //     let v_pixeles: number;
    //     const v_ancho = typeof this._ancho === 'undefined' ? '10car' : this._ancho; // Si el ancho es undefined pone por defecto 10car

    //     v_pixeles = pixeles(v_ancho); // Devuelve el ancho en pixeles para comprobarlo
    //     return Math.max(Math.max(v_pixelesEtiqueta, v_pixeles), 100); // Devuelve el maximo entre la etiqueta y el ancho.
    // }

    calculaTamanioEtiqueta(): number {
        let v_pixelesEtiqueta: number;
        if (typeof this._etiqueta === 'string') { // Comprueba que la etiqueta es de tipo texto.
            v_pixelesEtiqueta = pixelesTexto(this._etiqueta) * 1.2; // le da un tamaño a la etiqueta en pixeles multiplicado por 1.2
        } else {
            v_pixelesEtiqueta = 0; // Si no es string no pone nada
        }
        return v_pixelesEtiqueta;
    }

    get tamanioAncho(): string | number | undefined {
        if (this.formato === 'texto' && typeof this._ancho === 'undefined') {
            return '100%';
        }

        let v_tamanio: string | number | undefined;
        const v_ancho = typeof this._ancho === 'undefined' ? '10car' : this._ancho;
        v_tamanio = compruebaFormatoAncho(v_ancho);
        if (typeof v_tamanio === 'number') {
            // Comprueba tamanio de etiqueta y devolver el máximo
            const v_tamanioEtiqueta: number = this.calculaTamanioEtiqueta();
            v_tamanio = Math.max(v_tamanioEtiqueta, v_tamanio);
        }
        return v_tamanio;
    }

    // onValueChanged(event: any): void {
    //     const date: Date = event.args.date;
    //     if (date) {
    //         switch (this.formato) {
    //             case 'fecha':
    //                 this._valor = date.toLocaleDateString();
    //                 this.valorCambiado(this._valor);
    //                 break;
    //             case 'hora':
    //                 this._valor = date.toLocaleTimeString();
    //                 this.valorCambiado(this._valor);
    //                 break;
    //         }
    //     }
    // }

    // establecerFormatoFechaHora() {
    //     this.firstDay = this.localization.firstDay;

    //     switch (this.formato) {
    //         case 'fecha':
    //             console.log("FORMATO FEHCA ")
    //             // this.format = this.localization.patterns.d;
    //             // this.showTimeIcon = false;
    //             // this.showCalendarIcon = true;
    //             break;
    //         case 'hora':
    //             this.format = this.localization.patterns.T;
    //             // this.showTimeIcon = true;
    //             // this.showCalendarIcon = false;
    //             break;
    //         //   case 'datetime':
    //         //     this.format =  this.localization.patterns.F;
    //         //     this.showTimeIcon = true;
    //         //     this.showCalendarIcon = true;
    //         //     break;
    //         default:
    //             this.format = this.localization.patterns.d;
    //             // this.showTimeIcon = false;
    //             // this.showCalendarIcon = true;
    //             break;
    //     }
    // }

    // Controlamos el foco del campo
    // Si el foco está en el campo añadimos clase 'focused'
    //@HostBinding('class.focused') 
    foco: boolean = false;

    focusFunction() {
        this.foco = true;
    }

    focusOutFunction() {
        if (this.valor === '' || null) this.foco = false;
    }

    // Estilos
    @HostBinding('class')
    get estilos() {
        if (typeof this.componente === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        const v_clases = ['campo'].concat(this.componente.estilos);
        if (this.formato === 'numero') {
            const v_estilosAlineacion = ['texto-derecha', 'texto-izquierda', 'texto-centrado'];
            if (!this.componente.estilos.some(p_estilo => v_estilosAlineacion.includes(p_estilo))) {
                v_clases.push('texto-derecha');
            }
        }
        if (this.oculto) { v_clases.push('oculto'); }
        if (this.negrita) { v_clases.push('negrita'); }
        if (this.cursiva) { v_clases.push('cursiva'); }
        if (this.foco) { v_clases.push('focused'); }
        if (!this.modificable) { v_clases.push('no-modificable'); }
        //if (typeof this.ancho !== 'undefined') { v_clases.push(`ancho-${this.ancho}`); }

        return v_clases.join(' ');
    }

    // Estilos cuando el campo es una etiqueta
    get estilosCampoEsEtiqueta() {
        if (typeof this.componente === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        if (Array.isArray(this.componente.estilos)) {
            return this.componente.estilos
                .filter(p_estilo => p_estilo.startsWith('etiqueta-'))
                .map(p_estilo => p_estilo.substr('etiqueta-'.length))
                .join(' ');
        } else {
            return [];
        }
    }

    get campoEsEtiqueta() {
        if (typeof this.componente === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        if (!Array.isArray(this.componente.estilos)) {
            return false;
        } else {
            return this.componente.estilos.includes('campo-es-etiqueta');
        }
    }

    formatoDecimal(estilo) {
        let expresionDecimal = /^d\d$/; //d1, d2, d3, ...
        return expresionDecimal.test(estilo);
    }

    establecerNumeroDecimales(estilos) {
        if (estilos.length > 0) {
            let indexFormatoDecimal = estilos.findIndex(estilo => this.formatoDecimal(estilo));
            if (indexFormatoDecimal > -1) {
                return estilos[indexFormatoDecimal].substr(1, 1);
            } else {
                return 0;
            }
        }
        return 0;
    }

    ngOnInit(): void {
        console.warn(`Creando ${this.id}`);

        this._multilinea = this.componente.multilinea;
        if (this._multilinea) {
            this._lineas = Math.max(5, this.componente.lineas);
        } else {
            this._lineas = this.componente.lineas;
        }

        // Inicializar propiedades
        this.formatoComponente = this.formato;
        // this.valorComponente = this.valor;
        if (this.valor !== null) this.foco = true;
        this.refrescaValorComponentes();
    }

    ngAfterViewInit(): void {
        // Cargar perfil para obtener la localización
        // y establecer los formatos de la fecha y la hora
        const perfil = this.profileService.activo;
        this.localization = getLocalization(perfil.cultureInfo);
        // this.establecerFormatoFechaHora();

        if (typeof this._textArea !== 'undefined') {
            if (typeof this._valor === 'string') {
                this._textArea.val(this._valor);
            } else {
                this._textArea.val(null);
            }
        }
        if (typeof this._dateTimeInput !== 'undefined') {
            // if (this.formato === 'fecha' || this.formato === 'hora' || this.formato === 'fechaHora') {
            //     this._dateTimeInput.setDate(Configuracion.formatoFecha.fecha(this.valorDateTimeInput as string));
            // } else {
            //     this._dateTimeInput.setDate(null);
            // }
        }

        if (typeof this.dropDownButton !== 'undefined' && this.dropDownButton !== null) {
            //this.dropDownButton.setContent('<span class="icono-calendario"><span class="icon-today"></span></span>');
            // this.dropDownButton.setContent('');
        }
        if (typeof this._valor === 'number') {
            let numeroDecimales = this.establecerNumeroDecimales(this.componente.estilos);
            let millares: boolean = this.componente.estilos.includes("m");
            if (millares) {
                this.valorNumberInput = Number(this._valor).toLocaleString(undefined, { minimumFractionDigits: numeroDecimales });
            } else {
                this.valorNumberInput = this._valor.toFixed(numeroDecimales);
                this.valorNumberInput = this.valorNumberInput.replace(".", ",");
            }
        }
    }

    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
    }
}
// REGISTRY.set('campo', CampoComponent);
