import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from './components/components.module';
import { MainModule } from './main/main.module';

export const CONTAINERS = [


];

export const EXPORT_CONTAINERS = [

];

export const EXPORT_PROVIDERS = [
];

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    MainModule
  ],
  declarations: EXPORT_CONTAINERS,
  exports: EXPORT_CONTAINERS,
  providers: EXPORT_PROVIDERS,
  entryComponents: CONTAINERS
})
export class CoreModule { }
