import { Injectable, ComponentRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, PartialObserver, Subscription } from 'rxjs';
import { BaseComponenteAngular } from '../components/UtilidadesComponentes';
import { PestanaComponent } from '../components/pestana/pestana.component';

@Injectable({
    providedIn: 'root'
})
export class ModalService implements OnDestroy {
    private _componente = new BehaviorSubject<ComponentRef<BaseComponenteAngular>>(null);
    private resuelve: (p_valor: boolean) => void;

    readonly alAbrirModalGlobal = new BehaviorSubject<[ComponentRef<BaseComponenteAngular>, (p_valor: boolean) => void]>([null, null]);

    ngOnDestroy(): void {
        this.alAbrirModalGlobal.unsubscribe();
    }

    private get estadoModal(): ComponentRef<BaseComponenteAngular> {
        return this._componente.getValue();
    }

    private set estadoModal(v: ComponentRef<BaseComponenteAngular>) {
        this._componente.next(v);
    }

    abreModal(p_componente: ComponentRef<BaseComponenteAngular>, p_resuelve: (p_valor: boolean) => void, p_pestanaActiva: ComponentRef<PestanaComponent>) {
        // this._componente.next(p_componente);
        // this.resuelve = p_resuelve;

        if (p_pestanaActiva && typeof p_pestanaActiva.instance) {
            (p_pestanaActiva as ComponentRef<PestanaComponent>).instance.abreModal(p_componente, p_resuelve);
        } else {
            this.alAbrirModalGlobal.next([p_componente, p_resuelve]);
        }
    }

    cierraModal(p_ok: boolean) {
        this._componente.next(null);
        this.resuelve(p_ok);
    }

    get modalAbierto(): boolean {
        return this.estadoModal !== null;
    }

    subscribeAbre(p_observador: PartialObserver<ComponentRef<BaseComponenteAngular>>) {
        return this._componente.subscribe(p_observador);
    }
}
