import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PestanaService } from '../PestanaService';
import { PerfilService } from '../Perfilservice';
import { Subscription } from 'rxjs';

@Injectable()
export class AuthService implements OnDestroy {
    private alCambiarPerfil: Subscription;

    constructor(
        private router: Router,
        private pestanaService: PestanaService,
        private perfilService: PerfilService
    ) {
        this.alCambiarPerfil = this.perfilService.alCambiar.subscribe(
            p_perfil => {
                if (p_perfil === null) {
                    this.cierraSesion();
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.alCambiarPerfil.unsubscribe();
    }

    get token() {
        return localStorage.getItem('sid');
    }

    set token(token: string) {
        localStorage.setItem('sid', token);
    }

    get sesionAbierta() {
        return this.token !== null;
    }

    cierraSesion() {
        this.pestanaService.cierraTodas();
        localStorage.removeItem('sid');
        this.router.navigate(['login']);
    }
}
