import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../services/Auth/auth.service';
import { PerfilService } from '../../../services/Perfilservice';

@Component({
    selector: 'app-perfil',
    templateUrl: './perfil.component.html'
})

export class PerfilComponent implements OnInit {
    constructor(
        private readonly profileService: PerfilService,
        private auth: AuthService
    ) { }

    emp_cod: string;
    emp_nombre: string;
    nombre: string;
    empgrupo_cod: string;
    empgrupo_nombre: string;
    idiomas_cod: string;
    idiomas_nombre: string;
    gvagente_cod: string;
    gvagente_nombre: string;
    iniciales: string;
    color: string;

    @ViewChild('jqxpopover') jqxPopover;

    // Funciones
    /**
     * se subscribe a profileSubscription de spyProfileSandbox y si el estado 
     * recibido es OK sobreescribe los datos del perfil de este componente
     */
    ngOnInit() {
        const v_perfil = this.profileService.activo;
        if (v_perfil === null) {
            throw new Error('Error interno; no hay un perfil activo.');
        }
        this.emp_cod = v_perfil.emp_cod;
        this.emp_nombre = v_perfil.emp_nombre;
        this.nombre = v_perfil.nombre;
        this.empgrupo_cod = v_perfil.empgrupo_cod;
        this.empgrupo_nombre = v_perfil.empgrupo_nombre;
        this.idiomas_cod = v_perfil.idiomas_cod;
        this.idiomas_nombre = v_perfil.idiomas_nombre;
        this.gvagente_cod = v_perfil.gvagente_cod;
        this.gvagente_nombre = v_perfil.gvagente_nombre;
        this.iniciales = v_perfil.iniciales;
        this.color = v_perfil.color;
    }

    cierraSesion() {
        this.jqxPopover.close();

        setTimeout(() => this.auth.cierraSesion(), 0);
    }
}
