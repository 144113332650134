import { Enlace, Modelo, Cambio, cambios, InstanciaModelo, InstanciaObjeto, PropiedadObjeto } from './ejecucion/modelo';
import { tiposPredefinidos } from './especificacion/tipos';
import { igual } from './evotec_comun';
import { ResultadoAccion } from './acciones/evotec_acciones';

// devuelve una función que actualizará el modelo y registrará los cambios realizados a raiz de un cambio llevado a cabo en la vista
export function actualizadorModelo(
    p_definicionModelo: Modelo,
    p_instanciaModelo: InstanciaObjeto,
    p_enlace: Enlace,
    p_valorComponente: () => any,
    p_fila: number,
    p_contextoLlamada: any
): ((p_valor: any) => ResultadoAccion) | ((p_valorComponente: any) => ResultadoAccion) {

    if (typeof p_contextoLlamada.pestana !== 'undefined') {
        console.log('!undefined');
    } else {
        console.log('undefined');
    }

    if (typeof p_enlace === 'undefined') {
        return () => { };
    }

    const v_contextoLlamada = p_contextoLlamada;

    const v_actualizador = (p_valorComponente: any) => {
        const
            v_propiedad = p_definicionModelo.damePropiedad(p_enlace.propiedad),
            v_nombreTipo = v_propiedad.tipo,
            v_tipo = tiposPredefinidos[v_nombreTipo];
        let v_valor: any;
        if (typeof v_tipo !== 'undefined') {
            v_valor = v_tipo.desdeTexto(p_valorComponente);
        } else {
            v_valor = p_valorComponente;
        }

        const v_transforma = p_enlace.funcionTransforma(p_enlace.actualizaModelo);
        let v_nuevo = p_enlace.transformaValor(v_valor, p_valor => v_transforma(p_enlace.definicionPropiedad.valorInstancia(p_valor)));
        const
            v_valores = p_instanciaModelo.valores,
            v_anterior = v_valores[p_enlace.propiedad];

        if (v_propiedad instanceof PropiedadObjeto) {
            v_nuevo = new InstanciaObjeto(v_nuevo);
            v_nuevo.padres = (v_valores[p_enlace.propiedad] as InstanciaObjeto).padres;
        }
        if (!igual(v_nuevo, v_anterior)) {
            v_valores[p_enlace.propiedad] = v_nuevo;

            const v_cambio = new Cambio(p_definicionModelo, p_instanciaModelo, p_enlace.propiedad, p_fila, v_nuevo, v_anterior, v_contextoLlamada);
            cambios.push(v_cambio);
            return cambios.aplica();
        }
    };

    if (typeof p_valorComponente === 'undefined') {
        return v_actualizador;
    } else {
        return p_valor => v_actualizador(p_valorComponente());
    }
}
