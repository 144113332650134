import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CapturaFocoService {
    private idEvento: number = 0;

    private actual: HTMLElement = null;

    private focusinDocument: (p_evento: MouseEvent) => void = null;
    private keydownDocument: (p_evento: KeyboardEvent) => void = null;

    // Captura el foco en un elemento
    captura(p_elemento: HTMLElement) {
        // Registro el evento focusin del objeto document
        if (this.focusinDocument !== null) {
            document.removeEventListener('focusin', this.focusinDocument);
            this.focusinDocument = null;
        }
        this.focusinDocument = (p_evento) => {
            // Cuando el elemento que toma el foco está dentro del elemento capturado, establezco como actual el elemento que recibe el foco
            if (p_elemento.contains(p_evento.target as Node)) {
                this.actual = p_evento.target as HTMLElement;
                console.log(`[${this.idEvento++}] CapturaFoco: foco en ${this.actual.nodeName}`);
            }
            p_evento.preventDefault();
        };
        document.addEventListener('focusin', this.focusinDocument, true);

        // Registro el evento keydown del objeto document
        if (this.keydownDocument !== null) {
            document.removeEventListener('keydown', this.keydownDocument);
            this.keydownDocument = null;
        }
        this.keydownDocument = (p_evento) => {
            // Cuando la pulsación es Tab (o Shift + Tab) y el elemento key la recibe está dentro del elemento capturado, se establece
            // el foco en el siguiente elemento
            // Por contra, si el elemento está fuera del elemento capturado se da el foco al último elemento dentro de la captura que
            // tuviera el foco.
            if (p_evento.code === 'Tab') {
                if (p_elemento.contains(p_evento.target as Node)) {
                    const v_accesibles = this.elementosAccesibles(p_elemento);
                    let v_nuevoElemento: HTMLElement;
                    if (p_evento.shiftKey) {
                        v_nuevoElemento = this.calculaAnteriorElemento(p_evento.target as HTMLElement, v_accesibles);
                    } else {
                        v_nuevoElemento = this.calculaSiguienteElemento(p_evento.target as HTMLElement, v_accesibles);
                    }
                    v_nuevoElemento.focus();
                    p_evento.preventDefault();
                } else if (this.actual !== null) {
                    this.actual.focus();
                    p_evento.preventDefault();
                }
            }
        };
        document.addEventListener('keydown', this.keydownDocument, true);

        // Al iniciar la captura de un elemento se establece el foco en su primer elemento
        const v_accesibles = this.elementosAccesibles(p_elemento);
        if (v_accesibles.length > 0) {
            v_accesibles[0].focus();
        }
    }

    // Relación de elementos accesibles dentro del elemento
    private elementosAccesibles(p_elemento: HTMLElement) {
        const v_accesibles = Array.from(p_elemento.querySelectorAll<HTMLElement>('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled])'));
        return v_accesibles;
    }

    // Calcula el indice del elemento dentro de la lista de elementos accesibles
    private calculaIndiceElemento(p_elemento: HTMLElement, p_accesibles: HTMLElement[]) {
        const v_indice = p_accesibles.findIndex(p => p === p_elemento);
        return v_indice;
    }

    // Calcula el siguiente elemento accesible
    private calculaSiguienteElemento(p_elemento: HTMLElement, p_accesibles: HTMLElement[]) {
        const
            v_indice = this.calculaIndiceElemento(p_elemento, p_accesibles),
            v_indiceSiguiente = (v_indice + 1) % p_accesibles.length,
            v_siguiente = p_accesibles[v_indiceSiguiente];
        return v_siguiente;
    }

    // Calcula el anterior elemento accesible
    private calculaAnteriorElemento(p_elemento: HTMLElement, p_accesibles: HTMLElement[]) {
        const v_indice = this.calculaIndiceElemento(p_elemento, p_accesibles);
        let v_indiceAnterior: number;
        if (v_indice === 0) {
            v_indiceAnterior = p_accesibles.length - 1;
        } else {
            v_indiceAnterior = (v_indice - 1) % p_accesibles.length;
        }
        const v_anterior = p_accesibles[v_indiceAnterior];
        return v_anterior;
    }

    // Libera la captura
    libera() {
        if (this.focusinDocument === null || this.keydownDocument) {
            console.warn('Operación no válida; no se ha iniciado una captura.');
            return;
            // throw new Error('Operación no válida; no se ha iniciado una captura.');
        }
        document.removeEventListener('focusin', this.focusinDocument);
        document.removeEventListener('keydown', this.keydownDocument);
    }
}
