// TODO: BRF tab component
/**
 * NOTAS: Los tabs ahora se guardan en un array dentro de tab en redux, pero 
 * la posición recogida para la tabla siempre es la 0. Esa parte hay que cambiarla
 */
import { OnInit, Component, ViewChild, ViewContainerRef, OnDestroy, HostBinding } from '@angular/core';
import { Subscription } from 'rxjs';
import { PestanaService } from '../../services/PestanaService';
import { ModalService } from '../../services/TarjetaService';

@Component({
    selector: 'app-contenedor-pestana',
    templateUrl: './pestana-contenido.component.html'
})
export class PestanaContenidoComponent implements OnInit, OnDestroy {
    private pestanaService: PestanaService;
    private modalService: ModalService;

    constructor(p_pestanaService: PestanaService, p_modalService: ModalService) {
        this.pestanaService = p_pestanaService;
        this.modalService = p_modalService;
    }

    subscripcionPestanaActiva: Subscription;
    mostrarDashboard: boolean = false;

    @HostBinding('@.disabled') disabled = true
    @ViewChild('contenedorContenido', { read: ViewContainerRef, static: true }) contenedorContenido: ViewContainerRef;

    ngOnInit() {
        this.subscripcionPestanaActiva = this.pestanaService.subcribeActiva({
            next: p_pestana => {
                while (this.contenedorContenido.length > 0) {
                    this.contenedorContenido.detach();
                }

                if (p_pestana !== null) {
                    this.contenedorContenido.insert(p_pestana.hostView);
                    this.mostrarDashboard = false;
                } else {
                    this.mostrarDashboard = true;
                }
            }
        });
    }

    ngOnDestroy() {
        this.subscripcionPestanaActiva.unsubscribe();
    }
}
