import { valida } from '../abstracto/evotec_comun';
import { Injectable, ViewContainerRef, ApplicationRef } from '@angular/core';
import { AppComponent } from 'src/app/app.component';


@Injectable({
    providedIn: 'root'
})
export class ViewContainerRefGlobalService {
    constructor(
        private readonly referenciaAplicacion: ApplicationRef
    ) {
    }

    get viewContainerRef(): ViewContainerRef {
        const v_aplicacion = this.referenciaAplicacion.components[0].instance;

        valida(v_aplicacion instanceof AppComponent);
        valida(v_aplicacion.viewContainerRef, `'viewContainerRef' no está definido en ${this.referenciaAplicacion.componentTypes[0].name}`);

        return v_aplicacion.viewContainerRef;
    }
}
