import { Diccionario, formatoFechaDDMMYYYY, formatoFechaDDMMYYYY_HHMMSS, formatoFechaYYYYMMDD, formatoFechaYYYYMMDD_HHMMSS } from '../evotec_comun';
import { Activacion } from '../acciones/activacion';
import { RespuestaApi } from '../acciones/evotec_acciones';
import { Variable } from '../acciones/variables';
import { Definicion } from '../especificacion/interfazUsuario';
import { Modelo } from './modelo';

type AccionBase = (p_parametros: Diccionario, p_activacion: Activacion, p_modelo: Modelo, p_valida: (p_respuesta: RespuestaApi, p_modelo: Modelo) => Promise<any>) => Promise<any>;

type Navega = (p_modeloInvocante: Variable | undefined, p_definicionLogica: Definicion, p_contextoLlamada: any) => void;

export type CierraModal = (p_contextoLlamada: any) => void;

type Modal = (p_modeloInvocante: Variable | undefined, p_definicionLogica: Definicion, p_contextoLlamada: any) => Promise<boolean>;

type Cierra = (p_contextoLlamada: any) => void;
type Informa = (p_mensaje: string) => void;

interface Opciones {
    accionBase?: AccionBase;
    navega?: Navega;
    modal?: Modal;
    cierraModal?: CierraModal;
    cierra?: Cierra;
    informa?: Informa;
}

export class Configuracion {
    // formato de fecha interno
    // static readonly formatoFecha =  formatoFechaYYYYMMDD;
    // static readonly formatoFechaHora =  formatoFechaYYYYMMDD_HHMMSS;
    static readonly formatoFecha =  formatoFechaDDMMYYYY;
    static readonly formatoFechaHora =  formatoFechaDDMMYYYY_HHMMSS;

    // formatos de fecha del API
    static readonly formatoFechaApi = formatoFechaDDMMYYYY;
    static readonly formatoFechaHoraApi = formatoFechaDDMMYYYY_HHMMSS;

    static accionBase: AccionBase;
    static navega: Navega;
    static cierraModal: CierraModal;
    static modal: Modal;
    static cierra: Cierra;
    static informa: Informa;

    private constructor() { }

    static configura(p_opciones: Opciones = {}) {
        const {
            accionBase: v_accionBase = Configuracion.accionBase,
            navega: v_navega = Configuracion.navega,
            modal: v_modal = Configuracion.modal,
            cierraModal: v_cierraModal = Configuracion.cierraModal,
            cierra: v_cierra = Configuracion.cierra,
            informa: v_informa = Configuracion.informa
        } = p_opciones;

        Configuracion.accionBase = v_accionBase;
        Configuracion.navega = v_navega;
        Configuracion.modal = v_modal;
        Configuracion.cierraModal = v_cierraModal;
        Configuracion.cierra = v_cierra;
        Configuracion.informa = v_informa;
    }
}
