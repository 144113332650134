import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-titulo-tarjeta',
    templateUrl: './tituloTarjeta.component.html',
})
export class TituloTarjetaComponent {
    @Input() icono: string;
    @Input() etiqueta: string;

    get tieneIcono(): boolean {
        const v_tieneIcono = typeof this.icono === 'string' && this.icono.length > 0;
        return v_tieneIcono;
    }

    get tieneEtiqueta(): boolean {
        const v_tieneEtiqueta = typeof this.etiqueta === 'string' && this.etiqueta.length > 0;
        return v_tieneEtiqueta;
    }
}
