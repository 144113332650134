import { Tipo, tipoIndeterminado } from './tipos';

export class Variable {
    readonly tipo: Tipo;
    readonly valor?: any;

    constructor(p_tipo: Tipo, p_valor?: any) {
        this.tipo = p_tipo;
        this.valor = p_valor;
    }
}

export const indeterminado = new Variable(tipoIndeterminado);
