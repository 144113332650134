import { Injectable, ComponentRef } from '@angular/core';
import { BehaviorSubject, PartialObserver, Subscription } from 'rxjs';
import { PestanaComponent } from '../components/pestana/pestana.component';
import { BaseComponenteAngular } from '../components/UtilidadesComponentes';
import { BaseComponenteLogico } from '../abstracto/ejecucion/componentes';

@Injectable({
    providedIn: 'root'
})
export class PestanaService {
    // lista de pestañas
    private _abiertas = new BehaviorSubject<ComponentRef<PestanaComponent>[]>([]);

    // pestaña activa
    private _activa = new BehaviorSubject<ComponentRef<PestanaComponent> | null>(null);

    // orden de activación
    private _orden: ComponentRef<PestanaComponent>[] = [];

    get abiertas(): ComponentRef<PestanaComponent>[] {
        return this._abiertas.getValue();
    }

    get activa(): ComponentRef<PestanaComponent> | null {
        return this._activa.getValue();
    }

    set activa(p_referenciaPestana: ComponentRef<PestanaComponent> | null) {
        if (p_referenciaPestana === null) {
            this.activa.instance.alDesactivar();
            this._activa.next(null);
        } else if (p_referenciaPestana !== this.activa) {
            if (this.activa != null) {
                this.activa.instance.alDesactivar();
            }
            p_referenciaPestana.instance.alActivar();
            this._activa.next(p_referenciaPestana);
            // cambio el orden de activación de las pestañas poniendo la pestaña que se activa la primera.
            this._orden = [p_referenciaPestana].concat(this._orden.filter(p => p !== p_referenciaPestana));
        }
    }

    activaYMueve(p_referenciaPestana: ComponentRef<PestanaComponent>, p_antesDe?: number) {
        if (p_referenciaPestana !== this.activa) {
            if (this.activa != null) {
                this.activa.instance.alDesactivar();
            }
            p_referenciaPestana.instance.alActivar();
            this._activa.next(p_referenciaPestana);
            // cambio el orden de activación de las pestañas poniendo la pestaña que se activa la primera.
            this._orden = [p_referenciaPestana].concat(this._orden.filter(p => p !== p_referenciaPestana));

            if (typeof p_antesDe !== 'undefined') {
                const
                    v_abiertas = this._abiertas.getValue(),
                    v_pestanas = v_abiertas.slice(0, p_antesDe).concat([p_referenciaPestana]).concat(v_abiertas.slice(p_antesDe).filter(p => p !== p_referenciaPestana));
                this._abiertas.next(v_pestanas);
            }
        }
    }

    abre(p_referenciaPestana: ComponentRef<PestanaComponent>) {
        const v_pestanas = this._abiertas.getValue().concat(p_referenciaPestana);
        this._abiertas.next(v_pestanas);
        this.activa = p_referenciaPestana;
    }

    abreVista(p_referenciaPestana: ComponentRef<PestanaComponent>, p_vista: ComponentRef<BaseComponenteAngular<BaseComponenteLogico>>) {
        p_referenciaPestana.instance.abreVista(p_vista);
        if (p_referenciaPestana === this._activa.getValue()) {
            this._activa.next(p_referenciaPestana);
        }
    }

    cierra(p_referenciaPestana: ComponentRef<PestanaComponent>) {
        // elimino la pestaña de la lista de pestañas abiertas
        this._abiertas.next(this.abiertas.filter(p => p !== p_referenciaPestana));

        // y de la lista de orden de activación
        this._orden = this._orden.filter(p => p !== p_referenciaPestana);

        // si la pestaña cerrada era la activa, activo otra sino dejo la que estaba
        if (p_referenciaPestana === this.activa) {
            if (this._orden.length > 0) {
                this.activa = this._orden[0];
            } else {
                this.activa = null;
            }
        }
        p_referenciaPestana.instance.cierra();
        p_referenciaPestana.destroy();
    }

    cierraTodas() {
        this.abiertas.forEach(p_pestana => this.cierra(p_pestana));
    }

    cierraVista(p_referenciaPestana: ComponentRef<PestanaComponent>) {
        if (p_referenciaPestana.instance.vistas.length > 0) {
            p_referenciaPestana.instance.cierraVista();
        } else {
            this.cierra(p_referenciaPestana);
        }
    }

    subcribeActiva(p_observador: PartialObserver<ComponentRef<PestanaComponent> | null>) {
        return this._activa.subscribe(p_observador);
    }
}
