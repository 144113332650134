<!-- 

<a [class]="icono" *ngIf="iconoComponente">{{etiqueta}}</a>
<a [class]="etiqueta" *ngIf="!iconoComponente">
    <span [class]="icono"></span>
</a> -->


<!-- <a>
    <span *ngIf="tieneIcono" [class]="icono"></span>
    <span *ngIf="tieneEtiqueta">{{etiqueta}}</span>
</a> -->
<ng-container *ngIf="!oculto">

    <span *ngIf="tieneIcono && tieneEtiqueta" [title]="etiqueta" matTooltipClass="tooltip">
        <!-- JPM -->
        <span [class]="icono"></span>
        <span>{{etiqueta}}</span>
    </span>

    <span *ngIf="tieneIcono && !tieneEtiqueta">
        <span [class]="icono"></span>
    </span>

    <span *ngIf="!tieneIcono && tieneEtiqueta">{{etiqueta}}</span>
</ng-container>