import { ErrorHandler, Injectable } from '@angular/core';
import { Compilador } from './abstracto/acciones/evotec_acciones';
import { AccionModal } from './abstracto/especificacion/acciones';
import { DefinicionPropiedadModelo } from './abstracto/especificacion/interfazUsuario';
import { formateaHora, ErrorDefinicion, comoJson } from './abstracto/evotec_comun';

@Injectable()
export class ManejadorErroresGlobal implements ErrorHandler {
    private errores: { hora: string, nombre: string, mensaje: string, pila: string, contexto: string }[] = [];
    private idTemporizador = null;

    handleError(p_error: Error) {
        this.errores = this.errores
            .concat({
                hora: formateaHora(new Date()), nombre: p_error.name, mensaje: p_error.message, pila: (p_error.stack || '').replace('\n', '<br>'), contexto: (p_error instanceof ErrorDefinicion) ? comoJson(p_error.contexto) : null
            })
            ;

        const v_mensajeError = p_error.message;

        console.error(v_mensajeError);

        if (v_mensajeError.indexOf('Found the synthetic property @fadeIn') > -1) {
            return;
        }

        if (this.idTemporizador !== null) {
            clearTimeout(this.idTemporizador);
        }
        this.idTemporizador = setTimeout(() => { this.muestraUltimosErrores(); }, 0);
    }

    private muestraUltimosErrores() {
        const v_modal: AccionModal = {
            accion_id: '_modal',
            pantalla: {
                modelo: {
                    error: {
                        tipo: 'tipo',
                        propiedades: {
                            hora: { tipo: 'string' },
                            nombre: { tipo: 'texto' },
                            mensaje: { tipo: 'texto' },
                            pila: { tipo: 'texto' },
                            contexto: { tipo: 'texto' }
                        }
                    },
                    errores: { tipo: 'array', elementos: 'error' } as DefinicionPropiedadModelo,
                    errorMostrado: { tipo: 'error' },
                },
                datos: {
                    errores: this.errores.slice(-5),
                    errorMostrado: this.errores[this.errores.length - 1],
                },
                vista: {
                    component: 'layoutVertical',
                    components: [{
                        component: 'spyCard',
                        etiqueta: '¡Uy, parece que algo va mal!',
                        components: [
                            {
                                component: 'layoutHorizontal',
                                components: [
                                    {
                                        component: 'layoutVertical',
                                        components: [
                                            {
                                                component: 'spyLabel',
                                                valor: `Y con este, ya llevamos ${this.errores.length} errores.`
                                            },
                                            {
                                                component: 'spyGrid',
                                                seleccionado: { modelo: 'errorMostrado' },
                                                registros: { modelo: 'errores' },
                                                columnas: [
                                                    {
                                                        etiqueta: 'Hora',
                                                        ancho: '8car',
                                                        celda: {
                                                            component: 'spyInput',
                                                            valor: { modelo: 'hora' }
                                                        }
                                                    },
                                                    {
                                                        etiqueta: 'Tipo',
                                                        ancho: '20car',
                                                        celda: {
                                                            component: 'spyInput',
                                                            valor: { modelo: 'nombre' }
                                                        }
                                                    },
                                                    {
                                                        etiqueta: 'Descripción',
                                                        celda: {
                                                            component: 'spyInput',
                                                            valor: { modelo: 'mensaje' }
                                                        }
                                                    },
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        component: 'layoutVertical',
                                        components: [
                                            {
                                                component: 'spyLabel',
                                                negrita: true,
                                                valor: { modelo: 'errorMostrado.mensaje' }
                                            },
                                            {
                                                component: 'spyLabel',
                                                estilos: ['html'],
                                                valor: { modelo: 'errorMostrado.pila' }
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }]
                } as any
            }
        };
        Compilador.ejecuta(v_modal, undefined, undefined, undefined, { pestana: () => undefined });
        this.idTemporizador = null;
    }
}
