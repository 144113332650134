<div class="spyCombo">
    <!-- <select [name]="id" class="spyCombo-value" ng-class="formato" [value]="valor" [attr.disabled]="soloLectura"
        (change)="actualizaValor($event.target.value)">
        <option *ngFor="let valor of opciones" value="{{valor.id}}">
            {{valor.etiqueta}}
        </option>
    </select> -->
    <!-- [width]="100" -->
    <jqxDropDownList #jqxdropdownlist [source]="opciones" [selectedIndex]="indiceSeleccionado"
        (onSelect)="alSeleccionar($event)" [displayMember]="'etiqueta'" [valueMember]="'valor'">
    </jqxDropDownList>
</div>