import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainRoutingModule } from './main-routing.module';
import { ComponentsModule } from '../components/components.module';

export const CONTAINERS = [
  // MainComponent
];

export const EXPORT_CONTAINERS = [
  // MainComponent
];

@NgModule({
  imports: [
    CommonModule,
    MainRoutingModule,
    ComponentsModule
  ],
  declarations: EXPORT_CONTAINERS,
  exports: EXPORT_CONTAINERS,
  providers: [
  ],
  entryComponents: CONTAINERS
})
export class MainModule { }
