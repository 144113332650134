import { reescribeModelo } from '../especificacion/reescrituraAcciones';
import { reescribeComponente } from '../especificacion/reescrituraComponentes';
import { Modelo, Tipos, Propiedades, Acciones, Propiedad, PropiedadArray, PropiedadSimple, PropiedadObjeto } from './modelo';
import { instanciaComponente } from './componentes';
import { esTipoPredefinido } from '../especificacion/tipos';
import { Variable } from '../acciones/variables';
import { Definicion, miembroEsAgenda, extraeTiposLogicos, DefinicionModelo, DefinicionTipoModelo, miembroEsPropiedad, DefinicionPropiedadModelo, miembroEsAccion, DefinicionAccionModelo, miembroEsPropiedadArray } from '../especificacion/interfazUsuario';

import cloneDeep from 'lodash.clonedeep';
import { ErrorDefinicion } from '../evotec_comun';

var n: number = 0;

// ---------------------------------------------------------------------------------------------
// procesa la definicion de una pantalla
// ---------------------------------------------------------------------------------------------
export function procesa(
    p_modeloInvocante: Variable | undefined,
    p_definicion: Definicion
) {

    console.debug('Procesando definición de pantalla');

    // *********************************************************************************************
    // ---------------------------- ÑAPA ÑAPA AGENDA ----------------------------------------------- 
    console.warn('procesa -- !!! ATENCIÓN: METIDA A MANURRIO LA GESTIÓN DE LA AGENDA - cuando se pueda QUITAR')
    const v_tipoAgendaModelo = Object.getOwnPropertyNames(p_definicion.modelo)
        .filter(p_propiedad => miembroEsAgenda(p_definicion.modelo[p_propiedad]));

    if (v_tipoAgendaModelo.length !== 0) {
        (p_definicion.modelo as any)[v_tipoAgendaModelo[0]] = {
            tipo: 'tipo',
            propiedades: {
                'id': { tipo: 'texto' },
                'calendar': { tipo: 'texto' },
                'start': { tipo: 'fecha' },
                'end': { tipo: 'fecha' },
                'subject': { tipo: 'texto' },
                'description': { tipo: 'texto' }
            }
        }
        const v_tipoAgendaRegistros = v_tipoAgendaModelo + '_registros';
        const v_tiposAgendaDatos = Object.getOwnPropertyNames(p_definicion.datos)
            .filter(p_propiedad => p_propiedad === v_tipoAgendaRegistros);
        (p_definicion.modelo as any)[v_tipoAgendaRegistros] = {
            tipo: 'array',
            elementos: v_tipoAgendaModelo[0]
        };
        p_definicion.datos[v_tipoAgendaModelo[0]] = p_definicion.datos[v_tiposAgendaDatos[0]];
    }


    // ---------------------------- ÑAPA ÑAPA AGENDA ----------------------------------------------- 
    // *********************************************************************************************

    const
        v_modelo = p_definicion.modelo && reescribeModelo(p_definicion.modelo, p_definicion),
        v_datos = p_definicion.datos,
        v_tiposLogicos = extraeTiposLogicos(v_modelo),
        v_vista = p_definicion.vista && reescribeComponente(v_modelo, p_definicion.vista, v_tiposLogicos);

    console.debug('Modelo: (...)');
    console.debug(v_modelo);
    console.debug('Vista: (...)');
    console.debug(v_vista);

    const
        v_definicionModelo = v_modelo && procesaDefinicionModelo(v_modelo, []),
        v_instanciaModelo = v_definicionModelo.instanciaModelo(v_datos),
        v_instanciaComponentes = v_vista && instanciaComponente(p_modeloInvocante, v_definicionModelo, v_vista);

    var m = ++n;
    function f() {
        // console.group(`tic ${m}`);
        try {
            const v_copia = cloneDeep(v_instanciaModelo.valores);
            // console.log(v_copia);
            try {
                v_definicionModelo.ejecutaPruebaDeCordura(v_instanciaModelo);
            } catch (ex) {
                console.error(ex);
            }
        } finally {
            // console.groupEnd();
        }
    }
    f();
    setInterval(f, 10000);

    return {
        modelo: { definicion: v_definicionModelo, instancia: v_instanciaModelo },
        vista: { definicion: v_instanciaComponentes }
    };
}


// procesa la definición del modelo
export function procesaDefinicionModelo(p_modelo: DefinicionModelo, p_padres: Modelo[]): Modelo {
    const
        // Paso 1: se extraen todos los nombres de tipo del modelo
        v_tiposLogicos = extraeTiposLogicos(p_modelo),

        // Paso 2: se extraen todos los tipos del modelo
        v_tipos = Object.getOwnPropertyNames(v_tiposLogicos)
            .reduce((p_tipos, p_nombre) => {
                const v_propiedad = p_modelo[p_nombre] as DefinicionTipoModelo;
                p_tipos[p_nombre] = procesaDefinicionModelo(v_propiedad.propiedades, p_padres);
                return p_tipos;
            }, {} as Tipos),

        // Paso 3: se extraen todas las propiedades del modelo
        v_propiedades = Object.getOwnPropertyNames(p_modelo)
            .filter(p_propiedad => miembroEsPropiedad(p_modelo[p_propiedad], v_tiposLogicos))
            .reduce((p_propiedades, p_propiedad) => {
                const v_propiedad = p_modelo[p_propiedad] as DefinicionPropiedadModelo;
                p_propiedades[p_propiedad] = procesaPropiedad(v_propiedad, v_tipos);
                return p_propiedades;
            }, {} as Propiedades),

        // Paso 4 se extraen todas las acciones del modelo
        v_acciones = Object.getOwnPropertyNames(p_modelo)
            .filter(p_propiedad => miembroEsAccion(p_modelo[p_propiedad]))
            .reduce((p_acciones, p_propiedad) => {
                const v_accion = { ...p_modelo[p_propiedad] } as DefinicionAccionModelo;
                delete v_accion.tipo;
                p_acciones[p_propiedad] = v_accion;
                return p_acciones;
            }, {} as Acciones);

    // Paso 5: se contruye una versión inicial del modelo
    let v_nuevoModelo = new Modelo(v_acciones, enlazaTipos(v_tipos, v_propiedades), v_tipos, []);

    v_nuevoModelo = v_nuevoModelo.resuelveAcciones(/*, p_padres*/);

    v_nuevoModelo.referenciaPadres([v_nuevoModelo].concat(p_padres));
    return v_nuevoModelo;
}

// procesa la definición de una propiedad del modelo
function procesaPropiedad(p_propiedad: DefinicionPropiedadModelo, p_tipos: Tipos/*, p_padres?*/): Propiedad {
    if (!esTipoPredefinido(p_propiedad.tipo) && !p_tipos[p_propiedad.tipo]) {
        throw new ErrorDefinicion(`Tipo desconocido: '${p_propiedad.tipo}'.`);
    }

    if (esTipoPredefinido(p_propiedad.tipo)) {
        if (miembroEsPropiedadArray(p_propiedad)) {
            const v_propiedad = new PropiedadArray(p_propiedad.elementos, p_propiedad.onChange);
            return v_propiedad;
        } else {
            const v_propiedad = new PropiedadSimple(p_propiedad.tipo, p_propiedad.onChange);
            return v_propiedad;
        }
    } else {
        const v_propiedad = new PropiedadObjeto(p_propiedad.tipo, p_propiedad.onChange);
        return v_propiedad;
    }
}

// Procesa las propiedades de la definición del modelo enlazando con los tipos detectados
function enlazaTipos(p_tipos: Tipos, p_propiedades: Propiedades): Propiedades {

    function enlazaTipoPropiedad(p_tipos: Tipos): (p_propiedad: Propiedad) => Propiedad {
        return p_propiedad => {
            if (p_propiedad instanceof PropiedadArray || p_propiedad instanceof PropiedadObjeto) {
                p_propiedad.enlaza(p_tipos);
            }
            return p_propiedad;
        };
    }

    const
        v_enlazaTipoPropiedad = enlazaTipoPropiedad(p_tipos),
        v_propiedades: Propiedades = {};

    Object.getOwnPropertyNames(p_propiedades).forEach(p_nombrePropiedad => {
        const v_valor = v_enlazaTipoPropiedad(p_propiedades[p_nombrePropiedad]);
        v_propiedades[p_nombrePropiedad] = v_valor;
    });

    return v_propiedades;
}
