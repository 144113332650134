import { Component, OnInit, ViewContainerRef, ViewChild, HostBinding, OnDestroy } from '@angular/core';
import { Variable } from '../../abstracto/acciones/variables';
import { BaseComponenteContenedorAngular } from '../UtilidadesComponentes';
import { ComponenteBotonera } from '../../abstracto/ejecucion/componentes';
import { Modelo, InstanciaModelo, InstanciaObjeto } from '../../abstracto/ejecucion/modelo';

@Component({
    selector: 'app-toolbar',
    templateUrl: './botonera.component.html',
})
export class BotoneraComponent implements BaseComponenteContenedorAngular<ComponenteBotonera>, OnInit, OnDestroy {
    private static _id = 0;
    id: string;

    definicionModelo: Modelo | undefined;
    instanciaModelo: InstanciaModelo | undefined;
    modeloInvocante: Variable | undefined;
    fila: number | undefined;

    componente: ComponenteBotonera | undefined;

    constructor() {
        this.id = `botonera-${BotoneraComponent._id++}`;
    }

    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_fila: number,
        p_componente: ComponenteBotonera,
        p_modeloInvocante: Variable): void {

        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    ngOnInit(): void {
        console.warn(`Creando ${this.id}`);
    }

    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
    }

    @ViewChild('vc', { read: ViewContainerRef, static: true }) contenedor: ViewContainerRef | undefined;

    // Propiedad 'visible'
    private _visible = true;
    get visible(): boolean { return this._visible; }
    set visible(p_valor: boolean) { this._visible = p_valor; }

    @HostBinding('class.oculto')
    get oculto(): boolean {
        return !this._visible;
    }
}
