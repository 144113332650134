<dialog #dialogo [class]="[estado].join(' ')">
    <div #modal class="modal">
        <div [class]="['modal-titulo', tieneIcono ? 'tiene-icono' : '', tieneEtiqueta ? 'tiene-etiqueta' : ''].join(' ')">
            <span *ngIf="tieneIcono" [class]="['modal-titulo-icono', icono].join(' ')"></span>
            <span class="modal-titulo-etiqueta">{{etiqueta}}</span>

            <span class="modal-titulo-cerrar al-final icon-close" (click)="cierra()"></span>
        </div>

        <div class="modal-contenido">
            <ng-container #contenedorContenido></ng-container>
        </div>

        <div [class]="['modal-opciones', tieneOpciones ? 'tiene-opciones': ''].join(' ')">
            <ng-container #contenedorOpciones></ng-container>
        </div>
    </div>
</dialog>