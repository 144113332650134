<section id="particles-js" class="page-login">

    <div class="login-wrapper">
        <h1>Bienvenido a <img src="/assets/images/logo-white.png" alt="Logo Spyro" /></h1>
        <h2>Iniciar sesión</h2>

        <form class="" #f="ngForm" (ngSubmit)="iniciaSesion(f)">

            <!-- input user -->
            <div class="spyInput-value-wrapper jqx-input-material distribucion-horizontal">
                <span class="icon-person_outline"></span>
                <jqxInput #inputNombre theme="material" [width]="270" [height]="30" [minLength]="1"
                    [placeHolder]="'Usuario'" name="nombre" id="nombre" ngModel>
                </jqxInput>
                <!-- <label for="id" class="spyInput-title">Usuario</label> -->
            </div>

            <!-- input password -->
            <div class="spyInput-value-wrapper jqx-input-material distribucion-horizontal">
                <span class="icon-password"></span>
                <jqxInput #inputPassword theme="material" [width]="270" [height]="30" [placeHolder]="'Contraseña'"
                    name="password" id="password" ngModel>
                </jqxInput>
                <span #ojo [class]="['ojo', iconoOjo].join(' ')"></span>
            </div>

            <!-- input bbdd -->
            <div class="spyInput-value-wrapper jqx-input-material distribucion-horizontal">
                <span class="icon-database"></span>
                <jqxInput #inputBd theme="material" [width]="270" [height]="30" [minLength]="1"
                    [placeHolder]="'Base de datos'" name="bd" id="bd" ngModel>
                </jqxInput>
            </div>

            <div class="form-footer">
                <div class="spycheck">
                    <input class="spyCheck-value" type="checkbox" id="casilla-verificacion-0" [checked]="recuerdame_SN"
                        (change)="recuerdame($event)" />
                    <label class="spyCheck-title" for="casilla-verificacion-0"><span></span>Recuérdame</label>
                </div>
                <a href="https://www.spyrosoftware.com/" target="_blank">¿Necesitas ayuda?</a>
            </div>

            <!-- btn -->
            <!-- <input type="submit" value="Acceder" /> -->
            <input type="submit" value="Acceder" />
            <!-- ./btn -->


        </form>


    </div>

</section>
<jqxNotification #jqxnotification [width]="'auto'" [position]="'bottom-right'" [opacity]="0.95" [autoOpen]="false"
    [autoClose]="false" [closeOnClick]="false" [theme]="'error'">
    <div>
        <span id="mensaje-notificacion"></span>
    </div>
</jqxNotification>