<ng-template #contenedor></ng-template>

<div class="cabecera-menu">
    <a class="menu-boton" (click)="minimizaMenu()">
        <span class="menu-boton-icono" [class.menu-minimizado]="menuMinimizado" [class.menu-oculto]="menuOculto">
            <span></span>
            <span></span>
            <span></span>
        </span>
    </a>

    <!-- <img src="assets/images/logo.png" alt="Logo" class="cabecera-logo"> -->
    <!-- <img src="assets/images/logo_s.png" alt="Logo" class="cabecera-logo cabecera-logo-spyro cabecera-logo-s"> -->
    <!-- [class.spinner-activo]="spinnerActivo"> -->
    <!-- <img src="assets/images/logo_p.png" alt="Logo" class="cabecera-logo cabecera-logo-spyro cabecera-logo-p"> -->
    <!-- [class.spinner-activo]="spinnerActivo"> -->
    <!-- <img src="assets/images/logo_y.png" alt="Logo" class="cabecera-logo cabecera-logo-spyro cabecera-logo-y"> -->
    <!-- [class.spinner-activo]="spinnerActivo"> -->
    <!-- <img src="assets/images/logo_r.png" alt="Logo" class="cabecera-logo cabecera-logo-spyro cabecera-logo-r"> -->
    <!-- [class.spinner-activo]="spinnerActivo"> -->
    <!-- <img src="assets/images/logo_o.png" alt="Logo" class="cabecera-logo cabecera-logo-spyro cabecera-logo-o"> -->
    <!-- [class.spinner-activo]="spinnerActivo"> -->
    <!-- <img src="assets/images/logo_web.png" alt="Logo" class="cabecera-logo cabecera-logo-web"> -->
    <img src="assets/images/logo-spyro-pantalla.png" alt="Logo" class="cabecera-logo cabecera-logo-web"> <!-- Icono -->
</div>
<div style="flex: 1; height: 100%;">
    <div class="cabecera-botonera">
        <app-selector-pestanas [pestanas]="pestanaService.abiertas" [pestanaActiva]="pestanaService.activa"
            (alSeleccionarPestana)="pestanaService.activaYMueve($event.pestana, $event.antesDe)"
            (alCerrarPestana)="pestanaService.cierra($event)">
        </app-selector-pestanas>
        <div class="cabecera-botonera-opciones-rapidas">
            <ul>
                <li *ngIf="!esEntornoProduccion()"><a class="menu-calendar"><span (click)="entornoPruebasRBM()"
                            class="ico-develop"></span></a></li>
                <!-- <li><a class="menu-calendar"><span (click)="agenda()" class="ico-calendar"></span></a></li> -->
                <app-perfil></app-perfil>
            </ul>
        </div>
    </div>
    <div class="cabecera-barra-progreso">
        <mat-progress-bar *ngIf="spinnerActivo" mode="indeterminate"></mat-progress-bar>
        <!-- <mat-progress-bar mode="determinate" value="30" style="transform: scaleX(1);"></mat-progress-bar> -->
    </div>
</div>