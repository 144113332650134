import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JqwidgetsModule } from './jqwidgets/jqwidgets.module';

export const CONTAINERS = [

];

export const EXPORT_CONTAINERS = [

];

@NgModule({
  imports: [
    CommonModule,
    JqwidgetsModule
  ],
  declarations: EXPORT_CONTAINERS,
  exports: EXPORT_CONTAINERS,
  providers: [],
  entryComponents: CONTAINERS
})
export class SharedModule { }