import { Component, OnInit, ViewChild, ViewContainerRef, Output, EventEmitter, ElementRef, OnDestroy, Optional, forwardRef, Inject, ComponentRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TarjetaComponent } from '../tarjeta/tarjeta.component';
import { CapturaFocoService } from '../../services/CapturaFocoService';
import { BaseComponenteAngular } from '../UtilidadesComponentes';
import { ContenedorModalComponent } from '../contenedorModal/contenedorModal.component';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit, OnDestroy {
    private static _id = 0;
    id: string;

    constructor(
        private readonly capturaFocoService: CapturaFocoService,
        @Optional() @Inject(forwardRef(() => ContenedorModalComponent)) private readonly contenedorModal: ContenedorModalComponent
    ) {
        this.id = `modal-${ModalComponent._id++}`;
    }

    subscripcionModalAbre: Subscription | undefined;

    @ViewChild('dialogo', { static: true }) dialogo: ElementRef<HTMLElement> | undefined;
    @ViewChild('modal', { static: true }) modal: ElementRef<HTMLElement> | undefined;
    @ViewChild('contenedorIconoEtiqueta', { read: ViewContainerRef, static: true }) contenedorIconoEtiqueta: ViewContainerRef | undefined;
    @ViewChild('contenedorOpciones', { read: ViewContainerRef, static: true }) contenedorOpciones: ViewContainerRef | undefined;
    @ViewChild('contenedorContenido', { read: ViewContainerRef, static: true }) contenedorContenido: ViewContainerRef | undefined;
    @Output() closeModalEvent = new EventEmitter<boolean>();

    // Propiedad 'etiqueta'
    private _etiqueta: string;
    get etiqueta(): string { return this._etiqueta; }

    get tieneEtiqueta(): boolean {
        const v_tieneEtiqueta = typeof this.etiqueta === 'string' && this.etiqueta.length > 0;
        return v_tieneEtiqueta;
    }

    // Propiedad 'etiqueta'
    private _icono: string;
    get icono(): string { return this._icono; }

    get tieneIcono(): boolean {
        const v_tieneIcono = typeof this._icono === 'string' && this._icono.length > 0;
        return v_tieneIcono;
    }

    tieneOpciones: boolean = false;

    private abre(p_componente: ComponentRef<BaseComponenteAngular>) {
        if (p_componente !== null) {
            const v_componenteLogico = p_componente.instance;
            if (v_componenteLogico instanceof TarjetaComponent) {
                p_componente.changeDetectorRef.detectChanges();
                
                const
                    v_tarjeta = v_componenteLogico,
                    v_tituloTarjeta = v_tarjeta.contenedorTitulo,
                    v_opcionesTarjeta = v_tarjeta.contenedorOpciones.detach();
                this._icono = v_tituloTarjeta.icono;
                this._etiqueta = v_tituloTarjeta.etiqueta;

                // if (v_tituloTarjeta !== null) {
                //     this.contenedorIconoEtiqueta.insert(v_tituloTarjeta);
                // }
                if (v_opcionesTarjeta !== null) {
                    this.contenedorOpciones.insert(v_opcionesTarjeta);
                    this.tieneOpciones = true;
                }
                while (v_tarjeta.contenedor.length > 0) {
                    const v_contenidoTarjeta = v_tarjeta.contenedor.detach(0);
                    this.contenedorContenido.insert(v_contenidoTarjeta);
                }
                // this.contenedorContenido.insert(v_contenidoTarjeta);
            } else {
                this.contenedorContenido.insert(p_componente.hostView);
            }

            // si la pestaña activa es la que contiene este modal habilitamos la animación de apertura del modal
            // if (this.pestanaService.activa.instance === this.contenedor) {
            this._estado = 'modal-abriendo';
            // }

            const v_dialogo = this.dialogo.nativeElement;

            // v_dialogo.showModal();
            setTimeout(() => this.capturaFocoService.captura(v_dialogo), 0);
        }
    }

    cierra() {
        this._estado = 'modal-cerrando';
    }

    ngOnInit() {
        console.warn(`Creando ${this.id}`);

        this.subscripcionModalAbre = this.contenedorModal.alAbrirModal.subscribe({
            next: (p_componente) => this.abre(p_componente)
        });

        this.modal.nativeElement.addEventListener('animationend', () => {
            if (this._estado === 'modal-abriendo') {
                this._estado = null;
            } else if (this._estado === 'modal-cerrando') {
                // Comprobamos si hay una pestaña activa y abrimos modal
                if (this.contenedorModal !== undefined) {
                    this.contenedorModal.cierraModal(true);
                }
                this.contenedorModal.abierto = false;
            }
        });
    }

    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
        this.contenedorContenido.detach();

        this.subscripcionModalAbre.unsubscribe();
        this.capturaFocoService.libera();

        // if (typeof this.capturaFoco !== 'undefined') {
        // this.capturaFoco.finaliza();
        // }
    }

    private _estado: 'modal-abriendo' | 'modal-cerrando' | null = null;
    get estado(): string {
        if (this._estado === null) {
            return '';
        } else {
            return this._estado;
        }
    }
}
