import { Diccionario } from '../evotec_comun';
import { esTipoPredefinido } from './tipos';
import { DefinicionComponente, DescriptorCalculo } from './componentes';
import { AccionResuelta } from '../acciones/evotec_acciones';
import { Accion } from './acciones';

// Interfaz de usuario
export interface Definicion {
    modelo: DefinicionModelo;
    datos?: Datos;
    vista?: DefinicionComponente;
}

export type DefinicionModelo = Diccionario<DefinicionMiembroModelo>;
export type Datos = Diccionario;

// Miembros del modelo
export type DefinicionMiembroModelo = DefinicionTipoModelo | DefinicionPropiedadModelo | DefinicionAccionModelo;

export interface DefinicionTipoModelo {
    tipo: 'tipo';
    propiedades: DefinicionModelo;
}

export interface DefinicionPropiedadModelo {
    tipo: string;
    modelo?: string;
    calculo?: string | DescriptorCalculo;
    onChange?: EventoAlCambiar;
}

export type DefinicionAccionModelo = { tipo: 'accion' } & Accion;

export interface DefinicionPropiedadModeloArray extends DefinicionPropiedadModelo {
    tipo: 'array';
    elementos: string;
}

export type EventoAlCambiar = string | Accion | AccionResuelta;

export function miembroEsTipo(p_miembro: DefinicionMiembroModelo): p_miembro is DefinicionTipoModelo {
    return p_miembro.tipo === 'tipo';
}

// Indica si un miembro de un modelo lógico es una propiedad de cualquier tipo.
export function miembroEsPropiedad(p_miembro: DefinicionMiembroModelo, p_tipos: Diccionario<DefinicionTipoModelo>): p_miembro is DefinicionPropiedadModelo {
    return typeof p_miembro.tipo !== 'undefined' && (esTipoPredefinido(p_miembro.tipo) || typeof p_tipos[p_miembro.tipo] !== 'undefined');
}

export function miembroEsPropiedadObjeto(p_miembro: DefinicionMiembroModelo, p_tipos: Diccionario<DefinicionTipoModelo>): boolean {
    return typeof p_miembro.tipo !== 'undefined' && !esTipoPredefinido(p_miembro.tipo) && typeof p_tipos[p_miembro.tipo] === 'undefined';
}

// Ñapa agenda
export function miembroEsAgenda(p_miembro: any): p_miembro is any {
    return p_miembro.tipo === 'agenda';
}

export function miembroEsPropiedadArray(p_propiedad: DefinicionMiembroModelo): p_propiedad is DefinicionPropiedadModeloArray {
    return p_propiedad.tipo === 'array';
}

export function miembroEsAccion(p_propiedad: DefinicionMiembroModelo): p_propiedad is DefinicionAccionModelo {
    return p_propiedad.tipo === 'accion';
}

export interface DefinicionPropiedadModeloArray extends DefinicionPropiedadModelo {
    elementos: string;
}

export type PropiedadNotificaCambio = DefinicionPropiedadModelo | DefinicionPropiedadModeloArray;

export function propiedadNotificaCambio(p_propiedad: DefinicionPropiedadModelo): p_propiedad is PropiedadNotificaCambio & { onChange: EventoAlCambiar } {
    return typeof p_propiedad === 'object' && p_propiedad !== null && typeof p_propiedad.onChange !== 'undefined';
}

export function esNombreAccion(p_accion: EventoAlCambiar | undefined): p_accion is string {
    return typeof p_accion === 'string';
}

export function extraeTiposLogicos(p_modelo: DefinicionModelo): Diccionario<DefinicionTipoModelo> {
    const v_tipos = Object.getOwnPropertyNames(p_modelo)
        .filter(p_propiedad => miembroEsTipo(p_modelo[p_propiedad]))
        .reduce((p_tipos, p_nombre) => {
            p_tipos[p_nombre] = p_modelo[p_nombre] as DefinicionTipoModelo;
            return p_tipos;
        }, {} as Diccionario<DefinicionTipoModelo>);
    return v_tipos;
}