import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { LoginService } from './loginService';
import { AuthService } from 'src/app/core/services/Auth/auth.service';
import { JqwidgetsModule } from './../../shared/jqwidgets/jqwidgets.module';


@NgModule({
    imports: [
        CommonModule,
        LoginRoutingModule,
        FormsModule,
        JqwidgetsModule,
    ],
    exports: [
        LoginComponent
    ],
    declarations: [LoginComponent],
    providers: [LoginService, AuthService]
})
export class LoginModule { }
