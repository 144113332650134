import { Component, OnInit, ViewContainerRef, ViewChild, ViewRef, ComponentRef, OnDestroy, HostBinding, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaginaService } from '../../services/PaginaService';
import { Variable } from '../../abstracto/acciones/variables';
import { ComponentePestana, BaseComponenteLogico } from '../../abstracto/ejecucion/componentes';
import { Modelo, InstanciaModelo, InstanciaObjeto } from '../../abstracto/ejecucion/modelo';
import { BaseComponenteAngular } from '../UtilidadesComponentes';
import { ContenedorModalComponent } from '../contenedorModal/contenedorModal.component';

@Component({
    selector: 'app-pestana',
    templateUrl: './pestana.component.html'
})
export class PestanaComponent implements BaseComponenteAngular<ComponentePestana>, OnInit, OnDestroy {
    ngOnInit(): void {
        console.warn(`Creando ${this.id}`);
    }
    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
    }
    //@ViewChild('modal', { static: false }) modal: ViewContainerRef | undefined;
    @ViewChild('contenedorModal', { read: ContenedorModalComponent, static: true }) contenedorModal: ContenedorModalComponent;

    private static _id = 0;
    id: string;
    @Input() spinnerActivo: boolean = false;

    constructor(
        private spinner: NgxSpinnerService,
        private paginaService: PaginaService
    ) {
        this.id = `pestana-${PestanaComponent._id++}`;
        this.inicializaTipoSpinner();
    }

    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_fila: number,
        p_componente: ComponentePestana,
        p_modeloInvocante: Variable): void {

        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    definicionModelo: Modelo | undefined;
    instanciaModelo: InstanciaModelo | undefined;
    modeloInvocante: Variable | undefined;
    fila: number | undefined;

    componente: ComponentePestana | undefined;

    @ViewChild('vc', { read: ViewContainerRef, static: true }) contenedor: ViewContainerRef | undefined;

    // Propiedad 'etiqueta'
    private _etiqueta: string = '';
    get etiqueta(): string { return this._etiqueta; }
    set etiqueta(p_valor: string) { this._etiqueta = p_valor; }

    // Propiedad 'visible'
    private _visible: boolean = true;
    get visible(): boolean { return this._visible; }
    set visible(p_valor: boolean) { this._visible = p_valor; }

    get vista(): ViewRef[] {
        if (typeof this.contenedor === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        const
            v_contenedorVista = this.contenedor,
            v_contenido: ViewRef[] = [];
        for (let v_indice = 0; v_indice < v_contenedorVista.length; v_indice++) {
            const v_vista = v_contenedorVista.get(v_indice);
            if (v_vista === null) {
                throw new Error('Error interno; no es una vista válida.');
            }
            v_contenido.push(v_vista);
        }
        return v_contenido;
    }

    vistas: ComponentRef<BaseComponenteAngular<BaseComponenteLogico>>[] = [];

    // Vista inicial de la pestaña. La forma en que se contruye e instancia la pestaña
    // no parece permitir que pueda forma parte de la lista de vistas de la pestaña
    // por lo que se de da un tratamiento especial.
    private vistaInicial: ViewRef | null = null;

    abreVista(p_vista: ComponentRef<BaseComponenteAngular<BaseComponenteLogico>>) {
        if (typeof this.contenedor === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        // Si el la primera vista que se abre se considera que el contenido actual de la pestaña
        // es la vista inicial. Se guarda para futuras referencias.
        if (this.vistas.length === 0) {
            this.vistaInicial = this.contenedor.get(0);
        }
        this.contenedor.clear();

        // Hacemos que se muestre la vista en la pestaña
        this.contenedor.insert(p_vista.hostView);

        // y guardamos la vista para poder regresar a ella.
        this.vistas.push(p_vista);
    }

    cierraVista() {
        if (typeof this.contenedor === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        const v_vistaACerrar = this.vistas.pop();
        if (typeof v_vistaACerrar !== 'undefined') {
            v_vistaACerrar.destroy();
        }

        if (this.vistas.length > 0) {
            const v_nuevaVista = this.vistas[this.vistas.length - 1];
            this.contenedor.insert(v_nuevaVista.hostView);
        } else if (this.vistaInicial !== null) {
            // Si ya no quedan vistas en la lista se restaura el contenido inicial de la
            // pestaña.
            this.contenedor.insert(this.vistaInicial);
        }

        // Eliminamos la vista anterior del contenedor. Si se ejecuta antes de insertar
        // el nuevo contenido, se produce un problema de refresco así que lo hacemos
        // después.
        // this.contenedor.detach(0);
        this.contenedor.remove(0);
    }

    cierra() {
        if (typeof this.contenedor === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        this.contenedor.clear();
        this.vistas.forEach(p_vista => p_vista.destroy());
        this.vistas = [];
    }

    private estadoModal: boolean = false;

    abreModal(p_componente: ComponentRef<BaseComponenteAngular>, p_resuelve: (p_valor: boolean) => void) {
        this.contenedorModal.abreModal(p_componente, p_resuelve);
    }

    cierraModal(p_ok: boolean) {
        this.contenedorModal.cierraModal(p_ok);
    }

    // Desactivamos las animaciones de material en este punto
    // porque hay un parpadeo al abrir el spinner
    @HostBinding('@.disabled') disabled = true
    private spinnersActivos: number = 0;
    muestraSpinner() {
        this.spinnersActivos++;
        this.spinnerActivo = true;
        this.spinner.show();
    }

    ocultaSpinner() {
        if (this.spinnersActivos <= 0) {
            throw new Error('Error interno; ningún spinner activo');
        }
        if (--this.spinnersActivos === 0) {
            this.spinnerActivo = false;
            this.spinner.hide().finally(this.inicializaTipoSpinner.bind(this));
        }
    }

    get modalAbierto(): boolean {
        return this.estadoModal;
    }

    get menuMinimizado(): boolean {
        return this.paginaService.menuMinimizado;
    }

    private static readonly spinners = [
        'ball-spin', 'ball-fussion', 'square-spin', 'timer', 'line-scale', 'ball-triangle-path',
        'ball-scale', 'ball-rotate', 'ball-clip-rotate-multiple', 'ball-clip-rotate', 'ball-atom',
        'triangle-skew-spin',
    ];

    private _tipoSpinner: string;

    inicializaTipoSpinner() {
        const v_indice = Math.floor(Math.random() * PestanaComponent.spinners.length);
        this._tipoSpinner = PestanaComponent.spinners[v_indice];
    }

    get tipoSpinner(): string {
        return this._tipoSpinner;
    }

    alActivar() {
        console.log(`activando pestana ${this.id}`);
    }

    alDesactivar() {
        console.log(`desactivando pestana ${this.id}`);
    }
}
