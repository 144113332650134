import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './services/Auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class CanActivateViaAuthGuard implements CanActivate {
    constructor(
        private auth: AuthService
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.auth.sesionAbierta) {
            return true;
        }

        this.auth.cierraSesion();
        return false;
    }
}
