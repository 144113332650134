import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { TaskService } from '../../services/TaskService';
import { Chart as ChartDHX } from 'dhx-suite';
import 'dhtmlx-gantt';
import { BehaviorSubject } from 'rxjs';
// import * as moment from 'moment';

declare let gantt: any;
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'gantt',
  styleUrls: ['./gantt.component.css'],
  providers: [TaskService],
  templateUrl: './gantt.component.html'
})
export class GanttComponent implements AfterViewInit, OnDestroy {
  @ViewChild('gantt_here') ganttContainer: ElementRef;
  @ViewChild('widget') chartContainer: ElementRef;
  chart: ChartDHX;
  container: ElementRef;
  descripcionTarea: string;


  constructor(private taskService: TaskService) { }

  calendarios = null;


  ngAfterViewInit() {
    gantt.plugins({
      // quick_info: true,
      tooltip: true,
      // critical_path: true
    });

    gantt.config.min_column_width = 80;
    var zoomConfig = {
      levels: [
        {
          name: "hour",
          scale_height: 75,
          min_column_width: 80,
          scales: [
            { unit: "hour", step: 1, format: "%g %a" },
            { unit: "day", step: 1, format: "%j %F, %l" },
            { unit: "minute", step: 15, format: "%i" }
          ]
        },
        {
          name: "day",
          scale_height: 27,
          min_column_width: 80,
          scales: [
            { unit: "day", step: 1, format: "%d %M" },
          ]
        },
        {
          name: "week",
          scale_height: 50,
          min_column_width: 50,
          scales: [
            {
              unit: "week", step: 1, format: function (date) {
                var dateToStr = gantt.date.date_to_str("%d %M");
                var endDate = gantt.date.add(date, -6, "day");
                var weekNum = gantt.date.date_to_str("%W")(date);
                return "#" + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate);
              }
            },
            { unit: "day", step: 1, format: "%j %D" }
          ]
        },
        {
          name: "month",
          scale_height: 50,
          min_column_width: 120,
          scales: [
            { unit: "month", format: "%F, %Y" },
            { unit: "week", format: "Semana #%W" }
          ]
        },
        {
          name: "quarter",
          height: 50,
          min_column_width: 90,
          scales: [
            { unit: "month", step: 1, format: "%M" },
            {
              unit: "quarter", step: 1, format: function (date) {
                var dateToStr = gantt.date.date_to_str("%M");
                var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                return dateToStr(date) + " - " + dateToStr(endDate);
              }
            }
          ],
        },
        {
          name: "year",
          scale_height: 50,
          min_column_width: 30,
          scales: [
            { unit: "year", step: 1, format: "%Y" }
          ]
        }
      ],
      useKey: "ctrlKey",
      trigger: "wheel",
      element: function () {
        return gantt.$root.querySelector(".gantt_task");
      }
    };

    gantt.ext.zoom.init(zoomConfig);
    gantt.ext.zoom.setLevel("month");
    gantt.ext.zoom.attachEvent("onAfterZoom", function (level, config) {
      document.querySelector(".gantt_radio[value='" + config.name + "']:checked");
    })
    var radios = document.getElementsByName("scale");
    for (var i = 0; i < radios.length; i++) {
      radios[i].onclick = function (event) {
        const target = event.target as HTMLTextAreaElement;
        gantt.ext.zoom.setLevel(target.value);
      };
    }
    let format_date = gantt.date.date_to_str("%d-%m-%Y");
    gantt.templates.tooltip_text = function (start, end, task) {
      return "<b>Recurso/máquina:</b> " + task.text
        + "<br/><b>Duración:</b> " + Number(((task.duration / 60) / 24).toFixed(2)) + " días "
        + "<br/><b>Fecha inicio:</b> " + format_date(start)
        + "<br/><b>Fecha fin:</b> " + format_date(end)
    };
    gantt.message("Visualiza rápidamente la planificación de los recursos/máquinas.");

    gantt.config.work_time = true;
    gantt.config.min_column_width = 60;
    gantt.config.duration_unit = "minute";
    gantt.config.scale_height = 20 * 3;
    gantt.config.row_height = 28;
    gantt.i18n.setLocale("es");

    gantt.config.date_grid = "%d/%m/%Y";
    gantt.config.reorder_grid_columns = true;
    gantt.config.drag_resize = false;
    gantt.config.drag_move = false;
    gantt.config.columns = [
      { name: "text", label: "Recurso/máquina", min_width: 200, width: 200, tree: true },
      { name: "start_date", label: "F.inicio", align: "center", min_width: 120, width: 120 },
      { name: "end_date", label: "F.fin", align: "center", min_width: 120, width: 120 },
      { name: "gpoperac_cod", label: "Operario", align: "center", min_width: 60, width: 60 },
      {
        name: "ggclipr_cod", label: "Cliente", min_width: 120, width: 120, template: function (obj) {
          if (obj.ggclipr_cod !== null) {
            return obj.ggclipr_cod + " - " + obj.ggclipr_nombre;
          }
          return null
        }
      },
      { name: "ggpedtip_cod", label: "Pedido", align: "center", min_width: 60, width: 60 },
    ]

    gantt.config.layout = {
      css: "gantt_container",
      cols: [
        {
          width: 300,
          min_width: 300,
          rows: [
            { view: "grid", scrollX: "gridScroll", scrollable: true, scrollY: "scrollVer" },
            { view: "scrollbar", id: "gridScroll", group: "horizontal" }
          ]
        },
        { resizer: true, width: 1 },
        {
          rows: [
            { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
            { view: "scrollbar", id: "scrollHor", group: "horizontal" }
          ]
        },
        { view: "scrollbar", id: "scrollVer" }
      ]
    };

    let a = this;
    gantt.attachEvent("onTaskRowClick", function (id, row) {
      if (!row.classList.contains("gantt_open") && !row.classList.contains("gantt_close")) {
        let task = gantt.getTask(id);
        console.log(task);
        a.taskService.setRecursoMaquina(task);
      }
    });

    gantt.templates.timeline_cell_class = function (task, date) {
      if (gantt.ext.zoom.getCurrentLevel() < 4 && !gantt.isWorkTime({ date: date, task: task })) {
        return "week_end";
      }
      // if (!gantt.isWorkTime({ task: task, date: date, })) {
      //   return "week_end";
      // }
      return "";
    };

    gantt.init(this.ganttContainer.nativeElement);
    this.taskService.getCalendarios()
      .then((data) => {
        console.log("Datos de los calendarios");
        console.log(data)
        if (data.Ok) {
          data = data.Objeto;
          this.calendarios = data;
          console.log("LLAMADA OK DE CALENDARIOS --->>>");
          console.log(this.calendarios);
          if (this.calendarios.length > 0) {

            /** CREAR CALENDARIOS ***/
            this.calendarios.map(calendario => {
              return gantt.addCalendar(calendario)
            });

            /** ESTABLECER DIAS DIFERENTES POR CADA CALENDARIO ***/
            let format_date = gantt.date.str_to_date("%d-%m-%Y");
            this.calendarios.map(calendario => {
              if (calendario.otherDays.length > 0) {
                calendario.otherDays.map(otherDay => {
                  let converted_date = format_date(otherDay.date);
                  return gantt.getCalendar(calendario.id).setWorkTime({ date: converted_date, hours: otherDay.hours });
                })
              } return null;
            })

            gantt.templates.timeline_cell_class = function (task, date) {
              if (!gantt.isWorkTime({ task: task, date: date }))
                return "week_end";
            };

            /** MONTAR SELECTOR DE CALENDARIO ***/
            let optionsLightbox;
            gantt.locale.labels.section_calendar = "Calendar";
            optionsLightbox = this.calendarios.map(calendario => {
              let option = {
                key: calendario.id,
                label: calendario.descrip
              }
              return option;
            });

            gantt.config.lightbox.sections = [
              { name: "description", height: 38, map_to: "text", type: "textarea", focus: true },
              { name: "calendar", height: 22, map_to: "calendar_id", type: "select", options: optionsLightbox },
              { name: "time", type: "duration", map_to: "auto" }
            ];

            gantt.templates.task_text = function (start, end, task) {
              let nameCalendar;
              var calendar = gantt.getTaskCalendar(task);
              let buscaNombreCalendario = optionsLightbox.filter(e => e.key === calendar.id);

              if (buscaNombreCalendario !== [] && buscaNombreCalendario.length === 1) {
                nameCalendar = buscaNombreCalendario[0].label;
              } else {
                nameCalendar = "Default";
              }

              return "<b>" + task.text + "</b>" + " " + "(" + nameCalendar + ")";
            };
            this.taskService.getRecursosMaquinas()
              .then((data) => {
                console.log("Datos de los recursos/maquinas");
                console.log(data)
                if (data.Ok) {
                  data = data.Objeto;
                } else {
                  // Mensaje cuando falla la llamada al gráfico de capacidad
                  this.taskService.getGanttDataFail(data.Mensaje)
                  data = [];
                }

                gantt.parse({ data })

              });

          } else {
            // Mensaje cuando falla la llamada de calendarios
            console.log("ERROR EN LA LLAMADA DE CALENDARIOS");
            this.calendarios = [];
          }
        }
      })
    // gantt.parse({
    //   data: [
    //     { id: 11, calendar_id: "custom", text: "Project #1", start_date: "28-03-2018", duration: "11110", progress: 0.6, open: true },
    //     { id: 1, calendar_id: "", text: "Project #2", start_date: "01-04-2018", duration: "1800", progress: 0.4, open: true },
    //     { id: 2, calendar_id: "custom", text: "Task #1", start_date: "02-04-2018", duration: "800", parent: "1", progress: 0.5, open: true },
    //     { id: 3, calendar_id: "custom", text: "Task #2", start_date: "11-04-2018", duration: "800", parent: "1", progress: 0.6, open: true },
    //     { id: 4, calendar_id: "", text: "Task #3", start_date: "13-04-2018", duration: "600", parent: "1", progress: 0.5, open: true },
    //     { id: 5, calendar_id: "", text: "Task #1.1", start_date: "02-04-2018", duration: "700", parent: "2", progress: 0.6, open: true },
    //     { id: 6, calendar_id: "custom", text: "Task #1.2", start_date: "03-04-2018", duration: "700", parent: "2", progress: 0.6, open: true },
    //     { id: 7, calendar_id: "", text: "Task #2.1", start_date: "11-04-2018", duration: "800", parent: "3", progress: 0.6, open: true },
    //     { id: 8, calendar_id: "custom", text: "Task #3.1", start_date: "14-04-2018", duration: "500", parent: "4", progress: 0.5, open: true },
    //     { id: 9, calendar_id: "", text: "Task #3.2", start_date: "14-04-2018", duration: "400", parent: "4", progress: 0.5, open: true },
    //     { id: 10, calendar_id: "", text: "Task #3.3", start_date: "14-04-2018", duration: "300", parent: "4", progress: 0.5, open: true },
    //     { id: 12, calendar_id: "", text: "Task #1", start_date: "03-04-2018", duration: "500", parent: "11", progress: 1, open: true },
    //     { id: 13, calendar_id: "custom", text: "Task #2", start_date: "02-04-2018", duration: "700", parent: "11", progress: 0.5, open: true },
    //     { id: 14, calendar_id: "custom", text: "Task #3", start_date: "02-04-2018", duration: "600", parent: "11", progress: 0.8, open: true },
    //     { id: 15, calendar_id: "", text: "Task #4", start_date: "02-04-2018", duration: "500", parent: "11", progress: 0.2, open: true },
    //     { id: 16, calendar_id: "", text: "Task #5", start_date: "02-04-2018", duration: "700", parent: "11", progress: 0, open: true },

    //     { id: 17, calendar_id: "", text: "Task #2.1", start_date: "03-04-2018", duration: "200", parent: "13", progress: 1, open: true },
    //     { id: 18, calendar_id: "custom", text: "Task #2.2", start_date: "06-04-2018", duration: "300", parent: "13", progress: 0.8, open: true },
    //     { id: 19, calendar_id: "", text: "Task #2.3", start_date: "10-04-2018", duration: "400", parent: "13", progress: 0.2, open: true },
    //     { id: 20, calendar_id: "custom", text: "Task #2.4", start_date: "10-04-2018", duration: "400", parent: "13", progress: 0, open: true },
    //     { id: 21, calendar_id: "", text: "Task #4.1", start_date: "03-04-2018", duration: "4", parent: "15", progress: 0.5, open: true },
    //     { id: 22, calendar_id: "", text: "Task #4.2", start_date: "03-04-2018", duration: "4", parent: "15", progress: 0.1, open: true },
    //     { id: 23, calendar_id: "", text: "Task #4.3", start_date: "03-04-2018", duration: "5", parent: "15", progress: 0, open: true }
    //   ],
    //   links: []
    // });

  }

  ngOnDestroy(): void {
    console.warn(`Destruyendo Gantt`);
    let data = [];
    gantt.parse({ data });
  }
}