import { Component, OnInit, ViewContainerRef, ViewChild, HostBinding, OnDestroy, ViewRef, ElementRef, TemplateRef, ContentChild, ViewChildren, ComponentRef } from '@angular/core';
import { Variable } from '../../abstracto/acciones/variables';
import { ComponenteTarjeta, ComponenteBotonera } from '../../abstracto/ejecucion/componentes';
import { compruebaFormatoAncho } from '../../abstracto/evotec_comun';
import { Modelo, InstanciaModelo, InstanciaObjeto } from '../../abstracto/ejecucion/modelo';
import { BaseComponenteAngular } from '../UtilidadesComponentes';
import { TituloTarjetaComponent } from './tituloTarjeta.component';

@Component({
    selector: 'app-tarjeta',
    templateUrl: './tarjeta.component.html',
})
export class TarjetaComponent implements BaseComponenteAngular<ComponenteTarjeta>, OnInit, OnDestroy {
    private static _id = 0;
    private id: string;

    ngOnInit(): void {
        console.warn(`Creando ${this.id}`);

        if (this.componente.componentes.length > 0 && this.componente.componentes[0] instanceof ComponenteBotonera) {
            const v_botonera = this.contenedor.detach(0);
            this.contenedorOpciones.insert(v_botonera);
            this._tieneBotonera = true;
        } else {
            this._tieneBotonera = false;
        }
    }
    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
    }

    constructor(private elementRef: ElementRef) {
        this.id = `tarjeta-${TarjetaComponent._id++}`;
    }

    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_fila: number,
        p_componente: ComponenteTarjeta,
        p_modeloInvocante: Variable): void {

        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    definicionModelo: Modelo;
    instanciaModelo: InstanciaModelo;
    modeloInvocante: Variable;
    fila: number;

    componente: ComponenteTarjeta;

    @ViewChild(TituloTarjetaComponent) contenedorTitulo: TituloTarjetaComponent;
    @ViewChild('contenedorOpciones', { read: ViewContainerRef, static: true }) contenedorOpciones: ViewContainerRef;
    @ViewChild('contenedorContenido', { read: ViewContainerRef, static: true }) contenedor: ViewContainerRef;

    // Propiedad 'icono'
    private _icono: string | null = null;
    get icono(): string | null { return this._icono; }
    set icono(p_valor: string | null) { this._icono = p_valor; }

    get tieneIcono(): boolean {
        const v_tieneIcono = typeof this.icono === 'string' && this.icono.length > 0;
        return v_tieneIcono;
    }

    // Propiedad 'etiqueta'
    private _etiqueta: string;
    get etiqueta(): string { return this._etiqueta; }
    set etiqueta(p_valor: string) { this._etiqueta = p_valor; }

    get tieneEtiqueta(): boolean {
        const v_tieneEtiqueta = typeof this.etiqueta === 'string' && this.etiqueta.length > 0;
        return v_tieneEtiqueta;
    }

    // Propiedad 'visible'
    private _visible: boolean;
    get visible(): boolean { return this._visible; }
    set visible(p_valor: boolean) { this._visible = p_valor; }

    // Propiedad 'ancho'
    private _ancho: string | undefined;
    get ancho(): string | undefined { return this._ancho; }
    set ancho(p_valor: string | undefined) { this._ancho = p_valor; }

    @HostBinding('style.width')
    get tamanioAncho(): string | number | undefined {
        if (typeof this._ancho === 'undefined') {
            return undefined;
        }

        let v_tamanio: string | number | undefined;
        const v_ancho = this._ancho;
        v_tamanio = compruebaFormatoAncho(v_ancho);
        return v_tamanio;
    }

    @HostBinding('class.oculto')
    get oculto(): boolean {
        return !this._visible;
    }

    private _tieneBotonera: boolean;
    get tieneBotonera(): boolean {
        return this._tieneBotonera;
    }

    get conBarraTitulo(): boolean {
        if (this.etiqueta || this._tieneBotonera) {
            return true;
        } else {
            return false;
        }
    }

    // Estilos
    @HostBinding('class')
    get estilos() {
        if (typeof this.componente === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        const v_clases = ['etiqueta'].concat(this.componente.estilos);
        if (this.oculto) { v_clases.push('oculto'); }
        if (typeof this._icono === 'string') { v_clases.push('con-icono'); }

        // v_clases.push(typeof this.esHtml);
        // v_clases.push(this.esHtml + '');
        return v_clases.join(' ');
    }
}
