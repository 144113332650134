import { ViewContainerRef, ComponentFactoryResolver, Type, ComponentRef, Injector, ApplicationRef } from '@angular/core';
import { CampoComponent } from './components/campo/campo.component';
import { TarjetaComponent } from './components/tarjeta/tarjeta.component';
import { BotonComponent } from './components/boton/boton.component';
import { CasillaVerificacionComponent } from './components/casilla-verificacion/casilla-verificacion.component';
import { EtiquetaComponent } from './components/etiqueta/etiqueta.component';
import { BloqueComponent } from './components/bloque/bloque.component';
import { BotoneraComponent } from './components/botonera/botonera.component';
import { PestanaComponent } from './components/pestana/pestana.component';
import { DesplegableComponent } from './components/desplegable/desplegable.component';
import { TablaComponent } from './components/tabla/tabla.component';
import { AgendaComponent } from './components/agenda/agenda.component';
import { Enlace, Modelo, cambios, InstanciaModelo, PropiedadArray, esNombreTipo, InstanciaObjeto, InstanciaArray } from './abstracto/ejecucion/modelo';
import { Variable } from './abstracto/acciones/variables';
import { BaseComponenteAngular, BaseComponenteContenedorAngular } from './components/UtilidadesComponentes';
import { actualizadorModelo } from './abstracto/evotec_json';
import {
    Visitante, ComponenteLogico, enlace, ComponenteContenedor, ComponenteTarjeta, ComponenteBloque, ComponentePestana,
    ComponenteBotonera, ComponenteDesplegable, ComponenteTabla, ComponenteColumnaTabla, ComponenteEtiqueta, ComponenteBoton,
    ComponenteCheck, ComponenteCampo, ComponenteAgenda
} from './abstracto/ejecucion/componentes';
import { ErrorDefinicion, ErrorValidacion, ErrorPruebaCordura, valida, comoJson } from './abstracto/evotec_comun';

interface TablaIconos {
    [nombre: string]: string;
}

export class GeneradorAngular implements Visitante<ComponentRef<BaseComponenteAngular<ComponenteLogico>>[]> {
    static readonly iconos: TablaIconos = {
        'nuevo': 'icon-new',
        'elimina': 'icon-delete',
        // 'nuevo_mensaje': 'icon-coments',
        'nuevo_mensaje': 'icon-new',
        'responde': 'icon-answer',
        'responde_a_todos': 'icon-answer_all',

        'abajo': 'icon-arrow_down',
        'izquierda': 'icon-arrow_left',
        'arriba': 'icon-arrow_up',
        'derecha': 'icon-arrow_right',
        'atras': 'icon-arrow_back',

        'anterior': 'icon-arrow_left',
        'siguiente': 'icon-arrow_right',

        'adjunto': 'icon-attach_file',

        'primera_pagina': 'icon-double_arrow_left',
        'pagina_anterior': 'icon-arrow_left',
        'pagina_siguiente': 'icon-arrow_right',
        'ultima_pagina': 'icon-double_arrow_right',

        'refresca': 'icon-reload',
        'navega': 'icon-open_in_new',
        'busca': 'icon-search',
        'cierra': 'icon-close',

        //'comentarios': 'icon-coments', // observaciones???

        'empresa': 'icon-company',

        'copia': 'icon-copy',
        'base_de_datos': 'icon-database',
        'destacado': 'icon-destacar',
        'hecho': 'icon-done',
        'editar': 'icon-edit',

        'favorito': 'icon-favorite',
        'filtro': 'icon-filter',
        'idioma': 'icon-language',
        'menu': 'icon-menu',
        'mas': 'icon-more_horiz',
        'mas_vertical': 'icon-more_vert',

        'ascendente': 'icon-order_ascendant',
        'descendente': 'icon-order_descendant',
        'contraseña': 'icon-password',
        'impresora': 'icon-print',
        'tiempo': 'icon-time',
        'calendario': 'icon-today',
        'ubicacion': 'icon-ubicacion',
        'almacen': 'icon-warehouse',
        'web': 'icon-web',

        'error': 'icon-new icon-error',
    };

    private readonly facilitadorFactoriaComponentes: ComponentFactoryResolver;
    private readonly resuelve: ((p_valor: any) => void) | undefined;
    private readonly rechaza: ((p_motivo: any) => void) | undefined;
    private pestana: ComponentRef<BaseComponenteAngular<ComponenteLogico>>;

    private static inicializaEnlace<T extends ComponenteLogico>(
        p_nombreEnlace: string,
        p_componente: T,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_actualiza: (p_enlace: Enlace | undefined, p_valor: any) => void
    ): Enlace | undefined {
        const v_enlace = enlace(p_componente.enlaces, p_nombreEnlace);

        const v_actualiza = p_actualiza.bind(undefined, v_enlace);

        if (typeof v_enlace !== 'undefined') {

            const
                v_functionTransforma = v_enlace.funcionTransforma(v_enlace.actualizaComponente),
                v_transformaYActualiza = p_valor => {
                    const v_valor = v_enlace.transformaValor(p_valor, v_functionTransforma);
                    v_actualiza(v_valor);
                };

            p_componente.actualizadores[p_nombreEnlace] = v_transformaYActualiza;
            v_transformaYActualiza(p_instanciaModelo.valorPropiedad(p_definicionModelo, v_enlace.propiedad));
        } else {
            v_actualiza(p_componente[p_nombreEnlace as keyof T]);
        }

        return v_enlace;
    }

    constructor(
        p_facilitadorFactoriaComponentes: ComponentFactoryResolver,
        p_resuelve: ((p_valor: any) => void) | undefined,
        p_rechaza: ((p_motivo: any) => void) | undefined,
        p_pestana: ComponentRef<BaseComponenteAngular<ComponenteLogico>>
    ) {
        this.facilitadorFactoriaComponentes = p_facilitadorFactoriaComponentes;
        this.resuelve = p_resuelve;
        this.rechaza = p_rechaza;
        this.pestana = p_pestana;
    }

    public static genera(
        p_componente: ComponenteLogico,
        p_contenedor: ViewContainerRef,
        p_facilitadorFactoriaComponentes: ComponentFactoryResolver,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaModelo,
        p_modeloInvocante: Variable | undefined,
        p_resuelve: (p_valor: any) => void,
        p_rechaza: (p_motivo: any) => void,
        p_pestana: ComponentRef<BaseComponenteAngular<ComponenteLogico>>
    ) {

        const v_generador = new GeneradorAngular(p_facilitadorFactoriaComponentes, p_resuelve, p_rechaza, p_pestana);
        return p_componente.acepta(v_generador, p_contenedor, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, undefined);
    }

    private visita<TComponenteVirtual extends ComponenteLogico, TComponenteAngular extends BaseComponenteAngular<TComponenteVirtual>>(
        p_componente: TComponenteVirtual,
        p_contenedor: ViewContainerRef,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable,
        p_fila: number,
        p_tipoComponente: Type<TComponenteAngular>,
        p_inicializador: (
            p_componente: TComponenteVirtual,
            p_definicionModelo: Modelo,
            p_instanciaModelo: InstanciaObjeto,
            p_modeloInvocante: Variable,
            p_fila: number,
            p_referenciaComponente: ComponentRef<TComponenteAngular>) => void
    ): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {

        const
            v_factoria = this.facilitadorFactoriaComponentes.resolveComponentFactory(p_tipoComponente),
            v_referenciaComponente = p_contenedor.createComponent(v_factoria);

        v_referenciaComponente.instance.inicializa(p_definicionModelo, p_instanciaModelo, p_fila, p_componente, p_modeloInvocante);

        p_inicializador(p_componente, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, v_referenciaComponente);
        return [v_referenciaComponente];
    }

    private visitaContenedor<TComponenteVirtual extends ComponenteContenedor, TComponenteAngular extends BaseComponenteContenedorAngular<TComponenteVirtual>>(
        p_componente: TComponenteVirtual,
        p_contenedor: ViewContainerRef,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable,
        p_fila: number,
        p_tipoComponente: Type<TComponenteAngular>,
        p_inicializador: (
            p_componente: TComponenteVirtual,
            p_definicionModelo: Modelo,
            p_instanciaModelo: InstanciaObjeto,
            p_modeloInvocante: Variable,
            p_fila: number,
            p_referenciaComponente: ComponentRef<TComponenteAngular>) => void
    ): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {

        const
            v_factoria = this.facilitadorFactoriaComponentes.resolveComponentFactory(p_tipoComponente),
            v_referenciaComponente = p_contenedor.createComponent(v_factoria);
        //v_referenciaComponente = v_factoria.create(this.inyector);

        v_referenciaComponente.instance.inicializa(p_definicionModelo, p_instanciaModelo, p_fila, p_componente, p_modeloInvocante);

        p_inicializador(p_componente, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, v_referenciaComponente);

        p_componente.componentes.forEach(p_componenteHijo =>
            p_componenteHijo.acepta(this, v_referenciaComponente.instance.contenedor, p_definicionModelo, p_instanciaModelo, p_modeloInvocante));

        return [v_referenciaComponente];
    }

    visitaTarjeta(
        p_componente: ComponenteTarjeta,
        p_contenedor: ViewContainerRef,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable,
        p_fila: number
    ): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {

        return this.visitaContenedor(p_componente, p_contenedor, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, TarjetaComponent,
            (p_componente, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, p_referenciaComponente): void => {

                GeneradorAngular.inicializaEnlace('etiqueta', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.etiqueta = p_valor;
                });

                GeneradorAngular.inicializaEnlace('icono', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    if (GeneradorAngular.iconos[p_valor]) {
                        p_valor = GeneradorAngular.iconos[p_valor];
                    }
                    if (p_valor) {
                        p_referenciaComponente.instance.icono = p_valor;
                    }
                });

                GeneradorAngular.inicializaEnlace('visible', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.visible = typeof p_valor === 'undefined' ? true : p_valor;
                });

                GeneradorAngular.inicializaEnlace('ancho', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => { // JPM
                    p_referenciaComponente.instance.ancho = p_valor;
                });
            }
        );
    }

    visitaBloque(
        p_componente: ComponenteBloque,
        p_contenedor: ViewContainerRef,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable,
        p_fila: number
    ): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {

        return this.visitaContenedor(p_componente, p_contenedor, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, BloqueComponent,
            (p_componente, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, p_referenciaComponente): void => {

                GeneradorAngular.inicializaEnlace('visible', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.visible = typeof p_valor === 'undefined' || p_valor ? true : false;
                });

                GeneradorAngular.inicializaEnlace('ancho', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => { // JPM
                    p_referenciaComponente.instance.ancho = p_valor;
                });
            }
        );
    }

    visitaPestana(
        p_componente: ComponentePestana,
        p_contenedor: ViewContainerRef,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable,
        p_fila: number
    ): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {

        const v_pestana = this.visitaContenedor(p_componente, p_contenedor, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, PestanaComponent,
            (p_componente, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, p_referenciaComponente): void => {
                GeneradorAngular.inicializaEnlace('etiqueta', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.etiqueta = p_valor;
                });

                GeneradorAngular.inicializaEnlace('visible', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.visible = typeof p_valor === 'undefined' ? true : p_valor;
                });
            }
        );
        this.pestana = v_pestana[0];
        return v_pestana;
    }

    visitaBotonera(
        p_componente: ComponenteBotonera,
        p_contenedor: ViewContainerRef,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable,
        p_fila: number
    ): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {

        return this.visitaContenedor(p_componente, p_contenedor, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, BotoneraComponent,
            (p_componente, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, p_referenciaComponente): void => {

                GeneradorAngular.inicializaEnlace('visible', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.visible = typeof p_valor === 'undefined' ? true : p_valor;
                });
            }
        );
    }

    visitaDesplegable(
        p_componente: ComponenteDesplegable,
        p_contenedor: ViewContainerRef,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable,
        p_fila: number
    ): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {

        return this.visita(p_componente, p_contenedor, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, DesplegableComponent,
            (p_componente, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, p_referenciaComponente): void => {

                GeneradorAngular.inicializaEnlace('etiqueta', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.etiqueta = p_valor;
                });

                const
                    p_nombreEnlace = 'seleccionado',
                    p_actualiza = (p_enlace: Enlace | undefined, p_valor: any) => {
                        const
                            v_functionTransforma = (typeof p_enlace === 'undefined') ? undefined : p_enlace.funcionTransforma(p_enlace.actualizaComponente),
                            v_valor = (typeof p_enlace === 'undefined') ? p_valor : p_enlace.transformaValor(p_valor, v_functionTransforma);
                        p_referenciaComponente.instance.seleccionado = v_valor;
                    },
                    v_enlace = enlace(p_componente.enlaces, p_nombreEnlace),
                    v_actualiza = p_actualiza.bind(undefined, v_enlace);

                if (typeof v_enlace !== 'undefined') {
                    p_referenciaComponente.instance.modificable = v_enlace.modificable;

                    p_componente.actualizadores[p_nombreEnlace] = v_actualiza;
                    v_actualiza(p_instanciaModelo.valorPropiedad(p_definicionModelo, v_enlace.propiedad));
                    if (v_enlace.modificable) {
                        if (v_enlace.modo !== 'unidireccional') {
                            p_referenciaComponente.instance.seleccionadoCambiado = actualizadorModelo(p_definicionModelo, p_instanciaModelo, v_enlace,
                                () => p_referenciaComponente.instance.seleccionado, p_componente.fila, { pestana: () => this.pestana });
                        }
                    }
                } else {
                    v_actualiza(p_componente[p_nombreEnlace]);
                }

                GeneradorAngular.inicializaEnlace('visible', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.visible = typeof p_valor === 'undefined' ? true : p_valor;
                });

                GeneradorAngular.inicializaEnlace('habilitado', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.habilitado = typeof p_valor === 'undefined' ? true : p_valor;
                });

                GeneradorAngular.inicializaEnlace('registros', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.registros = p_valor;
                });

                const
                    v_enlaceEtiquetaRegistros = enlace(p_componente.enlaces, 'etiquetaRegistros');

                if (typeof v_enlaceEtiquetaRegistros !== 'undefined') {
                    p_referenciaComponente.instance.etiquetaRegistros = v_enlaceEtiquetaRegistros.propiedad;

                    if (typeof v_enlace !== 'undefined') {
                        const v_propiedad = p_definicionModelo.damePropiedad(`${v_enlace.propiedad}.${v_enlaceEtiquetaRegistros.propiedad}`);
                        if (v_propiedad && v_propiedad.tipo === 'numero') {
                            p_referenciaComponente.instance.formato = 'numero';
                        }
                    }
                } else {
                    throw new ErrorDefinicion('\'etiquetaRegistros\' debe ser un enlace');
                }
            }
        );
    }

    visitaTabla(
        p_componente: ComponenteTabla,
        p_contenedor: ViewContainerRef,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable,
        p_fila: number
    ): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {

        return this.visita(p_componente, p_contenedor, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, TablaComponent,
            (p_componente, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, p_referenciaComponente): void => {

                // Generador.inicializaEnlace('etiqueta', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                //     const v_valor = evotec_json.transformaValor(p_valor, p_enlace && p_enlace.actualizaComponente);
                //     p_referenciaComponente.instance.etiqueta = v_valor;
                // });

                GeneradorAngular.inicializaEnlace('ancho', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.ancho = p_valor;
                });

                const
                    p_nombreEnlaceSeleccionado = 'seleccionado',
                    p_actualizaSeleccionado = (p_enlace: Enlace | undefined, p_valor: any) => {
                        const
                            v_functionTransforma = (typeof p_enlace === 'undefined') ? undefined : p_enlace.funcionTransforma(p_enlace.actualizaComponente),
                            v_valor = (typeof p_enlace === 'undefined') ? p_valor : p_enlace.transformaValor(p_valor, v_functionTransforma);
                        p_referenciaComponente.instance.seleccionado = v_valor;
                    },
                    v_enlaceSeleccionado = enlace(p_componente.enlaces, p_nombreEnlaceSeleccionado),
                    v_actualizaSeleccionado = p_actualizaSeleccionado.bind(undefined, v_enlaceSeleccionado);

                if (typeof v_enlaceSeleccionado !== 'undefined') {
                    p_referenciaComponente.instance.modificable = v_enlaceSeleccionado.modificable;

                    p_componente.actualizadores[p_nombreEnlaceSeleccionado] = v_actualizaSeleccionado;
                    v_actualizaSeleccionado(p_instanciaModelo.valorPropiedad(p_definicionModelo, v_enlaceSeleccionado.propiedad));
                    if (v_enlaceSeleccionado.modificable) {
                        if (v_enlaceSeleccionado.modo !== 'unidireccional') {
                            p_referenciaComponente.instance.seleccionadoCambiado = actualizadorModelo(p_definicionModelo, p_instanciaModelo, v_enlaceSeleccionado,
                                () => p_referenciaComponente.instance.seleccionado, p_componente.fila, { pestana: () => this.pestana });
                        }
                    }
                } else {
                    v_actualizaSeleccionado(p_componente[p_nombreEnlaceSeleccionado as keyof typeof p_componente]);
                }

                GeneradorAngular.inicializaEnlace('visible', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.visible = typeof p_valor === 'undefined' ? true : p_valor;
                });

                GeneradorAngular.inicializaEnlace('habilitado', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.habilitado = typeof p_valor === 'undefined' ? true : p_valor;
                });

                // Generador.inicializaEnlace('numeroPagina', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                //     const v_valor = transformaValor(p_valor, p_enlace && p_enlace.actualizaComponente);
                //     p_referenciaComponente.instance.numeroPagina = v_valor;
                // });

                const
                    p_nombreEnlaceNumeroPagina = 'numeroPagina',
                    p_actualizaNumeroPagina = (p_enlace: Enlace | undefined, p_valor: any) => {
                        const
                            v_functionTransforma = (typeof p_enlace === 'undefined') ? undefined : p_enlace.funcionTransforma(p_enlace.actualizaComponente),
                            v_valor = (typeof p_enlace === 'undefined') ? p_valor : p_enlace.transformaValor(p_valor, v_functionTransforma);
                        p_referenciaComponente.instance.numeroPagina = v_valor;
                    },
                    v_enlaceNumeroPagina = enlace(p_componente.enlaces, p_nombreEnlaceNumeroPagina),
                    v_actualizaNumeroPagina = p_actualizaNumeroPagina.bind(undefined, v_enlaceNumeroPagina);

                if (typeof v_enlaceNumeroPagina !== 'undefined') {
                    p_referenciaComponente.instance.modificable = v_enlaceNumeroPagina.modificable;

                    p_componente.actualizadores[p_nombreEnlaceNumeroPagina] = v_actualizaNumeroPagina;
                    v_actualizaNumeroPagina(p_instanciaModelo.valorPropiedad(p_definicionModelo, v_enlaceNumeroPagina.propiedad));
                    if (v_enlaceNumeroPagina.modificable) {
                        if (v_enlaceNumeroPagina.modo !== 'unidireccional') {
                            p_referenciaComponente.instance.numeroPaginaCambiada = actualizadorModelo(p_definicionModelo, p_instanciaModelo, v_enlaceNumeroPagina,
                                () => p_referenciaComponente.instance.numeroPagina, p_componente.fila, { pestana: () => this.pestana });
                        }
                    }
                } else {
                    v_actualizaNumeroPagina(p_componente[p_nombreEnlaceNumeroPagina]);
                }

                const
                    p_nombreEnlaceRegistrosPorPagina = 'registrosPorPagina',
                    p_actualizaRegistrosPorPagina = (p_enlace: Enlace | undefined, p_valor: any) => {
                        const
                            v_functionTransforma = (typeof p_enlace === 'undefined') ? undefined : p_enlace.funcionTransforma(p_enlace.actualizaComponente),
                            v_valor = (typeof p_enlace === 'undefined') ? p_valor : p_enlace.transformaValor(p_valor, v_functionTransforma);
                        p_referenciaComponente.instance.registrosPorPagina = v_valor;
                    },
                    v_enlaceRegistrosPorPagina = enlace(p_componente.enlaces, p_nombreEnlaceRegistrosPorPagina),
                    v_actualizaRegistrosPorPagina = p_actualizaRegistrosPorPagina.bind(undefined, v_enlaceRegistrosPorPagina);

                if (typeof v_enlaceRegistrosPorPagina !== 'undefined') {
                    p_referenciaComponente.instance.modificable = v_enlaceRegistrosPorPagina.modificable;

                    p_componente.actualizadores[p_nombreEnlaceRegistrosPorPagina] = v_actualizaRegistrosPorPagina;
                    v_actualizaRegistrosPorPagina(p_instanciaModelo.valorPropiedad(p_definicionModelo, v_enlaceRegistrosPorPagina.propiedad));
                    if (v_enlaceRegistrosPorPagina.modificable) {
                        if (v_enlaceRegistrosPorPagina.modo !== 'unidireccional') {
                            p_referenciaComponente.instance.registrosPorPaginaCambiado = actualizadorModelo(p_definicionModelo, p_instanciaModelo, v_enlaceRegistrosPorPagina,
                                () => p_referenciaComponente.instance.registrosPorPagina, p_componente.fila, { pestana: () => this.pestana });
                        }
                    }
                } else {
                    v_actualizaRegistrosPorPagina(p_componente[p_nombreEnlaceRegistrosPorPagina]);
                }

                // Generador.inicializaEnlace('registrosPorPagina', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                //     const v_valor = evotec_json.transformaValor(p_valor, p_enlace && p_enlace.actualizaComponente);
                //     p_referenciaComponente.instance.registrosPorPagina = v_valor;
                // });

                const v_enlaceRegistros = GeneradorAngular.inicializaEnlace('registros', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.registros = p_valor;
                });

                if (typeof v_enlaceRegistros !== 'undefined') {
                    const v_modeloRegistros = p_definicionModelo.damePropiedad(v_enlaceRegistros.propiedad);
                    if (v_modeloRegistros instanceof PropiedadArray) {
                        valida(typeof v_modeloRegistros.elementos !== 'string', `No se ha resuelto el nombre de tipo ('${v_modeloRegistros.elementos}')`);

                        const v_elementos = v_modeloRegistros.elementos;
                        p_referenciaComponente.instance.columnas = p_componente.columnas.map(p_columna => {
                            // REVISAR
                            const v_propiedadModelo = (p_columna.celda as any).valor.modelo;
                            const v_propiedad = v_elementos.damePropiedad(v_propiedadModelo);
                            return {
                                etiqueta: p_columna.etiqueta,
                                propiedadModelo: v_propiedadModelo,
                                tipo: v_propiedad.tipo,
                                ancho: p_columna.ancho,
                                estilos: p_columna.estilos,
                                alNavegar: p_columna.alNavegar && (p_fila => {
                                    const { propiedad: v_array, valor: v_instanciaModelo } = p_definicionModelo.damePropiedad(v_enlaceRegistros.propiedad, p_instanciaModelo);
                                    valida(v_array instanceof PropiedadArray, ErrorDefinicion, `'${v_enlaceRegistros.propiedad}' no es un array.`);
                                    valida(v_instanciaModelo instanceof InstanciaArray, 'Una propiedad de tipo array debe tener un valor de tipo array.');
                                    valida(typeof v_array.elementos !== 'string', 'No se esperaba un nombre de tipo');

                                    const v_elementos = v_array.elementos
                                        .evalua(p_columna.alNavegar, v_instanciaModelo.elementos[p_fila], p_modeloInvocante, this.resuelve, this.rechaza, { pestana: () => this.pestana });
                                    valida(v_elementos instanceof Promise, `Se esperaba una promesa pero se obtuvo '${comoJson(v_elementos)}'`);
                                    return v_elementos.then(() => cambios.aplica());
                                })
                                // Añadir propiedad formato (para gestionar campos tipo fecha):
                                // p_definicionModelo -> para sacar tipo del campo
                                // p_instanciaModelo.valores[v_enlaceRegistros.propiedad].elementos[p_fila] : para sacar el valor (aunque no lo tengo claro)
                            };
                        });

                        // p_componente.columnas.forEach((p_columna, p_indice) => {
                        //     GeneradorAngular.inicializaEnlace('etiqueta', p_columna, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                        //         p_referenciaComponente.instance.actualizaEtiqueta(p_indice, p_valor);
                        //     });
                        // });
                    }
                }
            }
        );
    }

    visitaColumnaTabla(p_componente: ComponenteColumnaTabla, ...p_parametros: any[]): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {
        throw new ErrorValidacion('Las columnas de una tabla se generan al generar la tabla');
    }

    visitaEtiqueta(
        p_componente: ComponenteEtiqueta,
        p_contenedor: ViewContainerRef,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable,
        p_fila: number
    ): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {

        return this.visita(p_componente, p_contenedor, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, EtiquetaComponent,
            (p_componente, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, p_referenciaComponente): void => {

                GeneradorAngular.inicializaEnlace('valor', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.valor = p_valor;
                });

                GeneradorAngular.inicializaEnlace('visible', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.visible = typeof p_valor === 'undefined' ? true : p_valor;
                });

                GeneradorAngular.inicializaEnlace('negrita', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.negrita = typeof p_valor === 'undefined' ? false : p_valor;
                });

                GeneradorAngular.inicializaEnlace('cursiva', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.cursiva = typeof p_valor === 'undefined' ? false : p_valor;
                });

                GeneradorAngular.inicializaEnlace('ancho', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.ancho = p_valor; // JPM
                });

                const
                    p_nombreEnlace = 'valor',
                    p_actualiza = (p_enlace: Enlace | undefined, p_valor: any) => {
                        const
                            v_funcionTransforma = (typeof p_enlace === 'undefined') ? undefined : p_enlace.funcionTransforma(p_enlace.actualizaComponente),
                            v_valor = (typeof p_enlace === 'undefined') ? p_valor : p_enlace.transformaValor(p_valor, v_funcionTransforma);
                        p_referenciaComponente.instance.refrescaValor(v_valor);
                    },
                    v_enlace = enlace(p_componente.enlaces, p_nombreEnlace),
                    v_actualiza = p_actualiza.bind(undefined, v_enlace);

                if (typeof v_enlace !== 'undefined') {
                    p_componente.actualizadores[p_nombreEnlace] = v_actualiza;
                    v_actualiza(p_instanciaModelo.valorPropiedad(p_definicionModelo, v_enlace.propiedad));

                    const v_propiedad = p_definicionModelo.damePropiedad(v_enlace.propiedad);
                    if (typeof v_propiedad !== 'undefined' && v_propiedad.tipo === 'numero' || v_propiedad.tipo === 'texto' || v_propiedad.tipo === 'fecha' || v_propiedad.tipo === 'hora' || v_propiedad.tipo === 'fechaHora') {
                        p_referenciaComponente.instance.formato = v_propiedad.tipo;
                    }
                }
            }
        );
    }

    visitaBoton(
        p_componente: ComponenteBoton,
        p_contenedor: ViewContainerRef,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable,
        p_fila: number
    ): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {

        return this.visita(p_componente, p_contenedor, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, BotonComponent,
            (p_componente, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, p_referenciaComponente): void => {

                GeneradorAngular.inicializaEnlace('etiqueta', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.etiqueta = p_valor;
                });

                GeneradorAngular.inicializaEnlace('icono', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    if (GeneradorAngular.iconos[p_valor]) {
                        p_valor = GeneradorAngular.iconos[p_valor];
                    }
                    if (p_valor) {
                        p_referenciaComponente.instance.icono = p_valor;
                    }
                });

                GeneradorAngular.inicializaEnlace('visible', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.visible = typeof p_valor === 'undefined' ? true : p_valor;
                });

                GeneradorAngular.inicializaEnlace('habilitado', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.habilitado = typeof p_valor === 'undefined' ? true : p_valor;
                });

                GeneradorAngular.inicializaEnlace('ancho', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.ancho = p_valor;
                });

                if (typeof p_componente.alPulsar !== 'undefined') {
                    const v_alPulsar = p_componente.alPulsar;
                    const v_resultado = () =>
                        Promise.resolve(
                            p_definicionModelo.evalua(v_alPulsar, p_instanciaModelo, p_modeloInvocante, this.resuelve, this.rechaza, { pestana: () => this.pestana }))
                            .finally(() => cambios.aplica());
                    p_referenciaComponente.instance.alPulsar = v_resultado;
                }
            }
        );
    }

    visitaCheck(
        p_componente: ComponenteCheck,
        p_contenedor: ViewContainerRef,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable,
        p_fila: number
    ): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {

        return this.visita(p_componente, p_contenedor, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, CasillaVerificacionComponent,
            (p_componente, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, p_referenciaComponente): void => {

                GeneradorAngular.inicializaEnlace('etiqueta', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.etiqueta = p_valor;
                });

                const
                    p_nombreEnlace = 'valor',
                    p_actualiza = (p_enlace: Enlace | undefined, p_valor: any) => {
                        const
                            v_funcionTransforma = (typeof p_enlace === 'undefined') ? undefined : p_enlace.funcionTransforma(p_enlace.actualizaComponente),
                            v_valor = (typeof p_enlace === 'undefined') ? p_valor : p_enlace.transformaValor(p_valor, v_funcionTransforma);
                        p_referenciaComponente.instance.actualizaValorDesdeModelo(v_valor);
                    },
                    v_enlace = enlace(p_componente.enlaces, p_nombreEnlace),
                    v_actualiza = p_actualiza.bind(undefined, v_enlace);

                if (typeof v_enlace !== 'undefined') {
                    p_referenciaComponente.instance.modificable = v_enlace.modificable;

                    p_componente.actualizadores[p_nombreEnlace] = v_actualiza;
                    v_actualiza(p_instanciaModelo.valorPropiedad(p_definicionModelo, v_enlace.propiedad));
                    if (v_enlace.modificable) {
                        if (v_enlace.modo !== 'unidireccional') {
                            p_referenciaComponente.instance.valorCambiado = actualizadorModelo(p_definicionModelo, p_instanciaModelo, v_enlace,
                                () => p_referenciaComponente.instance.valor, p_componente.fila, { pestana: () => this.pestana });
                        }
                    }
                } else {
                    v_actualiza(p_componente[p_nombreEnlace]);
                }

                GeneradorAngular.inicializaEnlace('visible', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.visible = typeof p_valor === 'undefined' ? true : p_valor;
                });

                GeneradorAngular.inicializaEnlace('habilitado', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.habilitado = typeof p_valor === 'undefined' ? true : p_valor;
                });
            }
        );
    }

    visitaCampo(
        p_componente: ComponenteCampo,
        p_contenedor: ViewContainerRef,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable,
        p_fila: number
    ): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {

        return this.visita(p_componente, p_contenedor, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, CampoComponent,
            (p_componente, p_definicionModelo, p_instanciaModelo, p_fila, p_modeloInvocante, p_referenciaComponente): void => {

                GeneradorAngular.inicializaEnlace('etiqueta', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.etiqueta = p_valor;
                });

                const
                    p_nombreEnlace = 'valor',
                    p_actualiza = (p_enlace: Enlace | undefined, p_valor: any) => {
                        const
                            v_funcionTransforma = (typeof p_enlace === 'undefined') ? undefined : p_enlace.funcionTransforma(p_enlace.actualizaComponente),
                            v_valor = (typeof p_enlace === 'undefined') ? p_valor : p_enlace.transformaValor(p_valor, v_funcionTransforma);
                        p_referenciaComponente.instance.refrescaValor(v_valor);
                    },
                    v_enlace = enlace(p_componente.enlaces, p_nombreEnlace),
                    v_actualiza = p_actualiza.bind(undefined, v_enlace);

                if (typeof v_enlace !== 'undefined') {
                    p_referenciaComponente.instance.modificable = v_enlace.modificable;

                    p_componente.actualizadores[p_nombreEnlace] = v_actualiza;
                    v_actualiza(p_instanciaModelo.valorPropiedad(p_definicionModelo, v_enlace.propiedad));
                    if (v_enlace.modificable) {
                        if (v_enlace.modo !== 'unidireccional') {
                            p_referenciaComponente.instance.valorCambiado = actualizadorModelo(p_definicionModelo, p_instanciaModelo, v_enlace,
                                () => p_referenciaComponente.instance.valor, p_componente.fila, { pestana: () => this.pestana });
                        }
                    }
                    const v_propiedad = p_definicionModelo.damePropiedad(v_enlace.propiedad);
                    if (typeof v_propiedad !== 'undefined' && v_propiedad.tipo === 'numero' || v_propiedad.tipo === 'texto' || v_propiedad.tipo === 'fecha' || v_propiedad.tipo === 'hora' || v_propiedad.tipo === 'fechaHora') {
                        p_referenciaComponente.instance.formato = v_propiedad.tipo;
                    }
                } else {
                    v_actualiza(p_componente[p_nombreEnlace]);
                }

                // Generador.inicializaEnlace('valor', p_componente, p_definicionModelo, p_instanciaModelo, p_enlace => p_valor => {
                //     const v_valor = evotec_json.transformaValor(p_valor, p_enlace && p_enlace.actualizaComponente);
                //     v_componente.instance.valor = v_valor;
                // });

                GeneradorAngular.inicializaEnlace('visible', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.visible = typeof p_valor === 'undefined' ? true : p_valor;
                });

                GeneradorAngular.inicializaEnlace('habilitado', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.habilitado = typeof p_valor === 'undefined' ? true : p_valor;
                });

                GeneradorAngular.inicializaEnlace('ancho', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.ancho = p_valor;
                });
            }
        );
    }

    visitaAgenda(
        p_componente: ComponenteAgenda,
        p_contenedor: ViewContainerRef,
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaObjeto,
        p_modeloInvocante: Variable,
        p_fila: number
    ): ComponentRef<BaseComponenteAngular<ComponenteLogico>>[] {

        return this.visita(p_componente, p_contenedor, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, AgendaComponent,
            (p_componente, p_definicionModelo, p_instanciaModelo, p_modeloInvocante, p_fila, p_referenciaComponente): void => {
                GeneradorAngular.inicializaEnlace('registros', p_componente, p_definicionModelo, p_instanciaModelo, (p_enlace, p_valor) => {
                    p_referenciaComponente.instance.registros = p_valor;
                });

            }
        );
    }
}
